import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useAccount, useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Button } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appButtonType, appRoute } from '@utils/globalConstant';
import Dokumente from '@theme/assets/img/dokumente.svg';
import Datenschutz from '@theme/assets/img/datenschutz.svg';
import ArrowRightSmall from '@theme/assets/img/arrow_right_small.svg';
import Impressum from '@theme/assets/img/impressum.svg';
import AppBewerten from '@theme/assets/img/app_bewerten.svg';
import './style.scss';

export function Legal() {
  // Constants

  // States

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isAccountLoading } = useAccount();

  // Functions

  // Hooks
  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_legal_meta_title')}
        description={t('ek_legal_meta_description')}
        keywords={t('ek_legal_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.HELP_AND_SERVICE}>
              {t('ek_bc_help_service')}
            </BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_legal_h') }} />
          <br />
        </div>
      </div>
      <div className="mt-4 pt-2">
        <div className="row">
          <div className="help-legal">
            <div className="col-lg-4">
              <Button
                className="copytext-medium"
                buttonClass={appButtonType.LINK}
                onClick={() => {
                  navigate(appRoute.DOCUMENTS);
                }}
              >
                <span>
                  <img src={Dokumente} alt="dokumente-icon" />
                  {t('ek_legal_h1')}
                </span>
                <img src={ArrowRightSmall} alt="rightarrow-icon" />
              </Button>

              <Button
                className="copytext-medium"
                buttonClass={appButtonType.LINK}
                onClick={() => {
                  navigate(appRoute.PRIVACY_DOCS);
                }}
              >
                <span>
                  <img src={Datenschutz} alt="datenschutz-icon" />
                  {t('ek_legal_h2')}
                </span>

                <img src={ArrowRightSmall} alt="rightarrow-icon" />
              </Button>

              <Button
                className="copytext-medium"
                buttonClass={appButtonType.LINK}
                onClick={() => {
                  navigate(appRoute.IMPRINT);
                }}
              >
                <span>
                  <img src={Impressum} alt="datenschutz-icon" />
                  {t('ek_legal_h3')}
                </span>
                <img src={ArrowRightSmall} alt="rightarrow-icon" />
              </Button>

              <Button
                className="copytext-medium"
                buttonClass={appButtonType.LINK}
                onClick={() => {
                  navigate(appRoute.FOOT_NOTES);
                }}
              >
                <span>
                  <img src={AppBewerten} alt="datenschutz-icon" />
                  {t('ek_legal_h4')}
                </span>

                <img src={ArrowRightSmall} alt="rightarrow-icon" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Legal;

export * from './Documents';
export * from './PrivacyDocuments';
export * from './Imprint';
export * from './FootNotes';
