import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { FullScreenModal } from '@core/Utils';
import { Button } from '@core/Inputs';
import { AutoTopUpFor, appButtonType, appRoute, appTopUpType } from '@utils/globalConstant';
import PaymentFailIcon from '@theme/assets/img/fail.svg';

export function FailureModal({
  isFailureModalOpen,
  setIsFailureModalOpen,
  topupReason,
  topUpType,
  emptySessionData,
  isStaticContentLoading,
  onFailedPayment
}) {
  // Contexts
  const { setCurrentStep, setIsActivationClicked } = useActivation();
  const navigate = useNavigate();
  const { t } = useStaticContent();
  // Functions
  const getFailureText = () => {
    switch (topupReason) {
      case AutoTopUpFor.ACTIVATION:
        return (
          <>
            <p
              className="pb-5 pt-3 copytext"
              dangerouslySetInnerHTML={{ __html: t('ek_ap_fail_message') }}
            />

            <Button
              buttonClass={appButtonType.PRIMARY}
              onClick={() => {
                emptySessionData();
                setIsFailureModalOpen(false);
                setIsActivationClicked(true);
                setCurrentStep(6);
                navigate(appRoute.ACTIVATION);
              }}>
              {t('ek_ap_fail_button')}
            </Button>
          </>
        );
      case AutoTopUpFor.LOGIN:
        return getLoginPaymentFailedText();
      default:
        return (
          <>
            <h2
              className="text-center text-uppercase my-4"
              dangerouslySetInnerHTML={{ __html: t('ek_fail_headline-default') }}
            />
            <p
              className="copytext custom-width"
              dangerouslySetInnerHTML={{ __html: t('ek_generic_err_txt') }}
            />

            <Button
              buttonClass={appButtonType.PRIMARY}
              onClick={() => {
                emptySessionData();
                setIsFailureModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_sofort-top-up_error-msg_back')}
            </Button>
          </>
        );
    }
  };

  const getLoginPaymentFailedText = () => {
    switch (topUpType) {
      case appTopUpType.DIRECT:
        return (
          <>
            <h2
              className="text-center text-uppercase my-4"
              dangerouslySetInnerHTML={{ __html: t('ek_fail_headline-default') }}
            />

            <p
              className="copytext custom-width"
              dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_error-msg_text1') }}
            />

            <Button
              buttonClass={appButtonType.PRIMARY}
              onClick={() => {
                emptySessionData();
                setIsFailureModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_sofort-top-up_error-msg_back')}
            </Button>
          </>
        );
      case appTopUpType.AUTOMATIC:
        return (
          <>
            <h2
              className="text-center text-uppercase my-4"
              dangerouslySetInnerHTML={{ __html: t('ek_fail_headline-default') }}
            />
            <p
              className="copytext custom-width"
              dangerouslySetInnerHTML={{ __html: t('ek_auto-top-up_error-msg_text1') }}
            />
            <Button
              buttonClass={appButtonType.PRIMARY}
              onClick={() => {
                emptySessionData();
                setIsFailureModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_sofort-top-up_error-msg_back')}
            </Button>
          </>
        );
      default:
        return (
          <>
            <h2
              className="text-center text-uppercase my-4"
              dangerouslySetInnerHTML={{ __html: t('ek_fail_headline-default') }}
            />
            <p
              className="copytext custom-width"
              dangerouslySetInnerHTML={{ __html: t('Your Last Payment Was Failed') }}
            />

            <Button
              buttonClass={appButtonType.PRIMARY}
              onClick={() => {
                emptySessionData();
                setIsSuccessModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_sofort-top-up_error-msg_back')}
            </Button>
          </>
        );
    }
  };

  return (
    <FullScreenModal isOpen={isFailureModalOpen && !isStaticContentLoading} fullscreen>
      <div className="modal-wh payment-failed vh-100 d-flex flex-column align-items-center justify-content-center mx-auto">
        <div className="payment-fail-icon mb-4">
          <img src={PaymentFailIcon} alt="paymentfailicon" />
        </div>
        {getFailureText()}
      </div>
    </FullScreenModal>
  );
}

export default FailureModal;
