/* eslint-disable prefer-const */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Slider } from '@part/Slider';
import { useTariff } from '@context/MobileOne';
import { appRoute } from '@utils/globalConstant';
import { TariffProduct } from '../Product';

export function TariffCatalogue() {
  // Constants

  // States
  const { allTariff, getTariffStatusName } = useTariff();

  // Contexts
  const navigate = useNavigate();

  // Functions
  function clicked(id, showTariffStatus) {
    const tariffDiv = document.querySelector(`#tariff${id}`).parentElement.parentElement
      .parentElement.className;

    if (tariffDiv.search('center') > 0 && !showTariffStatus) {
      navigate(appRoute.TARIFF_CHANGE(id));
    }
  }

  // Hooks
  // useEffect(() => {
  //   console.log(allTariff);
  // }, [allTariff]);

  return (
    <>
      {/* eslint-disable react/jsx-props-no-spreading */}
      <Slider
        items={allTariff.map((tariff) => (
          <TariffProduct
            key={`tariff-${tariff.id}`}
            {...{ ...tariff, showTariffStatus: getTariffStatusName(tariff.id) }}
            isChangeTariffAllowed
            isOverView
            // eslint-disable-next-line react/jsx-no-bind
            onClick={clicked}
            customClass="tariff-options-slide"
          />
        ))}
      />
    </>
  );
}

export default TariffCatalogue;
