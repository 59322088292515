import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

export function MetaTitle({ title, description, keywords }) {
  return (
    <Helmet>
      {title && <title>{title}</title>}
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
    </Helmet>
  );
}

MetaTitle.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  keywords: PropTypes.string.isRequired
};

export default MetaTitle;
