// import { useAuth } from "@dom-digital-online-media/dom-auth-sdk";
import React, { createContext, useContext, useMemo, useState } from 'react';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';
import { useAlert } from '@context/Utils';
import { appAlert } from '@utils/globalConstant';

export const ChargeHistoryContext = createContext();

export const ChargeHistoryProvider = function ({ children }) {
  // Context
  // const { isUserLoggedIn } = useAuth();
  const { onVoucherHistory } = useMobileOne();
  const { showAlert } = useAlert();
  // const { showLoader, hideLoader } = useLoader();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [history, setHistory] = useState([]);

  // Function
  const onLoad = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data = [], success } = await onVoucherHistory();
      if (data.length > 0 && success) {
        setHistory(data);
      }
      setIsLoading(false);
      // hideLoader();
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: `Error in request. ${error.message}` });
      }
      // showAlert({ type: appAlert.ERROR, message: 'Error in fetching history' });
    }
  };

  // // Hooks
  // useEffect(() => {
  //   if (isUserLoggedIn) {
  //     onLoad();
  //   }
  //   return () => {
  //     setHistory([]);
  //   }
  // }, [isUserLoggedIn])

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      history,
      setHistory,
      // API calls

      // Functions
      onLoad
    }),
    [
      // States
      isLoading,
      setIsLoading,
      history,
      setHistory,
      // API calls

      // Functions
      onLoad
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <ChargeHistoryContext.Provider value={contextPayload}>{children}</ChargeHistoryContext.Provider>
  );
};

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useChargeHistory = () => useContext(ChargeHistoryContext);

export default ChargeHistoryProvider;
