import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { MetaTitle } from '@core/Layout/MetaTitle';

export function PageNotFound() {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();

  // Functions

  // Hooks

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_not_found_meta_title')}
        description={t('ek_not_found_meta_description')}
        keywords={t('ek_not_found_meta_keywords')}
      />
      NotFound
    </div>
  );
}

export default PageNotFound;
