/* eslint-disable */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import warning from '@theme/assets/img/warning.svg';
import { Tooltip } from '@core/Utils';

import './style.scss';
import Tabs from '@core/Layout/Tabs';
import { Button, Radio, Switch, DropDown } from '@core/Inputs';
import { appButtonType } from '@utils/globalConstant';

// import { Col, Container, Row } from '@core/Layout';
// import { Switch, Text } from '@core/Inputs';
// import { ToolTip } from '@core/Utils';

export function Test() {
  return (
    <>
      <div className="my-5">
        <div className="container">
          <h1 className="mb-2">Primary Color</h1>
          <div className="ek-colors">
            <ul>
              <li>
                <div className="primary-background">
                  <div className="ek-primary-color">EDEKA smart Darkblue #153D63</div>
                  color: $primary;
                </div>
              </li>
              <li>
                <div className="primary-background">
                  <div className="ek-primary-light-color">EDEKA smart Lightblue #88c2eb</div>
                  color: $primary-light;
                </div>
              </li>
            </ul>
          </div>

          <h1 className="mb-2">Secondary Color</h1>
          <div className="ek-colors">
            <ul>
              <li>
                <div className="primary-background">
                  <div className="ek-talk">Talk #20B5EA</div>
                  color: $cyan;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-Kombi-xl">kombi XL #BC99C7</div>
                  color: $grey-600;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-Kombi-s">kombi S #DA435F</div>
                  color: $red-100;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-Kombi-max">kombi MAX #4EABA1 </div>
                  color: $grey-500 ;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-Kombi-m">kombi M #A3D911</div>
                  color: $grey-400;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-KomJahrespaket-start">Jahrespaket START #A3A2A2</div>
                  color: $grey-200;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-Kombi-l">kombi L #F39100</div>
                  color: $grey-300;
                </div>
              </li>

              <li>
                <div className="primary-background">
                  <div className="ek-KJahrespaket-premium">Jahrespaket Premium #D3B86F</div>
                  color: $yellow-300;
                </div>
              </li>
            </ul>
          </div>

          <h1 className="mb-2">Colors Necessary</h1>
          <div className="ek-colors">
            <ul>
              <li>
                <div className="primary-background">
                  <div className="ek-Success">Success #75DD7D</div>
                  color: $success;
                </div>
              </li>
              <li>
                <div className="primary-background">
                  <div className="ek-caution">Caution #FFC400</div>
                  color: $warning;
                </div>
              </li>
              <li>
                <div className="primary-background">
                  <div className="ek-error">Error #E24C4B</div>
                  color: $danger;
                </div>
              </li>
            </ul>
          </div>

          <h1 className="mb-2">Gradients</h1>
          <div className="ek-gradients">
            <ul>
              <li>
                <div className="gradient-background">
                  <div className="ek-gradient">Dark Gradient</div>
                  background: $dark-gradient;
                </div>
              </li>
              <li>
                <div className="light-gradient">
                  <div className="ek-light-gradient">Light Gradient</div>
                  background: $light-gradient;
                </div>
              </li>
            </ul>
          </div>

          <h1>V1 Form input</h1>
          <form>
            <div className="form-group">
              <label className="form-label v1" htmlFor="msisdn">
                Rufnummer{' '}
              </label>
              <input
                aria-describedby="msisdnBlock"
                name="msisdn"
                placeholder="01XXXXXXX"
                type="text"
                id="msisdn"
                className="form-control"
                value=""
              />
            </div>
            <div className="form-group">
              <label className="form-label v1" htmlFor="msisdn">
                Rufnummer{' '}
              </label>
              <input
                aria-describedby="msisdnBlock"
                name="msisdn"
                placeholder="01XXXXXXX"
                type="text"
                id="msisdn"
                className="form-control is-invalid"
                value=""
              />
            </div>
            <div className="form-group">
              <label className="form-label v1" htmlFor="msisdn">
                Rufnummer{' '}
              </label>
              <input
                aria-describedby="msisdnBlock"
                name="msisdn"
                placeholder="01XXXXXXX"
                type="text"
                id="msisdn"
                className="form-control is-valid"
                value=""
              />
            </div>
            <div className="form-group has-success">
              <label className="form-control-label v1" htmlFor="inputSuccess1">
                Valid input
              </label>
              <input
                type="text"
                value="correct value"
                className="form-control is-valid"
                id="inputValid"
              />
              <div className="valid-feedback">Success! You've done it.</div>
            </div>
            <div className="form-group has-danger">
              <label className="form-control-label v1" htmlFor="inputDanger1">
                Invalid input
              </label>
              <input
                type="text"
                value="wrong value"
                className="form-control is-invalid"
                id="inputInvalid"
              />
              <div className="invalid-feedback">Sorry, that username's taken. Try another?</div>
            </div>

            <div className="form-group">
              <label className="form-label v1" htmlFor="exampleSelect1">
                Example select
              </label>
              <select className="form-control" id="exampleSelect1">
                <option>1</option>
                <option>2</option>
                <option>3</option>
                <option>4</option>
                <option>5</option>
              </select>
            </div>
          </form>
          <h1 className="mt-3 mb-3">Custom Dropdown</h1>

          <DropDown
            apiData={[
              {
                id: 1,
                value: 'One'
              },
              {
                id: 2,
                value: 'Two'
              },
              {
                id: 3,
                value: 'Three'
              }
            ]}
          />

          {/* <div className="dropdown custom-dropdown">
            <a
              className="dropdown-toggle d-block w-100"
              data-toggle="dropdown"
              href="#"
              role="button"
              aria-haspopup="true"
              aria-expanded="false">
              Wunschtarif:
            </a>
            <div className="dropdown-menu">
              <a className="dropdown-item" href="#">
                Kombi S
              </a>
              <a className="dropdown-item" href="#">
                Kombi M
              </a>
              <a className="dropdown-item" href="#">
                Kombi L
              </a>
              <a className="dropdown-item" href="#">
                Kombi XL
              </a>
              <a className="dropdown-item" href="#">
                Jahrespaket START
              </a>
            </div>
          </div> */}

          <div className="mt-3 mb-3">
            <h1 className="mb-3">Radio buttons</h1>

            {/* <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
              />
              <label className="form-check-label" for="flexRadioDefault1">
                Default radio
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                checked
              />
              <label className="form-check-label" for="flexRadioDefault2">
                Default checked radio
              </label>
            </div> */}
            <Radio label="ek_tarif-change_text1" id="terms" name="terms" onChange={console.log} />
            {/* <div className="form-group">
              <div className="custom-control custom-radio">
                <input
                  type="radio"
                  id="customRadio1"
                  name="customRadio"
                  className="custom-control-input"
                  defaultChecked
                />
                <label className="custom-control-label" htmlFor="customRadio1">
                  Inactive
                </label>
              </div>
              <div className="custom-control custom-radio mb-2">
                <input
                  type="radio"
                  id="customRadio2"
                  name="customRadio"
                  className="custom-control-input"
                />
                <label className="custom-control-label" htmlFor="customRadio2">
                  Yes, I Agree
                </label>
              </div>
            </div> */}
          </div>

          <div className="mt-3 mb-3">
            <h1 className="mb-3">TOGGLES</h1>
            {/* <ToggleButton /> */}
            <Switch
              label="ek_tarif-change_text1"
              id="terms"
              name="terms"
              onChange={console.log}
              // isInvalid
              // isValid
              // error="Error message"
            />
          </div>

          <h1 className="mb-2 mt-5">Tooltip</h1>

          <div className="bs-component">
            <Tooltip title="top" toggle="tooltip">
              <button type="button" className="btn btn-secondary mx-2">
                Top
              </button>
            </Tooltip>

            <Tooltip title="left" toggle="tooltip">
              <button type="button" className="btn btn-secondary mx-2">
                left
              </button>
            </Tooltip>

            <Tooltip title="right" toggle="tooltip" placement="right">
              <button type="button" className="btn btn-secondary mx-2">
                Right
              </button>
            </Tooltip>

            <Tooltip title="bottom" toggle="tooltip" placement="bottom">
              <button type="button" className="btn btn-secondary">
                bottom
              </button>
            </Tooltip>
          </div>

          <Tabs />

          <div className="my-5">
            <h1 className="mb-3">Buttons</h1>

            <Button type={appButtonType.PRIMARY}>Primary</Button>
            <p className="mt-2">
              color: $white; <br />
              background: $dark-gradient; <br />
              box-shadow: $btn-box-shadow; <br />
              border-color: $primary;
            </p>

            <Button type={appButtonType.PRIMARY} isIcon icon={<i className="bi bi-arrow-right" />}>
              Primary
            </Button>
            <p className="mt-2">
              color: $white; <br />
              background: $dark-gradient; <br />
              box-shadow: $btn-box-shadow; <br />
              border-color: $primary;
            </p>

            <Button
              type={appButtonType.SECONDARY}
              isIcon
              icon={<i className="bi bi-arrow-right" />}
            >
              Secondary
            </Button>
            <p className="mt-2">
              background-color: $white; <br />
              color: $primary; <br />
              box-shadow: $secondary-btn-box-shadow; <br />
              border-color: $white;
            </p>

            <Button type={appButtonType.DANGER} isIcon icon={<i className="bi bi-arrow-right" />}>
              Danger
            </Button>
            <p className="mt-2">
              background: $danger !important; <br /> box-shadow: $danger-btn-box-shadow;
            </p>
          </div>

          <div className="my-5 headings">
            <h1>Heading 1 : NEXA XBOLD 28/34</h1>
            <p>
              font-family: $font-family-nexa-bold; <br /> font-size: $h1-font-size; <br />
              line-height: $line-height-lg; <br /> color: $primary; <br />
              letter-spacing: $character-spacing-1-12; <br /> text-align: $text-l; <br />{' '}
              text-transform: $text-uppercase;
            </p>
            <h2>Heading 2 : NEXA XBOLD 20/24</h2>
            <p>
              font-family: $font-family-nexa-bold; <br /> font-size: $h2-font-size; <br />
              line-height: $line-height-md; <br /> color: $primary; <br /> letter-spacing:
              $character-spacing-1-6; <br /> text-align: $text-l; <br /> text-transform:
              $text-uppercase;
            </p>
            <h3>Heading 3 : Poppins Medium 16/22</h3>
            <p>
              font-family: $font-family-poppins-medium; <br />
              font-size: $h3-font-size; <br />
              line-height: $line-height-sm; <br />
              color: $primary; <br />
              letter-spacing: $character-spacing-0-3; <br />
              text-align: $text-l; <br />
              text-transform: $text-uppercase;
            </p>
            <h4>Heading 4 : NEXA XBOLD 15/20</h4>
            <p>
              font-family: $font-family-nexa-bold; <br />
              font-size: $h4-font-size; <br />
              line-height: $line-height-xs; <br />
              color: $primary; <br />
              letter-spacing: $character-spacing-1-5; <br />
              text-align: $text-l; <br />
              text-transform: $text-uppercase;
            </p>
          </div>

          <div className="copy-text-section">
            <h1 className="mb-3">Paragraph text</h1>
            <p className="copytext">
              Copytext regular:: Lorem ipsum dolor sit amet, vel accumsan liberavisse ex
              <br />
              <span>
                font-family: $font-family-poppins-regular;
                <br />
                font-size: $font-size-sm;
                <br />
                color:$primary; <br />
                letter-spacing: $character-spacing-0-26; <br />
                line-height: $line-height-xs;
              </span>
            </p>
            <p className="copytext-medium">
              Copytext Medium :: Lorem ipsum dolor sit amet, vel accumsan liberavisse ex <br />
              <span>
                font-family: $font-family-poppins-medium; <br />
                font-size: $font-size-sm; <br />
                color: $primary;
                <br /> letter-spacing: $character-spacing-0-26;
                <br /> line-height: $line-height-xs;
              </span>
            </p>
            <p className="copytext-semibold">
              Copytext Semibold :: Lorem ipsum dolor sit amet, vel accumsan liberavisse ex <br />
              <span>
                font-family: $font-family-poppins-semibold; <br />
                font-size: $font-size-sm; <br />
                color: $primary;
                <br /> letter-spacing: $character-spacing-0-26;
                <br /> line-height: $line-height-xs;
              </span>
            </p>
          </div>

          <h1 className="mb-2">Links</h1>

          <a href="#">Link : Kundenportal</a>
          <p>
            font-family: $font-family-poppins-medium; <br />
            font-size: $font-size-sm; <br />
            color: $primary;
            <br /> letter-spacing: $character-spacing-0-26;
            <br /> line-height: $line-height-xs;
            <br /> text-decoration : $text-underline ;
          </p>
          <a className="link-light-blue" href="#">
            Link : Kundenportal
          </a>
          <p>
            font-family: $font-family-poppins-medium; <br />
            font-size: $font-size-sm; <br />
            color: $primary-light;
            <br /> letter-spacing: $character-spacing-0-26;
            <br /> line-height: $line-height-xs;
            <br /> text-decoration : $text-underline;
          </p>

          <a className="fussnote-link" href="#">
            Fussnote Link : Abbrechen
          </a>
          <p>
            font-family: $font-family-poppins-medium; <br />
            font-size: $font-size-xs; <br />
            color: $primary;
            <br /> letter-spacing: $character-spacing-0-26;
            <br /> line-height: $line-height-19;
            <br /> text-decoration : $text-underline;
          </p>

          <h1 className="mb-2 mt-3">Fussnote</h1>

          <p>
            <span className="fussnote">Fussnote : SIM/eSIM aktivieren</span>
          </p>
          <p>
            font-family: $font-family-poppins-medium; <br />
            font-size: $font-size-xs; <br />
            color: $primary;
            <br /> letter-spacing: $character-spacing-0-26;
            <br /> line-height: $line-height-19;
          </p>

          <h1 className="mb-2 mt-3">Breadcrums</h1>

          <p className="breadcum">
            {
              <span>
                Breadcrums : Übersicht {'>'} Konto {'>'}
              </span>
            }
          </p>
          <p>
            font-family: $font-family-poppins-medium; <br />
            font-size: $font-9; <br />
            color: $primary;
            <br /> letter-spacing: $character-spacing-0-2;
            <br /> line-height: $line-height-19;
          </p>

          <h1 className="mb-2 mt-3">Card</h1>
          <div className="card">
            <div className="card-body">
              <h2 className="card-title">Simple Card</h2>
              <p className="card-text">
                Some quick example text to build on the card title and make up the bulk of the
                card's content.
              </p>
              <button className="btn btn-primary" type="button">
                Primary
              </button>
            </div>
          </div>

          <h1 className="mb-4 mt-5">Tarif-Cards</h1>
          <div className="card tarrif-card mb-3 mt-3">
            <div className="card-body">
              <h2 className="card-title">Simple Card</h2>
              <div className="tarrif-list">
                <ul>
                  <li>
                    <p>
                      <span className="d-block"> 1 GB LTE max</span> mit bis zu 300 Mbit/s
                    </p>
                  </li>
                  <li>
                    <p>
                      <span className="d-block"> 50 Min. + 50 SMS </span> in alle dt. Netze
                    </p>
                  </li>
                  <li>
                    <p>
                      <span> FLAT (Min.+SMS) </span> <br /> ins Telekom Mobilfunknetz
                    </p>
                  </li>
                </ul>
                <hr />
              </div>

              <div className="tarrif-total">
                <p>
                  <span>9,95€ </span>/4 Wochen
                </p>
              </div>
            </div>
          </div>

          <h1 className="mb-4 mt-5">Glass-Cards</h1>
          <div className="card glass-card mb-3 mt-3">
            <div className="card-body">
              <h4 className="card-title">Glass for Forms</h4>

              <form>
                <div className="form-group">
                  <label className="form-label v1" htmlFor="msisdn">
                    Rufnummer{' '}
                  </label>
                  <input
                    aria-describedby="msisdnBlock"
                    name="msisdn"
                    placeholder="01XXXXXXX"
                    type="text"
                    id="msisdn"
                    className="form-control"
                    value=""
                  />
                </div>
                <div className="form-group">
                  <label className="form-label v1" htmlFor="msisdn">
                    E-Mail{' '}
                  </label>
                  <input
                    aria-describedby="msisdnBlock"
                    name="msisdn"
                    placeholder="name@mustermail.de"
                    type="text"
                    id="msisdn"
                    className="form-control"
                    value=""
                  />
                </div>
              </form>
            </div>
          </div>
          <h1 className="mt-5 mb-1">Modal Popup</h1>
          <button
            type="button"
            class="btn btn-primary"
            data-bs-toggle="modal"
            data-bs-target="#exampleModal"
          >
            Launch demo modal
          </button>
          <div className="modal">
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h3 className="modal-title">
                    Hinweis zur <br /> Rufnummernmitnahme
                  </h3>
                  <button
                    type="button"
                    className="close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="modal-body">
                  <img src={warning} alt="img" />
                  <p>
                    Bevor Sie Ihre Rufnummer mitnehmen können, müssen Sie unsere Kündigungshilfe bei
                    Ihrem bisherigen Anbieter einreichen. Einfach downloaden, ausfüllen, absenden.
                  </p>
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">
                    FORTFAHREN
                  </button>
                </div>
                <div className="modal-footer">
                  <a className="link-light-blue" href="#">
                    Zurück zu Anmeldung
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
          <>
            <h1 className="mt-5 mb-1">Alerts</h1>
            <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
              <symbol id="check-circle-fill" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
              </symbol>
              <symbol id="info-fill" viewBox="0 0 16 16">
                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
              </symbol>
              <symbol id="exclamation-triangle-fill" viewBox="0 0 16 16">
                <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
              </symbol>
            </svg>
            <div className="alert alert-primary d-flex align-items-center" role="alert">
              <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Info:">
                <use xlinkHref="#info-fill" />
              </svg>
              <h3>An example alert with an icon</h3>
            </div>
            <div className="alert alert-success d-flex align-items-center" role="alert">
              <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:">
                <use xlinkHref="#check-circle-fill" />
              </svg>
              <h3>An example alert with an icon</h3>
            </div>
            <div className="alert alert-warning d-flex align-items-center" role="alert">
              <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Warning:">
                <use xlinkHref="#exclamation-triangle-fill" />
              </svg>
              <h3>An example alert with an icon</h3>
            </div>
            <div className="alert alert-danger d-flex align-items-center" role="alert">
              <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:">
                <use xlinkHref="#exclamation-triangle-fill" />
              </svg>
              <h3>An example alert with an icon</h3>
            </div>
          </>
        </div>
      </div>
    </>
  );
}

export default Test;
