import React, { useEffect } from 'react';
import moment from 'moment';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { displayPrice } from '@utils/globalConstant';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import './style.scss';

export function ChargeHeader() {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();
  const {
    customerBalance: { totalBalance = 0 },
    isLoading: isCustomerLoading
  } = useCustomer();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { currentTopupDate } = useOnlineTopUp();

  // Functions

  const getTotalBalance = () => `${displayPrice(totalBalance)}`.replace('.', ',');

  const getUpdatedDate = () => {
    const createDate = new Date(currentTopupDate);
    const date1 = moment(createDate, 'DD.MM.YYYY').format('DD.MM.YYYY');
    return date1;
  };

  // Hooks

  useEffect(() => {
    if (isCustomerLoading || isStaticContentLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isCustomerLoading, isStaticContentLoading]);

  return (
    <div>
      <div className="dark-gradient w-100 aufladung-first">
        <div className="d-flex justify-content-between">
          <p className="copytext-semibold text-white mb-0">{t('ek_top-up_current-credit')}</p>
          <p className="copytext-semibold text-white mb-0">{getTotalBalance()} &euro;</p>
        </div>
        <div className="d-flex">
          {currentTopupDate && (
            <>
              <span className="text-white copytext">{t('ek_top-up_last_update')} </span>
              <span className="text-white copytext">{getUpdatedDate()}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ChargeHeader;
