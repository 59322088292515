import React from 'react';

export function Spinner() {
  return (
    <div style={{ width: '1rem', height: '1rem' }} className="spinner-border" role="status">
      <span className="visually-hidden">Loading...</span>
    </div>
  );
}

export default Spinner;
