import React from 'react';
import moment from 'moment';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { displayPrice } from '@utils/globalConstant';
import errorIcon from '@theme/assets/img/error.svg';

export function TopUpList({ item, onDeletePress }) {
  // Constants
  const momentObj = moment(item?.creationDate, 'YYYY-MM-DDThh:mm').format('DD.MM.YYYY hh:mm');

  // States

  // Contexts
  const { t } = useStaticContent();

  // Functions

  //  Hooks

  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <h5 className="copytext-medium">{item?.paymentMethod?.toUpperCase()}</h5>
          <span
            aria-hidden
            role="button"
            className="p-0 copytext d-flex"
            onClick={() => {
              onDeletePress(item);
            }}
          >
            <img className="error-icon" src={errorIcon} alt="ErrorIcon" />
            <u className="text-danger">{t('ek_top-up_delete_button')}</u>
          </span>
        </div>
        <ul className="list-unstyled mb-0">
          {/* <li className="copytext">{item?.status}</li> */}
          <li className="copytext-medium">{`${displayPrice(item?.amount)} €`}</li>
          <li className="copytext">{item?.cardNumber}</li>
          {item?.cardExpiryDate != null && item?.cardExpiryDate !== '' ? (
            <li className="copytext">{`${t('ek_autotop-up_text7')} ${item?.cardExpiryDate.substring(
              0,
              2
            )}/${item?.cardExpiryDate.substring(2, 4)}`}</li>
          ) : null}
          <li className="copytext">{momentObj}</li>
          <li className="copytext">{item?.brand}</li>
        </ul>
      </div>
      <br />
    </>
  );
}

export default TopUpList;
