import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appPaymentProductType } from '@utils/globalConstant';
import errorIcon from '@theme/assets/img/error.svg';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';

export function AutoTopUpList({ item, onDeletePress }) {
  // Constants
  const momentObj = moment(item?.created, 'YYYY-MM-DDThh:mm').format('DD.MM.YYYY hh:mm');

  // States
  const [recurringDay, setRecurringDay] = useState('');
  const [recurringLimit, setRecurringLimit] = useState('');
  const [recurringAmount, setRecurringAmount] = useState('');

  // Contexts
  const { t } = useStaticContent();
  const { autoTopUpQueryList, currentTopupType } = useOnlineTopUp();

  // Functions
  const currentType = () => {
    switch (currentTopupType) {
      case appPaymentProductType.LOW_BALANCE:
        return t('ek_tariff-details_text1').replace('{amount}', `${recurringLimit}`);
      case appPaymentProductType.RECURRING:
        return t('ek_tariff-details_text2')
          .replace('{day}', `${recurringDay}`)
          .replace('{amount}', `${recurringAmount}`);
      case null:
        return t('ek_tarif-details_no-topup');
      default:
        return t('ek_tariff-details_text3');
    }
  };

  //  Hooks
  useEffect(() => {
    if (item) {
      setRecurringDay(item?.recurringData?.day);
      setRecurringLimit(item?.lowBalanceLimit);
      setRecurringAmount(item?.amount);
    }
  }, [item]);

  return (
    <>
      <div>
        <div className="d-flex justify-content-between">
          <h5 className="copytext-medium">{`${t('ek_top-up_history-text3')} ${item?.msisdn}`}</h5>
          <span
            aria-hidden
            role="button"
            className="p-0 copytext"
            onClick={() => {
              onDeletePress(item);
            }}
          >
            <img className="error-icon" src={errorIcon} alt="ErrorIcon" />
            <u className="text-danger">{t('ek_top-up_delete_button')}</u>
          </span>
        </div>
        <ul className="mb-0">
          <li className="copytext">{`${t('ek_top-up_history-text2')} ${momentObj}`}</li>
          <li className="copytext">{autoTopUpQueryList?.length > 0 ? currentType() : null}</li>
        </ul>
        {item?.tariffEnabled && <span className="copytext">{t('ek_topup_display_tariff')}</span>}
      </div>
      <br />
    </>
  );
}

export default AutoTopUpList;
