import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import OTPInput from 'otp-input-react'; // { ResendOTP }
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount } from '@context/MobileOne/Account';
import { useLoader } from '@context/Utils';
import { Text, Button } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appButtonType, appRoute, appTextFieldLabelClass } from '@utils/globalConstant';
import { FullScreenModal } from '@core/Utils';
import errorIcon from '@theme/assets/img/error.svg';
import ReloadIcon from '@theme/assets/img/Reload.svg';
import success from '@theme/assets/img/success.svg';
import passwortAngeFordert from '@theme/assets/img/passwort_angefordert.svg';
import { ProfileInfo } from '../ProfileInfo';

export function ChangeEmail() {
  // Context
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const {
    isLoading,
    contactInitialValue,
    onChangeEmailSubmit,
    customChangePersonalDataSchema,
    contactSuccessModal,
    setContactSuccessModal,
    changeEmailForm,
    verifyEmailCode,
    onResendCode,
    validationSchemaCode,
    emailModal,
    emailCodeError,
    setEmailCodeError
  } = useAccount();
  const { showLoader, hideLoader } = useLoader();

  // Function

  // Hooks
  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_change_personal_data_meta_title')}
        description={t('ek_change_personal_data_meta_description')}
        keywords={t('ek_change_personal_data_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.PROFILE}>{t('ek_account_account')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_change-personal_h')}</h4>
          <br />
        </div>
      </div>

      <div className="mt-4 pt-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileInfo />
          </div>
          <div className="col-lg-7 offset-md-2">
            <div className="card">
              <div className="card-body pt-2">
                {changeEmailForm.isEmailDiff === 'NO' && (
                  <Formik
                    initialValues={contactInitialValue}
                    enableReinitialize
                    validationSchema={customChangePersonalDataSchema}
                    onSubmit={onChangeEmailSubmit}
                  >
                    {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <div className="col-lg-12">
                          <Text
                            label={t('ek_change-personal_h1')}
                            labelCustomClass={appTextFieldLabelClass.V1}
                            type="email"
                            id="email"
                            name="email"
                            onChange={handleChange}
                            value={values.email}
                            placeholder={t('ek_change-personal_h1')}
                            isValid={touched.email && !errors.email}
                            isInvalid={!!errors.email}
                            onBlur={handleBlur}
                            error={errors.email}
                          />
                        </div>

                        <div className="col-lg-12">
                          <Text
                            label={t('ek_change-personal_h2')}
                            labelCustomClass={appTextFieldLabelClass.V1}
                            type="text"
                            id="confirmEmail"
                            name="confirmEmail"
                            onChange={handleChange}
                            value={values.confirmEmail}
                            placeholder={t('ek_change-personal_h2')}
                            isValid={touched.confirmEmail && !errors.confirmEmail}
                            isInvalid={!!errors.confirmEmail}
                            onBlur={handleBlur}
                            error={errors.confirmEmail}
                          />
                        </div>

                        <div className="col-lg-12">
                          <Text
                            label={t('ek_change-personal_h4')}
                            labelCustomClass={appTextFieldLabelClass.V1}
                            type="text"
                            id="alternateNumberPrefix"
                            name="alternateNumberPrefix"
                            onChange={handleChange}
                            value={values.alternateNumberPrefix}
                            placeholder={t('ek_change-personal_h4')}
                            isValid={touched.alternateNumberPrefix && !errors.alternateNumberPrefix}
                            isInvalid={!!errors.alternateNumberPrefix}
                            onBlur={handleBlur}
                            error={errors.alternateNumberPrefix}
                          />
                        </div>

                        <div className="col-lg-12">
                          <Text
                            label={t('ek_change-personal_h3')}
                            labelCustomClass={appTextFieldLabelClass.V1}
                            type="text"
                            id="alternateNumber"
                            name="alternateNumber"
                            onChange={handleChange}
                            value={values.alternateNumber}
                            placeholder={t('ek_change-personal_h3')}
                            isValid={touched.alternateNumber && !errors.alternateNumber}
                            isInvalid={!!errors.alternateNumber}
                            onBlur={handleBlur}
                            error={errors.alternateNumber}
                          />
                        </div>

                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between pt-5">
                            <Button
                              buttonClass={appButtonType.LINK}
                              type="button"
                              onClick={() => {
                                navigate(-1);
                              }}
                            >
                              {t('ek_change-back_button')}
                            </Button>
                            <Button
                              buttonClass={appButtonType.PRIMARY}
                              type="submit"
                              isLoading={isLoading}
                            >
                              {t('ek_change-personal_save')}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
                {changeEmailForm.isEmailDiff === 'YES' && changeEmailForm.emailAddress !== '' && (
                  <Formik
                    initialValues={{ emailCode: '' }}
                    enableReinitialize
                    validationSchema={validationSchemaCode}
                    onSubmit={verifyEmailCode}
                  >
                    {({ values, handleSubmit, handleChange, touched, errors }) => (
                      <Form onSubmit={handleSubmit}>
                        <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_email_h1') }} />
                        <p className="copytext-semibold mb-0 pt-5 pb-5">
                          {t('ek_ap_activation_email_code_text1')}
                        </p>
                        <div className="email-varification-password">
                          <div className="d-flex justify-content-center">
                            <div>
                              <OTPInput
                                value={values.emailCode}
                                onChange={(e) => {
                                  setEmailCodeError(false);
                                  handleChange({
                                    target: {
                                      id: 'emailCode',
                                      name: 'emailCode',
                                      value: e
                                    }
                                  });
                                }}
                                inputClassName={
                                  (!!touched.emailCode && !!errors.emailCode) || emailCodeError
                                    ? 'is-invalid'
                                    : 'is-valid'
                                }
                                autoFocus
                                OTPLength={4}
                                otpType="number"
                                disabled={false}
                              />
                            </div>
                          </div>
                        </div>
                        {touched.emailCode && errors.emailCode && (
                          <div className="d-block invalid-feedback text-center">
                            <img className="error-icon" src={errorIcon} alt="error_icon" />
                            {errors.emailCode}
                          </div>
                        )}
                        {emailCodeError && (
                          <div className="d-block invalid-feedback text-center">
                            <img className="error-icon" src={errorIcon} alt="error_icon" />
                            {t('ek_ap_activation_code_fail')}
                          </div>
                        )}
                        <div className="d-block text-center pt-5 pb-4 mb-2">
                          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid, react/no-unknown-property */}
                          {/* <ResendOTP renderButton={renderButton} /> */}
                          <Button
                            buttonClass={appButtonType.LINK}
                            className="Copytext-Medium"
                            type="button"
                            onClick={onResendCode}
                          >
                            <img className="me-2" src={ReloadIcon} alt="ReloadIcon" />{' '}
                            <span
                              dangerouslySetInnerHTML={{
                                __html: t('ek_ap_email_resend_code_text')
                              }}
                            />
                          </Button>
                        </div>
                        <div className="d-flex justify-content-center">
                          <Button variant="primary" type="submit" isLoading={isLoading}>
                            {t('ek_ap_overview_confirm_button1')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={emailModal} fullscreen>
        <div className="emailmodal">
          <div className="d-flex justify-content-center text-center align-item-center vh-30 flex-column">
            <img className="mx-auto successicon" src={passwortAngeFordert} alt="successIcon" />
            <h2
              className="text-center mt-4 pt-2"
              dangerouslySetInnerHTML={{ __html: t('ek_ap_email-popup_text') }}
            />
          </div>
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={contactSuccessModal} fullscreen>
        <div>
          <div className="d-flex justify-content-center text-center align-item-center flex-column vh-100">
            <div className="success-icon">
              {' '}
              <img src={success} alt="success-icon" />{' '}
            </div>

            <h2 className="text-center pb-4 pt-4 mt-2 mb-2 text-uppercase">
              {t('ek_change-personal_success-msg')}
            </h2>
            <div className="d-flex justify-content-center text-center">
              <Button
                onClick={() => {
                  setContactSuccessModal(!contactSuccessModal);
                  navigate(appRoute.DASHBOARD);
                }}
              >
                {t('ek_back_overview')}
              </Button>
            </div>
          </div>
        </div>
      </FullScreenModal>
    </div>
  );
}

export default ChangeEmail;
