import React from 'react';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export function Home() {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();

  // Functions

  // Hooks

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_home_meta_title')}
        description={t('ek_home_meta_description')}
        keywords={t('ek_home_meta_keywords')}
      />
      <div>{t('home')}</div>
    </div>
  );
}

export default Home;
