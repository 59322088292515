/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

export const DocumentContext = createContext({});

export function DocumentContextProvider({ children }) {
  // States
  const [isLoading, setIsLoading] = useState(false);
  const [productInfoPdfs, setProductInfoPdfs] = useState();
  const [termsInfoPdfs, setTermsInfoPdfs] = useState();
  const [privacyInfoPdfs, setPrivacyInfoPdfs] = useState();
  const [evnPdfs, setEvnPdfs] = useState();
  const [mnpPdfs, setMnpPdfs] = useState();
  const [explainerVideos, setExplainerVideos] = useState();
  const [mediaImages, setMediaImages] = useState([]);
  const [appPopupImage, setAppPopupImage] = useState();
  const [loginBgImage, setLoginBgImage] = useState({});
  const [advantagesImgBg, setAdvantagesImgBg] = useState({});
  const [serviceImgBg, setServiceImgBg] = useState({});
  const [advantagesTopupImgBg, setAdvantagesTopupImgBg] = useState({});
  const [advantagesLoyaltyImgBg, setAdvantagesLoyaltyImgBg] = useState({});
  const [advantagesBirthdayImgBg, setAdvantagesBirthdayImgBg] = useState({});

  const [advantagesEmployeeImgBg, setAdvantagesEmployeeImgBg] = useState({});
  const [advantagesBirthdayContentImg, setAdvantagesBirthdayContentImg] = useState({});
  const [advantagesTopupContentImg, setAdvantagesTopupContentImg] = useState({});

  const [advantagesLoyalityContentImg, setAdvantagesLoyalityContentImg] = useState({});

  const [advantagesEmployeeContentImg, setAdvantagesEmployeeContentImg] = useState({});

  // Context
  const { staticContentData } = useStaticContent();

  // Validations

  // Functions

  const getExplainerVideos = () => {
    if (staticContentData) {
      const videoIds = staticContentData.ek_explanationVideos
        ? staticContentData.ek_explanationVideos
        : '';
      const mediaVideo = staticContentData.media_video ? staticContentData.media_video : '';
      const imagePreview = staticContentData.media_image ? staticContentData.media_image : '';
      // eslint-disable-next-line prefer-const
      let finalVideos = [];

      videoIds.forEach((elem, i) => {
        const { previewImg = [] } = elem;
        const videoPreviewId = previewImg['3x'];
        let videoPreviewImg;

        imagePreview.forEach((img, j) => {
          // eslint-disable-next-line eqeqeq
          if (videoPreviewId == img.id) {
            videoPreviewImg = img;
          }
        });

        mediaVideo.forEach((vid, k) => {
          if (elem.id === vid.id) {
            finalVideos.push({ ...elem, ...vid, previewImage: videoPreviewImg });
          }
        });
      });

      setExplainerVideos(finalVideos);
    }
  };

  // To get EVN category PDF
  const getEvnPdf = () => {
    if (staticContentData) {
      const evnPdfids = staticContentData.evn || [];
      const mediaPdfs = staticContentData.media_pdf || [];
      const evnPdfsList = [];

      mediaPdfs.forEach((pdf, i) => {
        // eslint-disable-next-line eqeqeq
        if (evnPdfids.id == pdf.id) {
          evnPdfsList.push(pdf);
        }
      });

      setEvnPdfs(evnPdfsList[0]);
    }
  };

  // To get EVN category PDF
  const getMnpPdf = () => {
    if (staticContentData) {
      const mnpPdfids = staticContentData.ek_pdfMnp || [];
      const mediaPdfs = staticContentData.media_pdf || [];
      const mnpPdfsList = [];

      mediaPdfs.forEach((pdf, i) => {
        // eslint-disable-next-line eqeqeq
        if (mnpPdfids.id == pdf.id) {
          mnpPdfsList.push(pdf);
        }
      });

      setMnpPdfs(mnpPdfsList[0]);
    }
  };

  // To get different category PDFs
  const getPdfs = () => {
    if (staticContentData) {
      const pdfsList = staticContentData.ek_pdfLists || [];
      const mediaPdfs = staticContentData.media_pdf || [];
      const productPdfs = [];
      const privacyPdfs = [];
      const termsPdfs = [];
      const productPdfIds = [];
      const termsPdfIds = [];
      const privacyPdfIds = [];

      pdfsList.forEach((elem, i) => {
        if (elem.listName === 'serviceDodumentsProductsheets') {
          elem.listContent.forEach((id, j) => {
            productPdfIds.push(id.id);
          });
        } else if (elem.listName === 'serviceDocumentsTerms') {
          elem.listContent.forEach((id, j) => {
            termsPdfIds.push(id.id);
          });
        } else if (elem.listName === 'serviceDocumentsPrivacy') {
          elem.listContent.forEach((id, j) => {
            privacyPdfIds.push(id.id);
          });
        }
      });

      mediaPdfs.forEach((pdf, i) => {
        productPdfIds.forEach((id, j) => {
          // eslint-disable-next-line eqeqeq
          if (id == pdf.id) {
            productPdfs.push(pdf);
          }
        });
        privacyPdfIds.forEach((id, j) => {
          // eslint-disable-next-line eqeqeq
          if (id == pdf.id) {
            privacyPdfs.push(pdf);
          }
        });
        termsPdfIds.forEach((id, j) => {
          // eslint-disable-next-line eqeqeq
          if (id == pdf.id) {
            termsPdfs.push(pdf);
          }
        });
      });

      setProductInfoPdfs(productPdfs);
      setTermsInfoPdfs(termsPdfs);
      setPrivacyInfoPdfs(privacyPdfs);
    }
  };

  const getMediaImgs = () => {
    if (staticContentData) {
      const images = staticContentData.media_image ? staticContentData.media_image : '';
      if (images) {
        setMediaImages(images);
        const appImage = images.find((img) => img.image_ref === 'ek_web_popup_app_bg');
        const bgimg = images.find(({ image_ref }) => image_ref === 'ek_web_login_bg');
        const advantagesimg = images.find(({ image_ref }) => image_ref === 'ek_web_advantages_bg');

        const advantagestopopimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-topup_bg'
        );

        const serviceimg = images.find(({ image_ref }) => image_ref === 'ek_web_service_bg');

        const advantagesemployeeimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-employee_bg'
        );

        const advantagesloyalityimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-loyality_bg'
        );

        const advantagesbirthdayimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-birthday_bg'
        );

        const advantagesbirthdaycontentimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-birthday_content-1'
        );

        const advantagestopupcontentimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-topup_content-1'
        );

        const advantagesloyalitycontentimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-loyality_content-1'
        );

        const advantagesemployeecontentimg = images.find(
          ({ image_ref }) => image_ref === 'ek_web_advantages-employee_content-1'
        );

        setLoginBgImage(bgimg);
        setAppPopupImage(appImage);
        if (advantagesimg) {
          setAdvantagesImgBg(advantagesimg);
        }
        setServiceImgBg(serviceimg);
        setAdvantagesEmployeeImgBg(advantagesemployeeimg);
        setAdvantagesLoyaltyImgBg(advantagesloyalityimg);
        setAdvantagesBirthdayImgBg(advantagesbirthdayimg);
        setAdvantagesTopupImgBg(advantagestopopimg);
        if (advantagestopopimg) {
          setAdvantagesTopupImgBg(advantagestopopimg);
        }
        setAdvantagesBirthdayContentImg(advantagesbirthdaycontentimg);
        setAdvantagesTopupContentImg(advantagestopupcontentimg);

        setAdvantagesLoyalityContentImg(advantagesloyalitycontentimg);
        setAdvantagesEmployeeContentImg(advantagesemployeecontentimg);
      }
    }
  };

  const downloadDocPDF = (link) => {
    window.location.href = link;
  };

  // Hooks
  useEffect(() => {
    if (staticContentData) {
      getMnpPdf();
      getPdfs();
      getMediaImgs();
    }
  }, [staticContentData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      productInfoPdfs,
      termsInfoPdfs,
      privacyInfoPdfs,
      evnPdfs,
      setEvnPdfs,
      explainerVideos,
      mnpPdfs,
      setMnpPdfs,
      mediaImages,
      appPopupImage,
      loginBgImage,
      advantagesImgBg,
      serviceImgBg,
      advantagesTopupImgBg,
      advantagesLoyaltyImgBg,
      advantagesBirthdayImgBg,
      advantagesEmployeeImgBg,
      advantagesBirthdayContentImg,
      advantagesTopupContentImg,
      advantagesLoyalityContentImg,
      advantagesEmployeeContentImg,

      // functions
      getPdfs,
      downloadDocPDF,
      getEvnPdf,
      getExplainerVideos
    }),
    [
      // States
      isLoading,
      setIsLoading,
      productInfoPdfs,
      termsInfoPdfs,
      privacyInfoPdfs,
      evnPdfs,
      setEvnPdfs,
      explainerVideos,
      mnpPdfs,
      setMnpPdfs,
      mediaImages,
      appPopupImage,
      loginBgImage,
      advantagesImgBg,
      serviceImgBg,
      advantagesTopupImgBg,
      advantagesLoyaltyImgBg,
      advantagesBirthdayImgBg,
      advantagesEmployeeImgBg,
      advantagesBirthdayContentImg,
      advantagesTopupContentImg,
      advantagesLoyalityContentImg,
      advantagesEmployeeContentImg,

      // functions
      getPdfs,
      downloadDocPDF,
      getEvnPdf,
      getExplainerVideos
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <DocumentContext.Provider value={contextPayload}>{children}</DocumentContext.Provider>;
}
DocumentContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useDocument = () => useContext(DocumentContext);

export default DocumentContext;
