/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import PropTypes from 'prop-types';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { PaymentMethod } from '@part/Payment';
import { Button } from '@core/Inputs';
import { appButtonType, appPaymentMethods } from '@utils/globalConstant';
import './style.scss';
// import Paypal from '@theme/assets/img/Pay_Paypal2.svg';

export function PaymentMethods({
  paymentMethod,
  chargingValidations,
  chargingFormSubmit,
  chargingForm
}) {
  const { t } = useStaticContent();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    });
  }, []);
  return (
    <Formik
      initialValues={chargingForm}
      validationSchema={chargingValidations}
      onSubmit={chargingFormSubmit}
    >
      {({
        values: { paymentMethodName },
        handleSubmit,
        handleChange,
        touched,
        errors,
        setFieldValue
      }) => (
        <div className="card mx-auto">
          <div className="card-body">
            <Form onSubmit={handleSubmit}>
              {/* <b>{t('activation-setup-topup')}:</b> */}

              <h3 dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_h6') }} />

              <div className="row mt-3">
                {/* <span
                  className="copytext-medium"
                  dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_subheader3') }}
                /> */}
                <br />
                {/* <div className="payment-top d-flex justify-content-center mt-3"> */}
                  {paymentMethod.map(
                    (payment) => (
                      // payment.name === appPaymentMethods.PAYPAL ||
                      // payment.name === appPaymentMethods.CREDIT_CARD ? (
                      <div
                        className="payment-method col-lg-4 py-1"
                        key={`${payment.name}-method`}
                        role="button"
                        onClick={() => {
                          setFieldValue('paymentMethodName', payment.name);
                        }}
                      >
                        <PaymentMethod
                          name={payment.name}
                          onSelect={(id) => {
                            handleChange('paymentMethodName')({
                              target: { name: 'paymentMethodName', value: id }
                            });
                          }}
                          isSelected={paymentMethodName === payment.name}
                        />
                      </div>
                    )
                    // ) : (
                    //   <></>
                    // )
                  )}
                {/* </div> */}
              </div>
              <div className="d-flex justify-content-end mt-4 pt-4">
                <Button
                  buttonClass={appButtonType.PRIMARY}
                  type="submit"
                  disabled={!paymentMethodName}
                >
                  {t('ek_ap_topup_confirm_button')}
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
    </Formik>
  );
}
PaymentMethods.propTypes = {
  paymentMethod: PropTypes.arrayOf(PropTypes.shape({}))
};
PaymentMethods.defaultProps = {
  paymentMethod: []
};

export default PaymentMethods;
