import React, { useEffect, useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAlphaComm } from '@dom-digital-online-media/dom-alphacomm-sdk';
import { Container } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { useConfig } from '@config/ContextManager';
import { useActivation, useCustomer } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useDashBoard } from '@context/MobileOne/DashBoard';

import {
  AutoTopUpFor,
  appAlert,
  appAutoTopUpType,
  appButtonType,
  // appPaymentTokenStatus,
  appRoute,
  appStorage,
  // AutoTopUpFor,
  displayPrice
  // appAutoTopUpType
} from '@utils/globalConstant';
import { useAlert, useLayout, useLoader } from '@context/Utils';
import { Button } from '@core/Inputs';
import { FullScreenModal } from '@core/Utils';
import PaymentFailIcon from '@theme/assets/img/fail.svg';
import PaymentSucessIcon from '@theme/assets/img/success.svg';

import './style.scss';
import FailureModal from './FailureModal';
import SuccessModal from './SuccessModal';

export function PaymentCallBack() {
  // Constants

  // States
  const [isLoading, setIsLoading] = useState(false);
  // const [autoTopupSuccessModal, setAutoTopupSuccessModal] = useState(false);
  // const [directTopupSuccessModal, setDirectTopupSuccessModal] = useState(false);
  // const [directTopupFailureModal, setDirectTopupFailureModal] = useState(false);
  // const [acivationTopupFailure, setAcivationTopupFailure] = useState(false);
  const [acivationTopupSuccess, setAcivationTopupSuccess] = useState(false);

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isFailureModalOpen, setIsFailureModalOpen] = useState(true);

  const [topupReason, setTopupReason] = useState(null);
  const [topUpType, setTopUpType] = useState(null);
  const [directTopUpAmount, setDirectTopUpAmount] = useState(null);
  const [paymentOrderStatus, setPaymentOrderStatus] = useState('');

  // const [directTopupAmount, setDirectTopupAmount] = useState('');

  // Contexts
  const { onPaymentCommitOrder } = useAlphaComm();
  const { showLoader, hideLoader } = useLoader();
  const { showAlert } = useAlert();
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const {
    config: { storage }
  } = useConfig();
  const {
    setCurrentStep,
    setIsActivationClicked,
    isLoading: isActivationLoading,
    onFailedPayment
  } = useActivation();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  // To clear session data
  const emptySessionData = async () => {
    await storage.encryptedRemoveItem(appStorage.CART_NAME);
    await storage.encryptedRemoveItem(appStorage.ORDER_NUMBER);
    await storage.encryptedRemoveItem(appStorage.PAYMENT_REASON);
    await storage.encryptedRemoveItem(appStorage.TOPUP_AMOUNT);
    await storage.encryptedRemoveItem(appStorage.TOPUP_TYPE);
  };

  const orderVerification = async (params) => {
    showLoader();
    try {
      const _topupReason = await storage.encryptedGetItem(appStorage.PAYMENT_REASON);
      const _amount = await storage.encryptedGetItem(appStorage.TOPUP_AMOUNT);
      console.log('_amount------------------', _amount);
      const _topUpType = await storage.encryptedGetItem(appStorage.TOPUP_TYPE);
      setDirectTopUpAmount(_amount);
      setTopupReason(_topupReason);
      setTopUpType(_topUpType);

      const { data = {}, success } = await onPaymentCommitOrder(params);
      // const { data, success } = { data: { orderStatus: 'success' }, success: true };
      // setPaymentOrderStatus(data.orderStatus);
      setPaymentOrderStatus(data.orderStatus);
      setIsLoading(false);
      if (data && success) {
        setIsSuccessModalOpen(true);
        setIsFailureModalOpen(false);
      } else {

        setIsSuccessModalOpen(false);
        setIsFailureModalOpen(true);
      }
    } catch (error) {
      console.log(error);
      setIsFailureModalOpen(true);
      setIsLoading(false);
      showAlert({
        type: appAlert.ERROR,
        message: error.error[0].messageBody
      });
    }
  };

  // Hooks
  useEffect(() => {
    // const orderNumber = sessionStorage.getItem(appStorage.ORDER_NUMBER);
    // const cartName = sessionStorage.getItem(appStorage.CART_NAME);
    showLoader();
    const queryParamsData = {
      paymentToken: queryParams.get('paymentTokenId'),
      orderNumber: queryParams.get('orderNumber'),
      cartName: queryParams.get('cartName')
    };

    if (queryParamsData.paymentToken || queryParamsData.orderNumber || queryParamsData.cartName) {
      // if (queryParams.get('paymentTokenId') && (orderNumber === queryParams.get('orderNumber') || cartName === queryParams.get('cartName'))){
      // showAlert({ type: appAlert.SUCCESS, message: 'Data Verfied Successfull..' });
      // const params = {
      //   "orderNumber": orderNumber,
      //   "cartName": cartName,
      //   "paymentToken": queryParams.get('paymentTokenId')
      // };
      orderVerification(queryParamsData);
    } else {
      hideLoader();
      showAlert({ type: appAlert.ERROR, message: `Invalid Data` });
    }
    // TODO REDIRECT TO HOME PAGE
  }, []);

  // useEffect(() => {
  //   if (acivationTopupSuccess) {
  //     setTimeout(() => {
  //       emptySessionData();
  //       setAcivationTopupSuccess(false);
  //       setIsActivationClicked(true);
  //       setCurrentStep(6);
  //       navigate(appRoute.ACTIVATION);
  //     }, 4000);
  //   }
  // }, [acivationTopupSuccess]);

  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isActivationLoading ||
      isLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isActivationLoading,
    isLoading
  ]);

  return (
    <Container>
      <MetaTitle
        title={t('ek_payment_call_back_meta_title')}
        description={t('ek_payment_call_back_meta_description')}
        keywords={t('ek_payment_call_back_meta_keywords')}
      />

      <SuccessModal
        isSuccessModalOpen={isSuccessModalOpen}
        setIsSuccessModalOpen={setIsSuccessModalOpen}
        topupReason={topupReason}
        topUpType={topUpType}
        directTopUpAmount={directTopUpAmount}
        isStaticContentLoading={isStaticContentLoading}
        emptySessionData={emptySessionData}
        orderStatus={paymentOrderStatus}
      />

      <FailureModal
        isFailureModalOpen={isFailureModalOpen}
        setIsFailureModalOpen={setIsFailureModalOpen}
        topupReason={topupReason}
        topUpType={topUpType}
        directTopUpAmount={directTopUpAmount}
        isStaticContentLoading={isStaticContentLoading}
        emptySessionData={emptySessionData}
        onFailedPayment={onFailedPayment}
        // orderStatus={paymentOrderStatus}
      />

      {/* <FullScreenModal isOpen={autoTopupSuccessModal} fullscreen>
        <div className="modal-wh payment-sucess vh-100 d-flex flex-column align-items-center justify-content-center mx-auto">
          <div className="payment-sucess-icon mb-4">
            <img src={PaymentSucessIcon} alt="paymentsuccessicon" />
          </div>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_success-msg_h1') }} />

          <p dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_success-msg_text1') }} />

          <Button
            onClick={() => {
              emptySessionData();
              setAutoTopupSuccessModal(false);
              navigate(appRoute.CHARGES);
            }}
          >
            {t('ek_autotop-up_success-msg_back')}
          </Button>
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={directTopupSuccessModal} fullscreen>
        <div className="modal-wh payment-sucess vh-100 d-flex flex-column align-items-center justify-content-center mx-auto">
          <div className="payment-sucess-icon mb-4">
            <img src={PaymentSucessIcon} alt="paymentsuccessicon" />
          </div>
          <h2
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_success-msg_h1') }}
          />

          <p
            className="copytext"
            dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_success-msg_text1') }}
          />
          <div className="d-flx top-up-amount mb-4">
            <h4
              className="d-inline-block copytext-semibold"
              dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_success-msg_text2') }}
            />

            <span className="d-inline-block ps-2">
              {t('ek_sofort-top-up_success-msg_amount')
                .replace('{top_up_amount}', `${displayPrice(directTopupAmount)} `)
                .replace('.', ',')}
            </span>
          </div>

          <Button
            onClick={() => {
              emptySessionData();
              setDirectTopupSuccessModal(false);
              navigate(appRoute.CHARGES);
            }}
          >
            {t('ek_sofort-top-up_success-msg_back')}
          </Button>
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={directTopupFailureModal} fullscreen>
        <div className="tariff-fail mx-auto d-flex justify-content-center flex-column align-items-center vh-100">
          <div className="payment-fail-icon mb-4">
            <img src={PaymentFailIcon} alt="paymentfailicon" />
          </div>
          <h2
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_error-msg_h1') }}
          />

          <p
            className="copy-text"
            dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_error-msg_text1') }}
          />

          <Button
            onClick={() => {
              emptySessionData();
              setDirectTopupFailureModal(false);
              navigate(appRoute.CHARGES);
            }}
          >
            {t('ek_sofort-top-up_error-msg_back')}
          </Button>
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={acivationTopupSuccess} fullscreen>
        <div className="modal-wh payment-sucess vh-100 d-flex flex-column align-items-center justify-content-center mx-auto">
          <div className="payment-sucess-icon mb-4">
            <img src={PaymentSucessIcon} alt="paymentsuccessicon" />
          </div>
          <h2
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_success_topup') }}
          />
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={acivationTopupFailure} fullscreen>
        <div className="modal-wh payment-sucess payment-failed  vh-100 d-flex flex-column align-items-center justify-content-center mx-auto">
          <h2 className="mb-4 pb-2" dangerouslySetInnerHTML={{ __html: t('ek_ap_fail_topup') }} />
          <div className="payment-fail-icon mb-4">
            <img src={PaymentFailIcon} alt="paymentfailicon" />
          </div>
          <p
            className="pb-5 pt-3 copytext"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_fail_message') }}
          />

          <Button
            buttonClass={appButtonType.PRIMARY}
            onClick={() => {
              emptySessionData();
              setAcivationTopupFailure(false);
              setIsActivationClicked(true);
              setCurrentStep(6);
              navigate(appRoute.ACTIVATION);
            }}
          >
            {t('ek_ap_fail_button')}
          </Button>
        </div>
      </FullScreenModal> */}
    </Container>
  );
}

export default PaymentCallBack;
