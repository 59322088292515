import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useCustomer, useTariff } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';

import { appButtonType, appRoute } from '@utils/globalConstant';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { Button } from '@core/Inputs';
import { FullScreenModal, Modal } from '@core/Utils';
import { AutoTopUpList } from '@part/TopUp';
import check from '@theme/assets/img/check.svg';
import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import { ChargeHeader } from './ChargeHeader';
import './style.scss';

export function Charge() {
  // Constants

  // States
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [autoTopUpItem, setAutoTopUpItem] = useState({});

  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { isUserLoggedIn } = useAuth();
  const {
    showTariffBanner,
    changeTariffName,
    tariffChangeInProgress,
    isLoading: isTariffLoading
  } = useTariff();
  const {
    deleteTopupSuccessModal,
    setDeleteTopupSuccessModal,
    topupOnLoad,
    autoTopUpQueryList,
    onDeleteAutoTopUp,
    onSsoTokenCallWithQuery,
    setAutoTopupMoreInfo,
    isLoading: isAutoTopUpLoading
  } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading, customerData } = useCustomer();

  // Functions
  const onDeletePress = (item) => {
    setAutoTopUpItem(item);
    setDeleteAlert(true);
  };

  const closeModal = () => setDeleteAlert(false);

  // Hooks

  useEffect(() => {
    if (isTariffLoading || isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isTariffLoading, isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading]);

  useEffect(() => {
    if (isUserLoggedIn && customerData) {
      onSsoTokenCallWithQuery();
    }
  }, [isUserLoggedIn, customerData]);

  return (
    <>
      {(tariffChangeInProgress || showTariffBanner) && (
        <div
          className="alert alert-warning text-center mt-2"
          role="alert"
          dangerouslySetInnerHTML={{
            __html: t('ek_tarif_success-msg_text1').replace('{tariff}', changeTariffName)
          }}
        />
      )}
      <div className="container">
        <MetaTitle
          title={t('ek_charge_meta_title')}
          description={t('ek_charge_meta_description')}
          keywords={t('ek_charge_meta_keywords')}
        />

        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_header') }} />
            <br />
          </div>
        </div>
        <div className="mt-4 pt-2">
          <div className="row">
            <div className="card mx-auto auto-topup p-0">
              <div className="card-body">
                <ChargeHeader />

                <div className="justify-content-center text-center btn-details pb-2 mb-2 pt-4 mt-2">
                  <div className="p-0">
                    <span
                      aria-hidden
                      role="button"
                      className="copytext-medium d-flex justify-content-center"
                      onClick={() => {
                        navigate(appRoute.RECHARGE_CREDIT);
                      }}
                    >
                      <div className="plus-icon me-3">
                        {' '}
                        <i className="bi bi-plus-lg" />
                      </div>
                      <u className="charges-text">{t('ek_top-up_button')}</u>
                    </span>
                  </div>
                </div>
                <hr />
                <div className="col-lg-12">
                  <div className="pt-3 pb-3 mb-2">
                    <span className="welcome-badge listing-badge d-flex rounded-pill text-bg-light">
                      <img src={EmpfehLung} alt="Icon" />{' '}
                      <h6 className="mb-0 px-2">{t('ek_autotop-up_recommendation')}</h6>
                    </span>
                  </div>
                </div>
                {autoTopUpQueryList.length > 0 ? (
                  <div>
                    <div className="d-flex justify-content-between">
                      <h5
                        className="copytext-medium"
                        dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_h1') }}
                      />
                      <h6 className="copytext-medium ps-3 text-success">
                        <img className="sucess-icon" src={check} alt="Icon" />
                        {t('ek_autotop-up_active').toUpperCase()}
                      </h6>
                    </div>
                    <p
                      className="copytext"
                      dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_text1') }}
                    />
                    {autoTopUpQueryList.length > 0 ? (
                      autoTopUpQueryList.map((token, index) => (
                        <div className="col-lg-12" key={`topup-list-${index}`}>
                          <AutoTopUpList item={token} onDeletePress={onDeletePress} />
                        </div>
                      ))
                    ) : (
                      <div>
                        <p
                          className="text-center"
                          dangerouslySetInnerHTML={{ __html: t('ek_no-data-found') }}
                        />
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="col-lg-12">
                    <div className="d-flex justify-content-between">
                      <h5 className="copytext-medium">{t('ek_autotop-up_h1')}</h5>
                      <span
                        aria-hidden
                        role="button"
                        className="p-0 copytext-medium"
                        onClick={() => {
                          topupOnLoad();
                          setAutoTopupMoreInfo(false);
                          navigate(appRoute.SET_AUTO_TOPUP);
                        }}
                      >
                        <u>{t('ek_autotop-up_setup')}</u>
                      </span>
                    </div>
                    <p
                      className="copytext pb-2"
                      dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_text8') }}
                    />
                  </div>
                )}
                <div className="text-center">
                  <Button
                    buttonClass={appButtonType.LINK}
                    type="button"
                    onClick={() => {
                      navigate(appRoute.TOPUP_HISTORY);
                    }}
                  >
                    {t('ek_top-up_history_h1')}
                  </Button>
                  <br />
                  <Button
                    buttonClass={appButtonType.LINK}
                    type="button"
                    onClick={() => {
                      navigate(appRoute.VOUCHER_HISTORY);
                    }}
                  >
                    {t('ek_top-up_history_h2')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FullScreenModal isOpen={deleteTopupSuccessModal} fullscreen>
          <div>
            <div className="d-flex justify-content-center text-center">
              <div>
                <h4>{t('ek_autotop-up_delete_success-msg_h1')}</h4>
              </div>
            </div>

            <div className="d-flex justify-content-center text-center">
              <div
                dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_delete_success-msg_text1') }}
              />
            </div>

            <div className="justify-content-center text-center">
              <Button
                onClick={() => {
                  setDeleteTopupSuccessModal(false);
                }}
              >
                {t('ek_autotop-up_delete_success-msg_text2')}
              </Button>
            </div>
          </div>
        </FullScreenModal>

        <Modal isOpen={deleteAlert} onClose={closeModal} isCloseButton>
          <div>
            <div className="text-center justify-content-center">
              <h4 className="text-white text-center">{t('ek_autotop-up_delete_h1')}</h4>

              <p>{t('ek_autotop-up_delete_text1')}</p>

              <Button
                onClick={() => {
                  setDeleteAlert(false);
                  onDeleteAutoTopUp(autoTopUpItem);
                }}
              >
                {t('ek_autotop-up_delete')}
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
}

Charge.propTypes = {};

export default Charge;

export * from './Voucher';
export * from './OnlineTopUp';
export * from './ChargeHistory';
export * from './ChargeHeader';
export * from './RechargeCredit';
export * from './TopUpHistory';
