import React from 'react';
import backIcon from '@theme/assets/img/arrow_left.svg';
import { appIconsPath } from '@utils/globalConstant';

function MoreInfoScreen({
  children,
  image = appIconsPath.ALPHACOMM_LOGO,
  heading = 'test heading',
  onBackClick = () => {}
}) {
  return (
    <div className="row">
      <div className="back-btn pb-4">
        <button className="btn bg-transparent p-0" onClick={onBackClick}>
          <img src={backIcon} alt="back" />
        </button>
      </div>
      <div className="main-body">
        {heading && <h3 className="py-3 m-0" dangerouslySetInnerHTML={{ __html: heading }} />}
        <div className="children-div py-3">{children}</div>
        <div className="image-div text-center py-4">
          <div className="pt-2 pb-4">
            <img className="pb-4" src={image} alt="alphacomm" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default MoreInfoScreen;
