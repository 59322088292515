import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import {
  appButtonType,
  appRoute,
  appTariffs,
  displayPrice,
  gettingNumberForGerman
} from '@utils/globalConstant';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { Button } from '@core/Inputs';
import './style.scss';

export function ActiveTariffSection() {
  // Constants

  // States
  const [tariffBullets, setTariffBullets] = useState([]);

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const {
    customerData: { msisdn = '' }
  } = useCustomer();
  const {
    tariffDetails: {
      id = 0,
      name = '',
      price = '',
      duration = '',
      durationUnit = '',
      additionalInfo: { primaryColor = '', bullets = [] } = {}
    } = {},
    tariffDetails
  } = useDashBoard();

  // Functions
  const customerNumber = () => gettingNumberForGerman(msisdn);
  const getTariffPrice = () =>
    `${displayPrice(price)} &euro; / ${duration} ${durationUnit}`.replace('.', ',');

  // Hooks
  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let tBullets = [];

    if (bullets) {
      if (bullets.length > 2) {
        tBullets.push(bullets[0]);
        tBullets.push(bullets[1]);
        tBullets.push(bullets[2]);
        tBullets.push(bullets[3]);
      } else {
        tBullets.push(bullets[0]);
        tBullets.push(bullets[1]);
      }
      setTariffBullets(tBullets);
    }
  }, [bullets]);

  return (
    <>
      {tariffDetails && (
        <div>
          <p className="copytext-medium mb-0">
            {t('ek_book-data-number')}
            <span className="d-inline-block ps-2"> {customerNumber()} </span>
          </p>

          <p className="copytext-medium mb-2">
            {t('ek_book-data_active-tariff')}

            {primaryColor && (
              <span className="ps-3 d-inline-block" style={{ color: primaryColor }}>
                {id === appTariffs.JAHRESTARIF_PREMIUM ? t('ek_tariff_premium') : name}
              </span>
            )}
          </p>
          <div className="tarif-options-list">
            <ul>
              {tariffBullets.map((item, index) => (
                <li className="d-flex copytext-medium py-1" key={`tariff-bullet-${index}`}>
                  <i className="bi bi-dot" />
                  <div dangerouslySetInnerHTML={{ __html: item?.text }} />
                </li>
              ))}
            </ul>
            <hr />
            <p className="copytext-medium" dangerouslySetInnerHTML={{ __html: getTariffPrice() }} />
            <Button
              buttonClass={appButtonType.LINK}
              onClick={() => {
                navigate(appRoute.TARIFF_OPTION);
              }}
              className="p-0"
            >
              {t('ek_tariff-details_change')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
}

export default ActiveTariffSection;
