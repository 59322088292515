import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@core/Inputs';

export function Breadcrumbs({ children }) {
  return (
    <nav style={{ bsBreadcrumbDivider: '>' }} aria-label="breadcrumb">
      <ol className="breadcrumb">{children}</ol>
    </nav>
  );
}

export function BreadcrumbItem({ children, route = '' }) {
  return (
    <li className="breadcrumb-item">
      {/* {route !== '' ? ( */}
        <Link href={route} to={route}>
          {children} {'>'}
        </Link>
      {/* ) : (
        <span className='bold'>{children}</span>
      )} */}
    </li>
  );
}

Breadcrumbs.prototype = {
  children: PropTypes.node.isRequired
};

BreadcrumbItem.prototype = {
  children: PropTypes.node.isRequired,
  route: PropTypes.string
};

BreadcrumbItem.defaultProps = {
  children: PropTypes.node.isRequired,
  route: ''
};

export default Breadcrumbs;
