import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Button } from '@core/Inputs';
import { appButtonType, appPaymentProductType, appRoute, appTariffs } from '@utils/globalConstant';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';

import './style.scss';
import { useLayout, useLoader } from '@context/Utils';

export function TariffDetails() {
  // Constants

  // States
  const [tariffBullets, setTariffBullets] = useState([]);
  const [recurringDay, setRecurringDay] = useState('');
  const [recurringLimit, setRecurringLimit] = useState('');
  const [recurringAmount, setRecurringAmount] = useState('');

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { isLoading: isStaticContentLoading } = useLayout();
  const {
    customerData: { msisdn = '', loyaltyBonus = false },
    personalData: { flags = {} },
    customerProducts,
    isBirthdayBonus,
    isLoading: isCustomerLoading,
    evnData
  } = useCustomer();
  const {
    tariffDetails: {
      id = 0,
      name = '',
      price = '',
      duration = '',
      durationUnit = '',
      additionalInfo = {}
    },
    isLoading: isDashboardLoading
  } = useDashBoard();
  const {
    isAutoTopUpActive,
    autoTopUpQueryList,
    currentTopupType,
    isLoading: isAutoTopUpLoading
  } = useOnlineTopUp();
  const { showLoader, hideLoader } = useLoader();

  // Functions
  const getMsisdn = () => `${t('ek_tariff-details_h1').replace('{{costumers_number}}', msisdn)}`;
  const getTariffPrice = () => `${price} &euro; / ${duration} ${durationUnit}`;

  const currentType = () => {
    switch (currentTopupType) {
      case appPaymentProductType.LOW_BALANCE:
        return t('ek_tariff-details_text1').replace('{amount}', `${recurringLimit}`);
      case appPaymentProductType.RECURRING:
        return t('ek_tariff-details_text2')
          .replace('{day}', `${recurringDay}`)
          .replace('{amount}', `${recurringAmount}`);
      case null:
        return t('ek_tarif-details_no-topup');
      default:
        return t('ek_tariff-details_text3');
    }
  };

  // Hooks
  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let tBullets = [];

    if (additionalInfo.bullets) {
      if (additionalInfo.bullets.length <= 2) {
        tBullets.push(additionalInfo.bullets[0]);
        tBullets.push(additionalInfo.bullets[1]);
      } else {
        tBullets.push(additionalInfo.bullets[0]);
        tBullets.push(additionalInfo.bullets[1]);
        tBullets.push(additionalInfo.bullets[2]);
        tBullets.push(additionalInfo.bullets[3]);
      }
      setTariffBullets(tBullets);
    }
  }, [additionalInfo.bullets]);

  useEffect(() => {
    if (autoTopUpQueryList?.length > 0) {
      setRecurringDay(autoTopUpQueryList[0]?.recurringData?.day);
      setRecurringLimit(autoTopUpQueryList[0]?.lowBalanceLimit);
      setRecurringAmount(autoTopUpQueryList[0]?.amount);
    }
  }, [autoTopUpQueryList]);

  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_user_tariff_details_meta_title')}
        description={t('ek_user_tariff_details_meta_description')}
        keywords={t('ek_user_tariff_details_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_tariff-details_h')}</h4>
          <br />
        </div>
      </div>
      <div className="mt-4 pt-0">
        <div className="row">
          <div className="col-lg-4">
            <div className="tariff-details">
              <p className="copytext-medium mb-0">
                {t('ek_ap_activation_nr_header')}
                <span className="d-inline-block ps-2">{getMsisdn()}</span>
              </p>
              <p className="copytext-medium mb-0">
                {t('ek_ap_chosen_tariff')}
                <span
                  className="d-inline-block ps-2"
                  style={{ color: `${additionalInfo?.primaryColor}` }}
                >
                  <strong>
                    {id === appTariffs.JAHRESTARIF_PREMIUM ? t('ek_tariff_premium') : name}
                  </strong>
                </span>
              </p>
              <div className="tarif-options-list">
                <ul className="list-unstyled pt-2">
                  {tariffBullets &&
                    tariffBullets.map((item, index) => (
                      <li className="d-flex copytext-medium py-1" key={`tariff-bullet-${index}`}>
                        <i className="bi bi-dot" />
                        <div>
                          <span className="p-0" dangerouslySetInnerHTML={{ __html: item?.text }} />
                        </div>
                      </li>
                    ))}
                </ul>
              </div>

              <hr />
              <p
                className="copytext-medium"
                dangerouslySetInnerHTML={{ __html: getTariffPrice() }}
              />
              <Button
                buttonClass={appButtonType.LINK}
                onClick={() => {
                  navigate(appRoute.TARIFF_OPTION);
                }}
                className="p-0"
              >
                {t('ek_tariff-details_change')}
              </Button>
            </div>
          </div>
          <div className="col-lg-7 offset-md-1">
            <div className="card tariff-details-option mt-2">
              <div className="card-body">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="copytext-medium mb-0">{t('ek_tariff-details_h3')}</p>
                    <span className="copytext">{customerProducts[0]?.endDate}</span>
                  </div>
                  <div className="mb-3">
                    <Button
                      buttonClass={appButtonType.LINK}
                      customClass="d-flex"
                      onClick={() => {
                        navigate(appRoute.CANCEL_CONTRACT);
                      }}
                    >
                      {t('ek_tariff-details_termination')}
                    </Button>
                  </div>
                </div>
                <div>
                  <span>{}</span>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <p className="copytext-medium mb-0">{t('ek_tariff-details_h4')}</p>
                  {evnData.evnAllowed ? (
                    <Button
                      buttonClass={appButtonType.LINK}
                      onClick={() => {
                        navigate(appRoute.PROFILE);
                      }}
                    >
                      {t('ek_tariff-details_lookevn')}
                    </Button>
                  ) : (
                    <Button
                      buttonClass={appButtonType.LINK}
                      onClick={() => {
                        navigate(appRoute.PROFILE);
                      }}
                    >
                      {t('ek_tariff-details_application')}
                    </Button>
                  )}
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <p className="copytext-medium mb-0">{t('ek_tariff-details_h5')}</p>
                  {isAutoTopUpActive ? (
                    <Button
                      buttonClass={appButtonType.LINK}
                      onClick={() => {
                        navigate(appRoute.CHARGES);
                      }}
                    >
                      {t('ek_tariff-details_adjust')}
                    </Button>
                  ) : (
                    <Button
                      buttonClass={appButtonType.LINK}
                      onClick={() => {
                        navigate(appRoute.SET_AUTO_TOPUP);
                      }}
                    >
                      {t('ek_autotop-up_setup')}
                    </Button>
                  )}
                </div>
                {isAutoTopUpActive ? (
                  <ul className="pt-1 ps-0">
                    <li className="d-flex copytext py-1">
                      <i className="bi bi-dot" />
                      {currentType()}
                    </li>
                  </ul>
                ) : (
                  <ul className="pt-1 ps-0">
                    <li className="d-flex copytext py-1">
                      <i className="bi bi-dot" />
                      {t('ek_tarif-details_no-topup')}
                    </li>
                  </ul>
                )}

                <div className="d-flex justify-content-between">
                  <p className="copytext-medium ">{t('ek_tariff-details_h6')}</p>
                  <Button
                    role="button"
                    buttonClass={appButtonType.LINK}
                    customClass="d-flex"
                    onClick={() => {
                      navigate(appRoute.ADVANTAGES);
                    }}
                  >
                    {t('ek_tariff-details_details')}
                  </Button>
                </div>
                <div className="tarif-options-list">
                  <ul className="list-unstyled">
                    {flags?.employee && (
                      <li className="d-flex copytext py-1">
                        <i className="bi bi-check-lg me-2" />
                        <div dangerouslySetInnerHTML={{ __html: t('ek_tariff-details_text4') }} />
                      </li>
                    )}
                    {loyaltyBonus && (
                      <li className="d-flex copytext py-1">
                        <i className="bi bi-check-lg me-2" />
                        <div dangerouslySetInnerHTML={{ __html: t('ek_tariff-details_text5') }} />
                      </li>
                    )}
                    {isBirthdayBonus && (
                      <li className="d-flex copytext py-1">
                        <i className="bi bi-check-lg me-2" />
                        <div dangerouslySetInnerHTML={{ __html: t('ek_tariff-details_text6') }} />
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TariffDetails;
