import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useLayout, useLoader } from '@context/Utils';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { Tooltip } from '@core/Utils/Tooltip';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import './style.scss';

export function ProfileInfo() {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();
  const {
    personalData: { firstName = '', lastName = '', birthDate = '', address = '' },
    customerData: { msisdn = '', customerNumber = '' },
    isLoading: isCustomerLoading
  } = useCustomer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isDashboardLoading } = useDashBoard();

  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  // Functions
  const getDMYFormat = (date) => {
    const createDate = new Date(date);
    const date1 = moment(createDate, 'DD.MM.YYYY').format('DD.MM.YYYY');
    return date1;
  };

  // Hooks

  return (
    <div className="row">
      <div className="col-lg-12">
        <div className="mb-3 pb-2">
          <p className="copytext-medium mb-1">{t('ek_account_text1')}</p>
          <span className="copytext">{msisdn}</span>
        </div>

        <div className="mb-3 pb-2">
          <p className="copytext-medium mb-1">{t('ek_account_text2')}</p>
          <span className="copytext">{customerNumber}</span>
        </div>

        <div className="mb-3 pb-2">
          <p className="copytext-medium mb-1">{t('ek_account_text3')}</p>
          <span className="copytext">{`${firstName} ${lastName}`}</span>
        </div>

        <div className="mb-3 pb-2">
          <p className="copytext-medium mb-1">{t('ek_account_text4')}</p>
          <span className="copytext">{getDMYFormat(birthDate)}</span>
        </div>
        <div className="mb-3 pb-2">
          <div className="copytext-medium mb-1">
            {t('ek_account_text5')}
            <Tooltip title={t('ek_account_move_text')}>
              <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
            </Tooltip>
          </div>

          <span className="copytext d-flex">
            {address && address.street} {address && address.houseNumber}
          </span>
          <span className="copytext d-flex">
            {address && address.zip} {address && address.city}
          </span>
          <span className="copytext d-flex">{address && address.countryName}</span>
        </div>
      </div>
    </div>
  );
}

export default ProfileInfo;
