import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { Button, Text } from '@core/Inputs';
import { appTextFieldLabelClass } from '@utils/globalConstant';
import EditIcon from '@theme/assets/img/bearbeiten.svg';

export function UserAccount({
  msisdn,
  cscPassword,
  hotlinePassword,
  handleBlur,
  handleChange,
  errors,
  touched
}) {
  // States
  const [isEditableUser, setIsEditableUser] = useState(false);

  // Contexts
  const { t } = useStaticContent();
  const {
    phoneNumberActivationForm: { oldNumberPrefix = '', oldNumber = '', mnp = false }
  } = useActivation();

  // Functions
  const onEditClick = () => {
    setIsEditableUser(!isEditableUser);
  };
  return (
    <>
      <div className="card-edit d-flex justify-content-between">
        <h4>{t('ek_ap_overview_subheader2')}</h4>

        {/*
          NOTE: edit button for passwords is deactivated in overview in activation journey,
          if you want to reactivate this functionality, please reactivate validation in activation context
          and don't forget to use the new password validation as described there.
         */}
        {/* <Button type="button" onClick={onEditClick}>
          <img src={EditIcon} alt="editicon" />
        </Button> */}
      </div>
      <div className="row">
        <div className="col-md-12">
          <Text
            type="text"
            labelCustomClass={appTextFieldLabelClass.V1}
            inputCustomClass="not-editable"
            label={t('ek_ap_overview_nr')}
            id="msisdn"
            name="msisdn"
            onChange={handleChange}
            value={msisdn}
            placeholder={t('ek_ap_overview_nr')}
            isValid={touched.msisdn && !errors.msisdn}
            isInvalid={!!touched.msisdn && !!errors.msisdn}
            onBlur={handleBlur}
            error={touched.msisdn && errors.msisdn ? errors.msisdn : null}
            isDisabled
          />
        </div>
        {mnp ? (
          <div className="col-md-12">
            <Text
              type="text"
              labelCustomClass={appTextFieldLabelClass.V1}
              id="mnp-overview"
              inputCustomClass="not-editable"
              label={t('ek_ap_overview_mnp')}
              value={`${oldNumberPrefix}${oldNumber}`}
              placeholder={t('ek_ap_overview_mnp')}
              isDisabled
            />
          </div>
        ) : (
          <div className="col-md-12 mt-4">
            <p className="copytext">{t('ek_ap_overview_no_mnp')}</p>
          </div>
        )}
        <div className="col-md-12">
          <Text
            type="password"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_overview_pw')}
            id="cscPassword"
            name="cscPassword"
            onChange={handleChange}
            value={cscPassword}
            placeholder={t('ek_ap_overview_pw')}
            isValid={touched.cscPassword && !errors.cscPassword}
            isInvalid={!!touched.cscPassword && !!errors.cscPassword}
            onBlur={handleBlur}
            error={touched.cscPassword && errors.cscPassword ? errors.cscPassword : null}
            isDisabled={!isEditableUser}
          />
        </div>
        <div className="col-md-12">
          <Text
            type="password"
            labelCustomClass={appTextFieldLabelClass.V1}
            label={t('ek_ap_overview_hotlinepw')}
            id="hotlinePassword"
            name="hotlinePassword"
            onChange={handleChange}
            value={hotlinePassword}
            placeholder={t('ek_ap_overview_hotlinepw')}
            isValid={touched.hotlinePassword && !errors.hotlinePassword}
            isInvalid={!!touched.hotlinePassword && !!errors.hotlinePassword}
            onBlur={handleBlur}
            error={
              touched.hotlinePassword && errors.hotlinePassword ? errors.hotlinePassword : null
            }
            isDisabled={!isEditableUser}
          />
        </div>
      </div>
    </>
  );
}

UserAccount.propTypes = {
  msisdn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cscPassword: PropTypes.string,
  hotlinePassword: PropTypes.string,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};
UserAccount.defaultProps = {
  msisdn: '',
  cscPassword: '',
  hotlinePassword: '',
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};

export default UserAccount;
