import React, { useEffect, useState } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useCustomer } from '@context/MobileOne';
import { appRoute, appTokenStatus } from '@utils/globalConstant';
import { Button } from '@core/Inputs';
import { Modal } from '@core/Utils';
import { BreadcrumbItem, Breadcrumbs, Card, CardBody } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { ChargeHeader } from '../ChargeHeader';
import { TopUpList } from '../TopUpList';

export function TopUpHistory() {
  // Constants

  // States
  const [deleteAlert, setDeleteAlert] = useState(false);
  const [autoTopUpItem, setAutoTopUpItem] = useState({});

  // Contexts
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const {
    paymentTokens,
    setPaymentTokens,
    onDeleteConfirm,
    alphaComToken,
    getAutoTopUpData,
    isLoading: isAutoTopUpLoading
  } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();

  // Functions
  const onDeletePress = (item) => {
    setAutoTopUpItem(item);
    setDeleteAlert(true);
  };

  const closeModal = () => setDeleteAlert(false);

  //  Hooks
  useEffect(() => {
    if (isUserLoggedIn && alphaComToken) {
      setPaymentTokens([]);
      getAutoTopUpData();
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_topup_history_meta_title')}
        description={t('ek_topup_history_meta_description')}
        keywords={t('ek_topup_history_meta_keywords')}
      />

      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.CHARGES}>{t('ek_bc_charging')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_top-up_history_h1') }} />
          <br />
        </div>
      </div>

      <Card customClass="mx-auto mt-5 p-0">
        <CardBody>
          <ChargeHeader />
          <br />
          {paymentTokens.length > 0 ? (
            paymentTokens.map(
              (token, index) =>
                token.status === appTokenStatus.CONFIRMED && (
                  <div className="col-lg-12" key={`topup-list-${index}`}>
                    <TopUpList item={token} onDeletePress={onDeletePress} />
                  </div>
                )
            )
          ) : (
            <div>
              <p
                className="text-center"
                dangerouslySetInnerHTML={{ __html: t('ek_no-data-found') }}
              />
            </div>
          )}
        </CardBody>
      </Card>

      <Modal isOpen={deleteAlert} onClose={closeModal} isCloseButton>
        <div>
          <div className="text-center justify-content-center">
            <h4 className="text-white text-center">{t('ek_autotop-up_delete_h1')}</h4>

            <p dangerouslySetInnerHTML={{ __html: t('ek_autotop-up_delete_text1') }} />

            <Button
              onClick={() => {
                setDeleteAlert(false);
                onDeleteConfirm(autoTopUpItem);
              }}
            >
              {t('ek_autotop-up_delete')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default TopUpHistory;
