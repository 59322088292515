import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useChargeHistory } from '@context/AlphaComm';
import { useLayout, useLoader } from '@context/Utils';
import { useCustomer } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appRoute } from '@utils/globalConstant';
import { ChargeHeader } from '../ChargeHeader';

export function ChargeHistory() {
  // State
  // const [loadHistory, setLoadHistory] = useState(false);

  // Context
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const { history, setHistory, onLoad, isLoading: isHistoryLoading } = useChargeHistory();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();

  // Hooks
  useEffect(() => {
    if (isUserLoggedIn) {
      setHistory([]);
      onLoad();
    }
  }, []);

  useEffect(() => {
    if (isHistoryLoading || isStaticContentLoading || isCustomerLoading || isAutoTopUpLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isHistoryLoading, isStaticContentLoading, isCustomerLoading, isAutoTopUpLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_voucher_history_meta_title')}
        description={t('ek_voucher_history_meta_description')}
        keywords={t('ek_voucher_history_meta_keywords')}
      />

      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.CHARGES}>{t('ek_bc_charging')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_top-up_history_h2') }} />
          <br />
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <div className="card mx-auto">
            <div className="card-body">
              <ChargeHeader />
              <br />
              {history.length > 0 ? (
                <div>
                  <div className="d-flex justify-content-between">
                    <div>Date</div>
                    <div>Amount</div>
                  </div>
                  {history.map((item, index) => (
                    <div className="d-flex justify-content-between" key={`charge-history-${index}`}>
                      <div className="copytext pb-2">{item.date}</div>
                      <div className="copytext pb-2">{item.amount} &euro;</div>
                    </div>
                  ))}
                </div>
              ) : (
                <div>
                  <center>{t('ek_no-data-found')}</center>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChargeHistory;
