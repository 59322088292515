import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { useCustomer, useDocument } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import ArrowRightSmall from '@theme/assets/img/arrow_right_small.svg';
import AutoAufladung from '@theme/assets/img/auto-aufladung.svg';
import Treuebonus from '@theme/assets/img/treuebonus.svg';
import MitarbeiterVorteil from '@theme/assets/img/mitarbeiter_vorteil.svg';
import Geburtstagsbonus from '@theme/assets/img/geburtstagsbonus.svg';

import './style.scss';

export function Advantages() {
  // Constants

  const { advantagesImgBg } = useDocument();
  const { isLoading: isAutoTopUpLoading, isAutoTopUpActive } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // States

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();

  // Functions

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div
      className="advanatges-bg"
      style={{
        backgroundImage: `url(${
          advantagesImgBg?.responsive_image ? advantagesImgBg.responsive_image['2x'] : ''
        })`
      }}>
      <div className="container">
        <MetaTitle
          title={t('ek_advantages_meta_title')}
          description={t('ek_advantages_meta_description')}
          keywords={t('ek_advantages_meta_keywords')}
        />

        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>
                <span dangerouslySetInnerHTML={{ __html: t('ek_bc_dashboard') }} />
              </BreadcrumbItem>
              <BreadcrumbItem route={appRoute.USER_ACCOUNT}>
                <span dangerouslySetInnerHTML={{ __html: t('ek_column_five') }} />
              </BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_advantages_h') }} />
            <br />
          </div>
        </div>
        <div className="mt-4 pt-0">
          <div className="row">
            <div className="col-lg-4">
              <div className="help-legal">
                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.TRUE_BONUS);
                  }}>
                  <span>
                    <img src={Treuebonus} alt="Treuebonus-icon" />
                    <h5
                      className="copytext-medium"
                      dangerouslySetInnerHTML={{ __html: t('ek_advantages_no1') }}
                    />
                  </span>

                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.BIRTHDAY);
                  }}>
                  <span>
                    <img src={Geburtstagsbonus} alt="Geburtstagsbonus-icon" />
                    <h5
                      className="copytext-medium"
                      dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2') }}
                    />
                  </span>

                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    if (isAutoTopUpActive) {
                      navigate(appRoute.CHARGES);
                    } else {
                      navigate(appRoute.ADVANTAGE_AUTOTOPUP);
                    }
                  }}>
                  <span>
                    <img src={AutoAufladung} alt="AutoAufladung-icon" />
                    <h5
                      className="copytext-medium"
                      dangerouslySetInnerHTML={{ __html: t('ek_advantages_no4') }}
                    />
                  </span>

                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>

                <div
                  className="d-flex justify-content-between mb-4"
                  aria-hidden
                  role="button"
                  onClick={() => {
                    navigate(appRoute.INSIDE_BONUS);
                  }}>
                  <span>
                    <img src={MitarbeiterVorteil} alt="MitarbeiterVorteil-icon" />
                    <h5
                      className="copytext-medium"
                      dangerouslySetInnerHTML={{ __html: t('ek_advantages_no5') }}
                    />
                  </span>

                  <img src={ArrowRightSmall} alt="rightarrow-icon" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;

export * from './TrueBonus';
export * from './Birthday';
export * from './InsideBonus';
export * from './AdvantageAutoTopup';
