import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import {
  Routes,
  Route,
  useSearchParams,
  useLocation,
  useNavigate,
  Navigate
} from 'react-router-dom';

// import { PrivateRoute } from '@config/AppRoutes/PrivateRoute';
import { appStorage } from '@dom-digital-online-media/dom-app-config-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';

import {
  Test,
  Home,
  Login,
  Activation,
  Account,
  PageNotFound,
  Charge,
  PaymentCallBack,
  DashBoard,
  ForgetPassword,
  Profile,
  HelpAndServices,
  CancelContract,
  ChangeEmail,
  ChangeHotlinePassword,
  PasswordChange,
  RechargeCredit,
  SetAutoTopup,
  TariffDetails,
  Contact,
  Faq,
  ExplainerVideo,
  Legal,
  Documents,
  PrivacyDocuments,
  FootNotes,
  Imprint,
  Advantages,
  TrueBonus,
  Birthday,
  InsideBonus,
  AdvantageAutoTopup,
  SetDirectTopup,
  ForcePasswordChange,
  Voucher,
  Preferences,
  TopUpHistory,
  ChargeHistory,
  LockUnlockSIM
} from '@page/index';

import { withLayout } from '@part/Layout';
import { appRoute, appAlert, storageKeys } from '@utils/globalConstant';

import { useConfig } from '@config/ContextManager';
import { useAlert } from '@context/Utils';

import {
  BookDataPass,
  BookOption,
  BookRoaming,
  BookSpeedOn,
  BookPassOffers,
  ChangeTariff,
  Tariff,
  EsimTariff,
  Option
} from '@page/TariffOption';
import { PrivateRoute } from './PrivateRoute';

export function AllRoutes() {
  // Constants

  // States
  const [oauthVerifyLoading, setOauthVerifyLoading] = useState(true);

  // Contexts
  const location = useLocation();
  const navigate = useNavigate();

  const { showAlert } = useAlert();
  const { verifyLogin, setUserLogin } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    config: { storage }
  } = useConfig();

  // Functions
  const getAccess = async (code) => {
    try {
      const codeVerifier = await storage.getItem(appStorage.AUTH_CODE_VERIFIER);

      if (code && codeVerifier) {
        setOauthVerifyLoading(false);

        await storage.encryptedSetItem(appStorage.AUTH_CODE, code);
        // eslint-disable-next-line no-debugger
        const { data } = await verifyLogin(code, codeVerifier);

        // setUserLogin(true);
        await storage.encryptedSetItem(appStorage.AUTH_TOKEN, data.access_token);
        await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, data.refresh_token);
        await storage.encryptedSetItem(appStorage.USER_AUTH_DATA, JSON.stringify(data));

        // Clear up url;
        setSearchParams('');
        // Check if user is comming from previous route
        const APP_PREVIOUS_ROUTE_STORAGE = 'navigateTo';
        if (localStorage && localStorage.getItem(APP_PREVIOUS_ROUTE_STORAGE)) {
          setTimeout(() => {
            const previousRoute = localStorage.getItem(APP_PREVIOUS_ROUTE_STORAGE);
            localStorage.removeItem(APP_PREVIOUS_ROUTE_STORAGE);
            navigate(previousRoute);
          }, 150);
        }
        showAlert({ type: appAlert.SUCCESS, message: 'Welcome' });
        return true;
      }
      setUserLogin(false);
      setOauthVerifyLoading(false);
      setSearchParams('');
      return false;
    } catch (error) {
      console.log(error);
      setOauthVerifyLoading(false);
      // Show alert and clear up navigation
      showAlert({ type: appAlert.ERROR, message: 'Something went wrong!' });
      setSearchParams('');
      return false;
    }
  };

  const oauthValidate = async () => {
    const accessToken = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
    const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
    // setUserLogin(accessToken && refreshToken); // useEffect rerendring stoped by this
    if (accessToken && refreshToken) {
      setUserLogin(true);
    } else {
      setUserLogin(false);
    }
    setTimeout(() => {
      setOauthVerifyLoading(false);
    }, 150);
    return accessToken && refreshToken;
  };

  const callbackValidator = async () => {
    try {
      const sessionState = searchParams.get(storageKeys.SESSION_STATE);
      const code = searchParams.get(storageKeys.CODE);

      // eslint-disable-next-line no-debugger
      if (code && sessionState) {
        await storage.encryptedSetItem(storageKeys.CODE, code);
        await storage.encryptedSetItem(storageKeys.SESSION_STATE, sessionState);
        await getAccess(code);
      } else {
        await oauthValidate();
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Hooks
  // Authentication callback
  useEffect(() => {
    callbackValidator();
  }, []);

  // useEffect(() => {
  //   oauthValidate();
  // }, [location]);

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant' // Optional if you want to skip the scrolling animation
    });
  }, [location.pathname]);

  if (oauthVerifyLoading) {
    return <>Loading...</>;
  }

  return (
    <Routes>
      {/* Payment Return UI Page */}
      <Route path={appRoute.PAYMENT_CALLBACK} element={<PaymentCallBack />} />

      {/* Testing UI Page */}
      <Route path={appRoute.TEST} element={<Test />} />

      {/* App Login Page */}
      <Route index path={appRoute.LOGIN} element={<Login />} />

      {/* App Landing Page */}
      <Route index path={appRoute.LANDING_PAGE} element={<Login />} />

      {/* App Forgot Password Page */}
      <Route index path={appRoute.FORGOT_PASSWORD} element={<ForgetPassword />} />

      {/* App Home Page */}
      <Route index path={appRoute.HOME} element={<Home />} />

      {/* App SIM Activation Page */}
      <Route index path={appRoute.ACTIVATION} element={<Activation />} />

      {/* Dashboard Page */}
      <Route
        index
        path={appRoute.DASHBOARD}
        element={
          <PrivateRoute>
            <DashBoard />
          </PrivateRoute>
        }
      />

      <Route
        index
        path={appRoute.FORCE_PASSWORD_CHANGE}
        element={
          <PrivateRoute>
            <ForcePasswordChange />
          </PrivateRoute>
        }
      />

      {/* Tariff & Option Page */}
      <Route
        index
        path={appRoute.TARIFF_OPTION}
        element={
          <PrivateRoute>
            <Tariff />
          </PrivateRoute>
        }
      />

      {/* Tariff Change Page */}
      <Route
        index
        path={appRoute.TARIFF_CHANGE()}
        element={
          <PrivateRoute>
            <ChangeTariff />
          </PrivateRoute>
        }
      />

      {/* Tariff Change Page */}
      <Route
        index
        path={appRoute.ESIM_TARIFF}
        element={
          <PrivateRoute>
            <EsimTariff />
          </PrivateRoute>
        }
      />

      {/* Option Book Page */}
      <Route
        index
        path={appRoute.OPTION_BOOK()}
        element={
          <PrivateRoute>
            <BookOption />
          </PrivateRoute>
        }
      />

      {/* SpeedOn Book Page */}
      <Route
        index
        path={appRoute.BOOKABLE_DATA}
        element={
          <PrivateRoute>
            <Option />
          </PrivateRoute>
        }
      />

      {/* SpeedOn Book Page */}
      <Route
        index
        path={appRoute.SPEEDON_BOOK()}
        element={
          <PrivateRoute>
            <BookSpeedOn />
          </PrivateRoute>
        }
      />

      {/* PassOffer Book Page */}
      <Route
        index
        path={appRoute.PASSOFFER_BOOK()}
        element={
          <PrivateRoute>
            <BookPassOffers />
          </PrivateRoute>
        }
      />

      {/* DataPass Book Page */}
      <Route
        index
        path={appRoute.DATAPASS_BOOK()}
        element={
          <PrivateRoute>
            <BookDataPass />
          </PrivateRoute>
        }
      />

      {/* Roaming Book Page */}
      <Route
        index
        path={appRoute.ROAMING_BOOK()}
        element={
          <PrivateRoute>
            <BookRoaming />
          </PrivateRoute>
        }
      />

      {/* User App Account */}
      <Route
        index
        path={appRoute.USER_ACCOUNT}
        element={
          <PrivateRoute>
            <Account />
          </PrivateRoute>
        }
      />

      {/* User App Preferences */}
      <Route
        index
        path={appRoute.PREFERENCES}
        element={
          <PrivateRoute>
            <Preferences />
          </PrivateRoute>
        }
      />

      {/* User App Advantages */}
      <Route
        index
        path={appRoute.ADVANTAGES}
        element={
          <PrivateRoute>
            <Advantages />
          </PrivateRoute>
        }
      />

      {/* User App Advantages True Bonus */}
      <Route
        index
        path={appRoute.TRUE_BONUS}
        element={
          <PrivateRoute>
            <TrueBonus />
          </PrivateRoute>
        }
      />

      {/* User App Advantages Birthday */}
      <Route
        index
        path={appRoute.BIRTHDAY}
        element={
          <PrivateRoute>
            <Birthday />
          </PrivateRoute>
        }
      />

      {/* User App Advantages Inside Bonus */}
      <Route
        index
        path={appRoute.INSIDE_BONUS}
        element={
          <PrivateRoute>
            <InsideBonus />
          </PrivateRoute>
        }
      />

      {/* User App Advantages Auto Topup Page */}
      <Route
        index
        path={appRoute.ADVANTAGE_AUTOTOPUP}
        element={
          <PrivateRoute>
            <AdvantageAutoTopup />
          </PrivateRoute>
        }
      />

      {/* Lock Unlock user Sim */}
      <Route
        index
        path={appRoute.LOCK_UNLOCK_SIM}
        element={
          <PrivateRoute>
            <LockUnlockSIM />
          </PrivateRoute>
        }
      />

      {/* User Charge Account */}
      <Route
        index
        path={appRoute.CHARGES}
        element={
          <PrivateRoute>
            <Charge />
          </PrivateRoute>
        }
      />

      {/* User Charge Topup History */}
      <Route
        index
        path={appRoute.TOPUP_HISTORY}
        element={
          <PrivateRoute>
            <TopUpHistory />
          </PrivateRoute>
        }
      />

      {/* User Charge Voucher History */}
      <Route
        index
        path={appRoute.VOUCHER_HISTORY}
        element={
          <PrivateRoute>
            <ChargeHistory />
          </PrivateRoute>
        }
      />

      {/* User Recharge Credit Screen */}
      <Route
        index
        path={appRoute.RECHARGE_CREDIT}
        element={
          <PrivateRoute>
            <RechargeCredit />
          </PrivateRoute>
        }
      />

      {/* User Set Auto Topup Screen */}
      <Route
        index
        path={appRoute.SET_AUTO_TOPUP}
        element={
          <PrivateRoute>
            <SetAutoTopup />
          </PrivateRoute>
        }
      />

      {/* User Set Auto Topup Screen */}
      <Route
        index
        path={appRoute.SET_DIRECT_TOPUP}
        element={
          <PrivateRoute>
            <SetDirectTopup />
          </PrivateRoute>
        }
      />

      {/* User Set Auto Topup Screen */}
      <Route
        index
        path={appRoute.RECHARGE_VOUCHER}
        element={
          <PrivateRoute>
            <Voucher />
          </PrivateRoute>
        }
      />

      {/* User Profile */}
      <Route
        index
        path={appRoute.PROFILE}
        element={
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        }
      />

      {/* User Change contact Info screen */}
      <Route
        index
        path={appRoute.CHANGE_USER_INFO}
        element={
          <PrivateRoute>
            <ChangeEmail />
          </PrivateRoute>
        }
      />

      {/* User Change Password screen */}
      <Route
        index
        path={appRoute.CHANGE_PASSWORD}
        element={
          <PrivateRoute>
            <PasswordChange />
          </PrivateRoute>
        }
      />

      {/* User Change Hotline Password screen */}
      <Route
        index
        path={appRoute.CHANGE_HOTLINE}
        element={
          <PrivateRoute>
            <ChangeHotlinePassword />
          </PrivateRoute>
        }
      />

      {/* Help and Service */}
      <Route
        index
        path={appRoute.HELP_AND_SERVICE}
        element={
          <PrivateRoute>
            <HelpAndServices />
          </PrivateRoute>
        }
      />

      {/* Active Tariff Detail Page */}
      <Route
        index
        path={appRoute.TARIFF_DETAILS}
        element={
          <PrivateRoute>
            <TariffDetails />
          </PrivateRoute>
        }
      />

      {/* Contact Screen */}
      <Route
        index
        path={appRoute.CONTACT}
        element={
          <PrivateRoute>
            <Contact />
          </PrivateRoute>
        }
      />

      {/* FAQ Screen */}
      <Route
        index
        path={appRoute.FAQ}
        element={
          <PrivateRoute>
            <Faq />
          </PrivateRoute>
        }
      />

      {/* Explainer Video Screen */}
      <Route
        index
        path={appRoute.EXPLAINER_VIDEO}
        element={
          <PrivateRoute>
            <ExplainerVideo />
          </PrivateRoute>
        }
      />

      {/* Legal Screen */}
      {/* Privacy Documents Screen */}
      <Route
        index
        path={appRoute.LEGAL}
        element={
          <PrivateRoute>
            <Legal />
          </PrivateRoute>
        }
      />

      {/* Cancel Contract Screen */}
      <Route
        index
        path={appRoute.CANCEL_CONTRACT}
        element={
          <PrivateRoute>
            <CancelContract />
          </PrivateRoute>
        }
      />

      {/* Documents Screen */}
      <Route
        index
        path={appRoute.DOCUMENTS}
        element={
          <PrivateRoute>
            <Documents />
          </PrivateRoute>
        }
      />

      {/* Documents Screen */}
      <Route
        index
        path={appRoute.PRIVACY_DOCS}
        element={
          <PrivateRoute>
            <PrivacyDocuments />
          </PrivateRoute>
        }
      />

      {/* Footnotes Screen */}
      <Route
        index
        path={appRoute.FOOT_NOTES}
        element={
          <PrivateRoute>
            <FootNotes />
          </PrivateRoute>
        }
      />

      {/* Imprint Screen */}
      <Route
        index
        path={appRoute.IMPRINT}
        element={
          <PrivateRoute>
            <Imprint />
          </PrivateRoute>
        }
      />

      {/* Public Paths */}
      <Route path={appRoute.NOT_FOUND} element={<PageNotFound />} />
      <Route path="*" element={<Navigate to={appRoute.NOT_FOUND} replace />} />
    </Routes>
  );
}

// AppRoutes.propTypes = {
//   location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired
// };

// export default withRouter(withAlert(withToast(withLayout(AppRoutes))));
export const AppRoutes = withLayout(AllRoutes);
export default AppRoutes;
