import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';

export function Legitimation() {
  // Contexts
  const { t } = useStaticContent();
  const { processWithPostIdent, setShowBackButton } = useActivation();

  // Hooks
  useEffect(() => {
    setShowBackButton(false);

    return () => {
      setShowBackButton(true);
    };
  }, []);
  return (
    <div className="card mx-auto">
      <div className="card-body">
        <h3 dangerouslySetInnerHTML={{ __html: t('ek_ap_legitimation_h1') }} />

        <div className="pt-2">
          <p
            className="mt-4 mb-3 copytext-semibold"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_legitimation_subheader') }}
          />
          <p
            className="copytext"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_legitimation_option1') }}
          />
        </div>
        <div>
          <div
            className="copytext"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_legitimation_text1') }}
          />
        </div>

        <div className="mt-5 d-flex justify-content-end">
          <Button onClick={processWithPostIdent}>{t('ek_ap_legitimation_start_button')}</Button>
        </div>
      </div>
    </div>
  );
}

export default Legitimation;
