import React, { useEffect } from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { useCustomer, useDocument } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useLayout, useLoader } from '@context/Utils';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';

import downloadIcon from '@theme/assets/img/download.svg';
import birthdaySearchIcon from '@theme/assets/img/bday-search.svg';
import birthdayGiftIcon from '@theme/assets/img/bday-gift.svg';

import './style.scss';

export function Birthday() {
  // Constants
  const { advantagesBirthdayImgBg, advantagesBirthdayContentImg } = useDocument();
  // States

  // Contexts
  const { t } = useStaticContent();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div
      className="advanatges-bg"
      style={{
        backgroundImage: `url(${
          advantagesBirthdayImgBg?.responsive_image
            ? advantagesBirthdayImgBg.responsive_image['2x']
            : ''
        })`
      }}
    >
      <div className="container">
        <MetaTitle
          title={t('ek_birthday_meta_title')}
          description={t('ek_birthday_meta_description')}
          keywords={t('ek_birthday_meta_keywords')}
        />

        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.ADVANTAGES}>
                {t('ek_public_account_advantages')}
              </BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2_breadcrumb') }} />
            <br />
          </div>
        </div>

        <div className="row">
          <div className="card true-bonus-card pt-4 mt-4 pb-4">
            <div className="true-bonus-center birthday-bonus-center">
              <div className="col-lg-12">
                <center>
                  <div className="birthday-content-img">
                    <img
                      src={advantagesBirthdayContentImg?.media_url_display}
                      alt="birthday-content-img"
                    />
                  </div>
                  <p
                    className="copytext text-center pt-4 pb-3"
                    dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2_text1') }}
                  />

                  <hr />

                  <p
                    className="text-center copytext-medium pt-3 pb-3"
                    dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2_h2') }}
                  />
                </center>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex true-bonus-bottom ">
                    <div>
                      <div className="text-center pb-3">
                        <img className="download_icon" src={downloadIcon} alt="downloadIcon" />
                      </div>
                      <p
                        className="text-center"
                        dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2_text10') }}
                      />
                    </div>

                    <div>
                      <div className="text-center pb-3">
                        <img
                          className="birthday_search_icon"
                          src={birthdaySearchIcon}
                          alt="birthdaySearchIcon"
                        />
                      </div>
                      <p
                        className="text-center"
                        dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2_text3') }}
                      />
                    </div>

                    <div>
                      <div className="text-center pb-3">
                        <img
                          className="birthday_gift_icon"
                          src={birthdayGiftIcon}
                          alt="birthdayGiftIcon"
                        />
                      </div>
                      <p
                        className="text-center"
                        dangerouslySetInnerHTML={{ __html: t('ek_advantages_no2_text4') }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Birthday;
