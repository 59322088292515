import React from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { Button } from '@core/Inputs';
import { useNavigate } from 'react-router-dom';
import { appButtonType, appRoute, displayPrice } from '@utils/globalConstant';
import './style.scss';

export function CustomerHeader() {
  // States

  // Context
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const {
    tariffDetails: { name = '' }
  } = useDashBoard();
  const {
    personalData: { firstName = '', lastName = '' },
    customerData: { msisdn = '' },
    customerBalance: { totalBalance = 0 }
  } = useCustomer();

  // Functions
  const getTotalBalance = () =>
    `${t('ek_dashboard_credit')} ${displayPrice(totalBalance)}`.replace('.', ',');

  const getUserNumber = () => `${t('ek_dashboard_number').replace('{costumers_number}', msisdn)}`;

  const getCustomerName = () =>
    `${t('ek_web_dashboard_text').replace('{prename surname}', `${firstName} ${lastName}`)}`;

  // Hooks

  return (
    <div className="dashboard-top">
      <div className="row">
        <div className="col-lg-5">
          <h3 dangerouslySetInnerHTML={{ __html: getCustomerName() }} />
        </div>
        <div className="col-lg-7">
          <div className="dash-tarif-detail">
            <div>
              <div className="d-flex justify-content-between">
                <div>
                  <h4 className="text-white">{name}</h4>

                  <p className="text-white m-0">{getUserNumber()}</p>
                </div>
                <Button
                  buttonClass={appButtonType.LINK}
                  className="p-0 text-white"
                  onClick={() => {
                    navigate(appRoute.TARIFF_DETAILS);
                  }}
                >
                  {t('ek_dashboard_tarif_details')}
                  <i className="bi bi-chevron-right" />
                </Button>
              </div>
              <hr />
              <div className="d-flex justify-content-between">
                <h4 className="text-white">{getTotalBalance()} &euro;</h4>

                <Button
                  buttonClass={appButtonType.LINK}
                  className="p-0 text-white"
                  onClick={() => {
                    navigate(appRoute.CHARGES);
                  }}
                >
                  {t('ek_dashboard_topup')}
                  <i className="bi bi-chevron-right" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomerHeader;
