import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount } from '@context/MobileOne/Account';
import { Text, Button } from '@core';
import { appButtonType, appRoute, appTextFieldLabelClass } from '@utils/globalConstant';
import { FullScreenModal } from '@core/Utils';
import errorIcon from '@theme/assets/img/error.svg';
import sucessIcon from '@theme/assets/img/check.svg';
import './style.scss';
import { useLoader } from '@context/Utils';

export function ChangePassword({ isProfile, isForceReset }) {
  // Constants

  // States

  // Context
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const {
    isLoading,
    changePasswordInitialValue,
    changePasswordValidation,
    validatePasswordWithKeys,
    onChangePasswordSubmit,
    passwordSuccessModal,
    setPasswordSuccessModal,
    changePasswordErrorMsg,
    onForceChangePasswordSubmit
  } = useAccount();
  const { showLoader, hideLoader } = useLoader();

  // Functions

  const onClickDashboard = () => {
    setPasswordSuccessModal(!passwordSuccessModal);
    navigate(appRoute.DASHBOARD);
  };

  // Hooks
  useEffect(() => {
    if (isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isLoading]);

  return (
    <div className="container">
      {/* {isProfile && <h4 dangerouslySetInnerHTML={{ __html: t('ek_change-pw_h') }} />} */}
      {isForceReset && (
        <h4
          className="text-center"
          dangerouslySetInnerHTML={{ __html: t('ek_force_change-pw_h') }}
        />
      )}

      <Formik
        initialValues={changePasswordInitialValue}
        // validationSchema={changePasswordValidation}
        validate={(v) => validatePasswordWithKeys(v, 'newPassword', 'confirmPassword')}
        onSubmit={(v) => {
          console.log(v);
          isForceReset ? onForceChangePasswordSubmit(v) : onChangePasswordSubmit(v);
        }}>
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <div className="login-form pt-0">
            <Form onSubmit={handleSubmit}>
              <div className="row">
                <div className="force-password-change">
                  <Text
                    label={t('ek_change-pw_h1')}
                    labelCustomClass={appTextFieldLabelClass.V1}
                    type="password"
                    id="oldPassword"
                    name="oldPassword"
                    onChange={handleChange}
                    value={values.oldPassword}
                    placeholder={t('ek_change-pw_h1')}
                    // isValid={touched.oldPassword && !errors.oldPassword}
                    // isInvalid={touched.oldPassword && !!errors.oldPassword}
                    onBlur={handleBlur}
                    error={touched.oldPassword && errors.oldPassword ? errors.oldPassword : null}
                  />
                  <Text
                    label={t('ek_change-pw_h2')}
                    labelCustomClass={appTextFieldLabelClass.V1}
                    type="password"
                    id="newPassword"
                    name="newPassword"
                    onChange={handleChange}
                    value={values.newPassword}
                    placeholder={t('ek_change-pw_h2')}
                    // isValid={touched.newPassword && !errors.newPassword}
                    isValid={touched.newPassword && !errors?.newPassword}
                    isInvalid={
                      (touched.newPassword && !!errors?.newPassword) || changePasswordErrorMsg
                    }
                    onBlur={handleBlur}
                    // error={touched.newPassword && errors.newPassword ? errors.newPassword : null}
                  />
                  <ol className="chage-password-err-list mb-0 mt-2 ps-0">
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.minMax
                          ? 'text-danger'
                          : values.newPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.minMax ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.newPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require1')}
                    </li>
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.uppercase
                          ? 'text-danger'
                          : values.newPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.uppercase ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.newPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require2')}
                    </li>
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.number
                          ? 'text-danger'
                          : values.newPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.number ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.newPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require3')}
                    </li>
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.specific
                          ? 'text-danger'
                          : values.newPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.specific ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.newPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require4')}
                    </li>
                    {/* changePasswordErrorMsg */}
                    {changePasswordErrorMsg && (
                      <li className="d-flex align-items-start text-danger">
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                        {changePasswordErrorMsg || t('ek_password_error_invalid-char')}
                      </li>
                    )}
                  </ol>
                  <Text
                    label={t('ek_change-pw_h3')}
                    labelCustomClass={appTextFieldLabelClass.V1}
                    type="password"
                    id="confirmPassword"
                    name="confirmPassword"
                    onChange={handleChange}
                    value={values.confirmPassword}
                    placeholder={t('ek_change-pw_h3')}
                    // isValid={touched.confirmPassword && !errors?.otherErrors?.match && newPassword === confirmPassword}
                    // isInvalid={touched.confirmPassword && errors?.otherErrors?.match}
                    isValid={touched.confirmPassword && !errors.confirmPassword}
                    isInvalid={
                      ((touched.confirmPassword || touched.newPassword) &&
                        (!!errors.confirmPassword || !!errors?.otherErrors?.match)) ||
                      changePasswordErrorMsg
                    }
                    onBlur={handleBlur}
                    // validMsg={
                    //   touched.confirmPassword && !errors.confirmPassword
                    //     ? t('ek_change-pw_success')
                    //     : null
                    // }
                    // help={!touched.confirmPassword && t('ek_change-pw_success')}
                    // error={
                    //   touched.confirmPassword && errors?.otherErrors?.match
                    //     ? t('ek_change-pw_success')
                    //     : null
                    // }
                  />
                  <ol className="chage-password-err-list mb-0 mt-1 ps-0">
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.match
                          ? 'text-danger'
                          : values.confirmPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.match ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.confirmPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_change-pw_success')}
                    </li>
                  </ol>
                </div>
                {/* {changePasswordErrorMsg && (
                  <div className="d-block invalid-feedback">
                    <span>
                      <img className="error-icon" src={errorIcon} alt="error_icon" />
                      {changePasswordErrorMsg}
                    </span>
                  </div>
                )} */}
                <div className="mt-5">
                  {isProfile ? (
                    <div className="d-flex justify-content-between">
                      <Button
                        buttonClass={appButtonType.LINK}
                        onClick={() => {
                          navigate(-1);
                        }}>
                        {t('ek_change-back_button')}
                      </Button>
                      <Button
                        buttonClass={appButtonType.PRIMARY}
                        type="submit"
                        isLoading={isLoading}>
                        {t('ek_change-pw_save')}
                      </Button>
                    </div>
                  ) : (
                    <Button buttonClass={appButtonType.PRIMARY} type="submit" isLoading={isLoading}>
                      {t('ek_change-pw_save')}
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        )}
      </Formik>
{/* 
      <FullScreenModal isOpen={passwordSuccessModal} fullscreen>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <center>
            <h4>{t('ek_change-pw_success-msg')}</h4>
            <br />
            <Button onClick={onClickDashboard}>{t('ek_back_overview')}</Button>
          </center>
        </div>
      </FullScreenModal> */}
    </div>
  );
}

ChangePassword.propTypes = {
  isProfile: PropTypes.bool,
  isForceReset: PropTypes.bool
};

ChangePassword.defaultProps = {
  isProfile: false,
  isForceReset: false
};

export default ChangePassword;
