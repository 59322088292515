import React from 'react';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount } from '@context/MobileOne';
import { Text, Button } from '@core/Inputs';
import { appButtonType, appTextInputClass } from '@utils/globalConstant';
import ArrowLeft from '@theme/assets/img/arrow_left.svg';
import errorIcon from '@theme/assets/img/error.svg';

import './style.scss';

export function ClientLogin({ isLogin, setIsLogin, onPressForgetPassword }) {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();
  const { loginInitialValue, loginFormValidations, onLoginSubmit, loginError, setLoginError } =
    useAccount();

  // Functions
  const onBackPress = () => {
    setIsLogin(!isLogin);
  };

  // Hooks

  return (
    <div>
      <div>
        <div className="login-top-title">
          <img
            aria-hidden
            className="arrow-left"
            src={ArrowLeft}
            alt="Leftarrow"
            onClick={onBackPress}
          />
          <h4
            className="text-center d-inline-block"
            dangerouslySetInnerHTML={{ __html: t('ek_login_h1') }}
          />
        </div>
        <Formik
          initialValues={loginInitialValue}
          validationSchema={loginFormValidations}
          onSubmit={onLoginSubmit}
        >
          {({
            values: { username, password },
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <div className="login-form">
              <Form onSubmit={handleSubmit}>
                <Text
                  type="text"
                  inputCustomClass={appTextInputClass.V2}
                  label={t('ek_login_nr_title')}
                  id="username"
                  name="username"
                  onChange={(e) => {
                    setLoginError(false);
                    handleChange({
                      target: {
                        id: 'username',
                        name: 'username',
                        value: e.target.value
                      }
                    });
                  }}
                  value={username}
                  placeholder={t('ek_login_nr_title')}
                  // isValid={touched.username && !errors.username}
                  isInvalid={!!touched.username && !!errors.username}
                  onBlur={handleBlur}
                  error={touched.username && errors.username ? errors.username : null}
                />

                <Text
                  type="password"
                  iconDivClass="mt-4"
                  inputCustomClass={appTextInputClass.V2}
                  label={t('ek_login_password_title')}
                  id="password"
                  name="password"
                  onChange={(e) => {
                    setLoginError(false);
                    handleChange({
                      target: {
                        id: 'password',
                        name: 'password',
                        value: e.target.value
                      }
                    });
                  }}
                  value={password}
                  placeholder={t('ek_login_password_input')}
                  // isValid={touched.password && !errors.password}
                  isInvalid={!!touched.password && !!errors.password}
                  onBlur={handleBlur}
                  error={touched.password && errors.password ? errors.password : null}
                />

                {loginError && (
                  <div className="d-block invalid-feedback text-center">
                    <img className="error-icon" src={errorIcon} alt="error_icon" />
                    {t('ek_web_login_error-msg')}
                  </div>
                )}

                <div className="d-flex justify-content-center text-center mt-5 pt-5 mb-4">
                  <Button buttonClass={appButtonType.PRIMARY} type="submit">
                    {t('ek_login_button')}
                  </Button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </div>
      <center>
        <Button type="button" buttonClass={appButtonType.LINK} onClick={onPressForgetPassword}>
          {t('ek_login_forgot-password')}
        </Button>
      </center>
    </div>
  );
}

export default ClientLogin;
