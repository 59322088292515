import React, { useEffect } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer, useDocument } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import CheckBlueIcon from '@theme/assets/img/check-blue.svg';
import './style.scss';

export function InsideBonus() {
  // Constants
  const { advantagesEmployeeImgBg, advantagesEmployeeContentImg } = useDocument();
  // States

  // Contexts
  const { t } = useStaticContent();
  const {
    personalData: { flags = {} },
    isLoading: isCustomerLoading
  } = useCustomer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div
      className="advanatges-bg"
      style={{
        backgroundImage: `url(${
          advantagesEmployeeImgBg?.responsive_image
            ? advantagesEmployeeImgBg.responsive_image['3x']
            : ''
        })`
      }}
    >
      <div className="container">
        <MetaTitle
          title={t('ek_employee_meta_title')}
          description={t('ek_employee_meta_description')}
          keywords={t('ek_employee_meta_keywords')}
        />

        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.ADVANTAGES}>
                {t('ek_public_account_advantages')}
              </BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_advantages_no5_breadcrumb') }} />
            <br />
          </div>
        </div>

        <div className="row">
          <div className="card inside-bonus-main mt-5">
            <div className="inside-bonus-center">
              <div className="inside-bonus-content-img">
                <img
                  src={advantagesEmployeeContentImg?.media_url_display}
                  alt="employee-content-img"
                />
              </div>
              <div className="col-lg-12">
                <br />
                {flags?.emaployee ? (
                  <center>
                    <img src={CheckBlueIcon} alt="successIcon" />

                    <div
                      className="copytext pt-3 pb-2"
                      dangerouslySetInnerHTML={{ __html: t('ek_advantages_no5_text0') }}
                    />

                    <hr />
                  </center>
                ) : (
                  <></>
                )}
                <div className="col-lg-12">
                  <div
                    className="text-center copytext pt-2"
                    dangerouslySetInnerHTML={{ __html: t('ek_advantages_no5_text1') }}
                  />
                  <br />
                  <div
                    className="text-center copytext pb-4"
                    dangerouslySetInnerHTML={{ __html: t('ek_advantages_no5_text1.1') }}
                  />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InsideBonus;
