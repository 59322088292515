/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import IosStore from '@theme/assets/img/ios-app.svg';
import AppStore from '@theme/assets/img/android-app.svg';
import './style.scss';

export function Footer() {
  // States

  // Contexts
  const { t } = useStaticContent();

  // Functions

  // Hooks

  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-9" dangerouslySetInnerHTML={{ __html: t('ek_web_footer_text') }} />
          {/* <p className="copytext mb-0">
              Das ist die Browsererweiterung zum EDEKA smart Kundenportal. <br />
              <a className="text-white" href="/"> 
                Laden Sie jetzt die App runter
              </a>
              um vollumfänglich auf Ihre Daten zuzugreifen
            </p>
          </div> */}
          <div className="col-md-3">
            <ul className="nav d-flex">
              <li className="nav-item pt-2 mt-1 me-2">
                <a href={t('ek_link_appstore')} target="_blank" rel="noreferrer">
                  <img src={IosStore} alt="iosstore" />
                </a>
              </li>
              <li className="nav-item pt-2 mt-1">
                <a href={t('ek_link_playstore')} target="_blank" rel="noreferrer">
                  <img src={AppStore} alt="appstore" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
