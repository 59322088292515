import React from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import { Button } from '@core/Inputs';
import { appButtonType } from '@utils/globalConstant';
import LogOutIcon from '@theme/assets/img/logout_white.svg';

import './style.scss';

export function LogoutAlert({ onLogoutPress, isLogoutClicked, setIsLogoutClicked }) {
  // States

  // Contexts
  const { t } = useStaticContent();

  // Functions

  return (
    <Modal
      isOpen={isLogoutClicked}
      contentClass="logout-modal"
      onClose={() => {
        setIsLogoutClicked(false);
      }}
      isCloseButton
    >
      <div className="logout-alert">
        <div
          className="copytext-semibold text-white mb-5"
          dangerouslySetInnerHTML={{
            __html: t('ek_logout_popup_text')
          }}
        />

        <div className="d-flex justify-content-center text-center mt-5">
          <img className="logout-img" src={LogOutIcon} alt="logout" />
        </div>

        <div className="d-flex justify-content-center text-center mt-5">
          <Button
            buttonClass={appButtonType.SECONDARY}
            type="button"
            onClick={() => {
              setIsLogoutClicked(false);
              onLogoutPress();
            }}
          >
            {t('ek_logout_popup_button')}
          </Button>
        </div>
        <div className="d-flex justify-content-center text-center my-4">
          <Button
            buttonClass={appButtonType.LINK}
            onClick={() => {
              setIsLogoutClicked(false);
            }}
          >
            {t('ek_logout_popup_link')}
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default LogoutAlert;
