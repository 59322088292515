import React, { useEffect } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer, useOption, useTariff } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { TariffCatalogue } from '@part/Tariff';
import { appRoute } from '@utils/globalConstant';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';

import './style.scss';

export function Tariff() {
  // Constants

  // States

  // Contexts
  const { isLoading: isCustomerLoading, tariffChangeInProgress } = useCustomer();
  const { isLoading: isOptionLoading } = useOption();
  const { isLoading, showTariffBanner, changeTariffName } = useTariff();
  const { t } = useStaticContent();
  const { hideLoader, showLoader } = useLoader();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();

  // Functions

  // Hooks
  useEffect(() => {
    if (
      isCustomerLoading ||
      isOptionLoading ||
      isLoading ||
      isDashboardLoading ||
      isStaticContentLoading ||
      isAutoTopUpLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
    // Clean up
    return () => {
      hideLoader();
    };
  }, [
    isCustomerLoading,
    isOptionLoading,
    isLoading,
    isDashboardLoading,
    isStaticContentLoading,
    isAutoTopUpLoading
  ]);

  return (
    <>
      {(tariffChangeInProgress || showTariffBanner) && (
        <div
          className="alert alert-warning text-center"
          role="alert"
          dangerouslySetInnerHTML={{
            __html: t('ek_tarif_success-msg_text1').replace('{tariff}', changeTariffName)
          }}
        />
      )}
      <div className="container">
        <MetaTitle
          title={t('ek_tariff_meta_title')}
          description={t('ek_tariff_meta_description')}
          keywords={t('ek_tariff_meta_keywords')}
        />
        <br />
        <Breadcrumbs>
          <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
        </Breadcrumbs>
        <h4 dangerouslySetInnerHTML={{ __html: t('ek_tarif_breadcrumb2') }} />
      </div>
      <div
        className="tariff-banner text-center mt-3 d-none"
        dangerouslySetInnerHTML={{
          __html: t('ek_tariffs_campaign_bannertext')
        }}
      />
      <div className="container">
        <div className="mb-1 mt-4">
          <div className="d-flex justify-content-center">
            <p className="mb-1" dangerouslySetInnerHTML={{ __html: t('ek_tarif_overview_h4') }} />
          </div>
          <div className="d-flex justify-content-center">
            <p
              className="copytext-semibold"
              dangerouslySetInnerHTML={{ __html: t('ek_tarif_overview_h3') }}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center">
          <TariffCatalogue />
        </div>
      </div>
    </>
  );
}

export default Tariff;

export * from './Change';
export * from './EsimTariff';
