import React from 'react';
import PropTypes from 'prop-types';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import { appButtonType } from '@utils/globalConstant';
import EDEKAsmartLogo from '@theme/assets/img/EDEKAsmart_logo_neg.svg';
import './style.scss';

export function ActivationWelcome({ isActivationClicked, setIsActivationClicked }) {
  // States

  // Contexts
  const { t } = useStaticContent();
  const { loginBgImage } = useCustomer();

  // Hooks

  return (
    <section className="welcome-screen">
      <div className="dark-gradient h-100 w-100 vh-100">
        <div
          className="welcome-bg"
          style={{
            backgroundImage: `url(${
              loginBgImage.responsive_image ? loginBgImage?.responsive_image['1x'] : ''
            })`
          }}
        >
          <div className="container">
            <div className="logo">
              <img src={EDEKAsmartLogo} alt="logo" />
            </div>
            <div className="d-flex align-items-center activation-middle justify-content-center flex-column">
              <div className="welcome-body">
                <h5
                  className="text-center"
                  dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_h1') }}
                />
                <p
                  className="pt-5 pb-3 d-block text-center"
                  dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_subheader1') }}
                />
              </div>

              <div className="welcome-steps text-center">
                <div className="row">
                  <div className="col-md-3">
                    {
                      <div className="d-block">
                        {<span>{t('1')}.</span> /* <span>{t('activation-one')}.</span> */}
                      </div>
                    }

                    <p
                      className="pt-3 pb-2 mb-5"
                      dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_text1') }}
                    />
                  </div>
                  <div className="col-md-3">
                    {
                      <div className="d-block">
                        {<span>{t('2')}.</span> /* <span>{t('activation-two')}.</span> */}
                      </div>
                    }

                    <p
                      className="pt-3 pb-2 mb-5"
                      dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_text2') }}
                    />
                  </div>
                  <div className="col-md-3">
                    {
                      <div className="d-block">
                        {<span>{t('3')}.</span> /* <span>{t('activation-three')}.</span> */}
                      </div>
                    }

                    <p
                      className="pt-3 pb-2 mb-5"
                      dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_text3') }}
                    />
                  </div>
                  <div className="col-md-3">
                    {
                      <div className="d-block">
                        {<span>{t('4')}.</span> /* <span>{t('activation-three')}.</span> */}
                      </div>
                    }

                    <p
                      className="pt-3 pb-2 mb-5"
                      dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_text4') }}
                    />
                  </div>
                </div>
              </div>

              <Button
                buttonClass={appButtonType.SECONDARY}
                onClick={() => setIsActivationClicked(!isActivationClicked)}
              >
                {t('ek_ap_activation_button')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
ActivationWelcome.propTypes = {
  isActivationClicked: PropTypes.bool.isRequired,
  setIsActivationClicked: PropTypes.func.isRequired
};
export default ActivationWelcome;
