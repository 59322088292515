import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount } from '@context/MobileOne';
import { Button, Link } from '@core/Inputs';
import { appButtonType, appRoute, headerStringRoute } from '@utils/globalConstant';
import LogOutIcon from '@theme/assets/img/logout.svg';
import './index.scss';

export function Header() {
  // Constants

  // States
  const location = useLocation();
  const [activePath, setActivePath] = useState('');

  // Contexts
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const { setIsLogoutClicked } = useAccount();

  // Functions
  const onLogoutClick = () => {
    setIsLogoutClicked(true);
  };

  // Hooks
  useEffect(() => {
    if (location) {
      const path = location.pathname.split('/');
      setActivePath(path[1]);
    }
  }, [location]);

  return (
    <div className="navbar d-block">
      <div className="container">
        <div className="w-100">
          <div className="d-flex justify-content-between align-items-center header_inner">
            {isUserLoggedIn ? (
              <>
                <ul className="nav">
                  <li className={`${activePath === headerStringRoute.DASHBOARD ? 'active' : ''}`}>
                    <Link className="nav-link" href={appRoute.DASHBOARD} to={appRoute.DASHBOARD}>
                      <span>{t('ek_header_dashboard')}</span>
                    </Link>
                  </li>
                  <li className={`${activePath === headerStringRoute.TARIFF ? 'active' : ''}`}>
                    <Link
                      className="nav-link"
                      href={appRoute.TARIFF_OPTION}
                      to={appRoute.TARIFF_OPTION}
                    >
                      <span>{t('ek_header_tariffs')}</span>
                    </Link>
                  </li>

                  <li className={`${activePath === headerStringRoute.CHARGES ? 'active' : ''}`}>
                    <Link className="nav-link" href={appRoute.CHARGES} to={appRoute.CHARGES}>
                      <span>{t('ek_ap_topup_header')}</span>
                    </Link>
                  </li>
                  <li
                    className={`${
                      activePath === headerStringRoute.HELP_AND_SERVICE ? 'active' : ''
                    }`}
                  >
                    <Link
                      className="nav-link"
                      href={appRoute.HELP_AND_SERVICE}
                      to={appRoute.HELP_AND_SERVICE}
                    >
                      <span>{t('ek_header_service-help')}</span>
                    </Link>
                  </li>
                  <li className={`${activePath === headerStringRoute.ACCOUNT ? 'active' : ''}`}>
                    <Link
                      className="nav-link"
                      href={appRoute.USER_ACCOUNT}
                      to={appRoute.USER_ACCOUNT}
                    >
                      <span>{t('ek_account_header')}</span>
                    </Link>
                  </li>
                </ul>
                <ul className="nav logout">
                  <li>
                    <Button
                      buttonClass={appButtonType.LINK}
                      className="nav-link pe-0 logout nav-link"
                      onClick={onLogoutClick}
                    >
                      Logout{' '}
                      <i>
                        <img src={LogOutIcon} alt="appstore" />
                      </i>
                    </Button>
                  </li>
                </ul>
              </>
            ) : (
              <>
                <Link href={appRoute.ACTIVATION} to={appRoute.ACTIVATION}>
                  Activation
                </Link>
                <Link href={appRoute.LOGIN} to={appRoute.LOGIN}>
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
