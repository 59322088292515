import React from 'react';
import { ChargeHistoryProvider } from './ChargeHistory';
import { OnlineTopUpProvider } from './OnlineTopUp';
import { VoucherProvider } from './Voucher';

export function AlphaCommProvider({ children, config, env }) {
  return (
    <>
      {/* OnlineTopUp Context Provider */}
      <OnlineTopUpProvider {...{ config: { ...config, env } }}>
        {/* Charge History Context Provider */}
        <ChargeHistoryProvider {...{ config: { ...config, env } }}>
          {/* Voucher Provider */}
          <VoucherProvider {...{ config: { ...config, env } }}>{children}</VoucherProvider>
        </ChargeHistoryProvider>
      </OnlineTopUpProvider>
    </>
  );
}

export default AlphaCommProvider;
