/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer, useTariff } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useLayout, useLoader } from '@context/Utils';
import { Button, Switch } from '@core/Inputs';
import { TariffProduct } from '@part/Tariff';
import { appButtonType, appRoute } from '@utils/globalConstant';
import { FullScreenModal, Modal } from '@core/Utils';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import warning from '@theme/assets/img/warning.svg';
import TariffSucessIcon from '@theme/assets/img/tarifwechsel.svg';
import TariffFailIcon from '@theme/assets/img/fail.svg';
import PDF from '@theme/assets/img/pdf_download.svg';
import './style.scss';

export function ChangeTariff() {
  // States
  const [changeTariff, setChangeTariff] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [modalAlert, setModalAlert] = useState(false);
  const [tariffFailModal, setTariffFailModal] = useState(false);
  const [openDoc, setOpenDoc] = useState(false);

  // Contexts
  const { t } = useStaticContent();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const { tariffId } = useParams();
  const {
    isLoading,
    bookableTariff,
    termsValidations,
    onSubmit,
    tariffSuccessModal,
    setTariffSuccessModal,
    changeTariffName,
    getVviDocuments,
    vviDocuments
  } = useTariff();
  const {
    isLoading: isCustomerLoading,
    customerBalance: { totalBalance = 0 },
    productInfoPdfs
  } = useCustomer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  const getTariffPDF = () => {
    let url = '';
    const tariffPdfId = changeTariff
      ? changeTariff.additionalInfo
        ? changeTariff.additionalInfo.document
          ? changeTariff.additionalInfo.document.productInformation
            ? changeTariff.additionalInfo.document.productInformation.id
              ? changeTariff.additionalInfo.document.productInformation.id
              : 0
            : 0
          : 0
        : 0
      : 0;
    const infoPdf = productInfoPdfs
      ? productInfoPdfs.find(({ id }) => Number(id) === tariffPdfId)
      : '';
    if (infoPdf) {
      url = infoPdf.media_url;
    }
    return url;
  };

  // Hooks
  useEffect(() => {
    if (tariffId && bookableTariff.length > 0) {
      const findTariff = bookableTariff.find(
        ({ id }) => parseInt(id, 10) === parseInt(tariffId, 10)
      );

      if (findTariff) {
        const { id } = findTariff;
        getVviDocuments(id);
        setChangeTariff(findTariff);
      }
    }
  }, [bookableTariff, tariffId]);

  useEffect(() => {
    if (
      isLoading ||
      isCustomerLoading ||
      isAutoTopUpLoading ||
      isDashboardLoading ||
      isStaticContentLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
    // Clean up
    return () => {
      hideLoader();
    };
  }, [
    isCustomerLoading,
    isLoading,
    isAutoTopUpLoading,
    isDashboardLoading,
    isStaticContentLoading
  ]);

  useEffect(() => {
    if (vviDocuments) {
      if (openDoc) {
        document.querySelector('#docA').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.vzf}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'vzf.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
        document.querySelector('#docB').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.agb}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'agb.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
        document.querySelector('#docC').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.preisliste}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'preisliste.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
        document.querySelector('#docD').addEventListener('click', () => {
          const linkSource = `data:application/pdf;base64,${vviDocuments.leistungsbeschreibung}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = 'leistungsbeschreibung.pdf';
          downloadLink.click();
          downloadLink.remove();
        });
      }
      () => {
        document.querySelector('#docA').removeEventListener('click');
        document.querySelector('#docB').removeEventListener('click');
        document.querySelector('#docC').removeEventListener('click');
        document.querySelector('#docD').removeEventListener('click');
      };
    }
  }, [openDoc, vviDocuments]);

  return (
    <div className="container">
      <div>
        <br />

        <Breadcrumbs className="mb-2">
          <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
          <BreadcrumbItem route={appRoute.TARIFF_OPTION}>{t('ek_bc_tarif_change')}</BreadcrumbItem>
        </Breadcrumbs>
        {changeTariff && <h4>{changeTariff.name}</h4>}
        <br />

        <Formik
          initialValues={{
            terms: false
          }}
          enableReinitialize
          validationSchema={termsValidations}
          onSubmit={() => {
            onSubmit({ tariffId });
            setModalAlert(false);
          }}
        >
          {({ values, handleChange, errors, touched, handleSubmit }) => (
            <Form className="pt-4 mt-2" onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6">
                  {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                  <TariffProduct {...{ ...changeTariff }} customClass="tariff-detail" />

                  <a href={getTariffPDF()} target="_blank" rel="noreferrer">
                    <u dangerouslySetInnerHTML={{ __html: t('ek_tarif_production-info') }} />
                  </a>
                </div>
                <div className="col-lg-6">
                  <div className="">
                    <div className="pt-5">
                      <Switch
                        className="py-4"
                        label={t('ek_tarif-change_text1')}
                        id="terms"
                        name="terms"
                        value
                        onChange={handleChange}
                        isInvalid={!!touched.terms && !!errors.terms}
                        errors={errors.terms}
                      />
                    </div>
                    <div className="ms-5 ps-2 mb-2">
                      <u className="copytext mb-2" type="button" onClick={() => setOpenDoc(true)}>
                        {t('ek_ap_overview_text3')}
                      </u>
                      {openDoc && (
                        <ul className="list-unstyled copytext mt-2">
                          <li id="docA" className="my-2" type="button">
                            <i>
                              <img src={PDF} alt="pdf-icon" />
                            </i>
                            VZF
                          </li>
                          <li id="docB" className="my-2" type="button">
                            <i>
                              <img src={PDF} alt="pdf-icon" />
                            </i>
                            AGB
                          </li>
                          <li id="docC" className="my-2" type="button">
                            <i>
                              <img src={PDF} alt="pdf-icon" />
                            </i>
                            PREISLISTE
                          </li>
                          <li id="docD" className="my-2" type="button">
                            <i>
                              <img src={PDF} alt="pdf-icon" />
                            </i>
                            LEISTUNGSBESCHREIBUNG
                          </li>
                        </ul>
                      )}
                    </div>
                    {/* <div className="py-3 w-100 d-inline-flex ms-5">
                            <Button
                              type="button"
                              buttonClass={appButtonType.SUCCESS}
                              role="tab"
                              disabled={!values.terms}
                              onClick={() => {
                                navigate(appRoute.ESIM_TARIFF);
                              }}>
                              {t('ek_tarif-change_order_esim_button')}
                            </Button>
                          </div> */}
                    <div className="py-3 w-100 d-inline-flex ms-5">
                      <Button
                        buttonClass={appButtonType.PRIMARY}
                        disabled={!values.terms}
                        type="button"
                        onClick={() => {
                          setModalAlert(true);
                        }}
                      >
                        {t('ek_tarif-change__button')}
                      </Button>
                    </div>
                  </div>
                  <Modal
                    title={t('ek_web_tarif-change_banner_h1')}
                    titleClass="tariff-alert-heading mx-auto"
                    isOpen={modalAlert}
                    onClose={() => {
                      setModalAlert(false);
                    }}
                    isCloseButton
                  >
                    <div>
                      <div className="warning-icon pt-2">
                        <img src={warning} alt="warning" />
                      </div>
                      <p dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_banner') }} />
                      <div className="d-flex justify-content-center text-center">
                        <Button
                          buttonClass={appButtonType.SECONDARY}
                          type="submit"
                          onClick={() => {
                            setModalAlert(false);
                            if (changeTariff.price <= totalBalance) {
                              onSubmit({ tariffId });
                            } else {
                              setTariffFailModal(true);
                            }
                          }}
                        >
                          {t('ek_web_tarif-change_banner_btn')}
                        </Button>
                      </div>
                    </div>
                  </Modal>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <br />
      <br />
      <FullScreenModal isOpen={tariffSuccessModal} fullscreen>
        <div className="tariff-sucess mx-auto d-flex justify-content-center flex-column align-items-center vh-100">
          <div className="tariff-sucess-icon mb-4 pb-3">
            <img src={TariffSucessIcon} alt="tariff-sucess-icon" />
          </div>
          {/* <i dangerouslySetInnerHTML={{ __html: t('ek_tarif_success-msg_icon') }} /> */}
          <h2 dangerouslySetInnerHTML={{ __html: t('ek_tarif_success-msg_h1') }} />

          <p
            className="copytext"
            dangerouslySetInnerHTML={{
              __html: t('ek_tarif_success-msg_text1').replace('{tariff}', changeTariffName)
            }}
          />

          <div className="d-flex justify-content-center text-center mt-3">
            <Button
              buttonClass={appButtonType.PRIMARY}
              type="button"
              onClick={() => {
                setTariffSuccessModal(false);
                navigate(appRoute.DASHBOARD);
              }}
            >
              {t('ek_tarif_back-overview')}
            </Button>
          </div>
        </div>
      </FullScreenModal>
      <FullScreenModal isOpen={tariffFailModal} fullscreen>
        <div className="tariff-fail mx-auto d-flex justify-content-center flex-column align-items-center vh-100">
          <div className="tariff-fail-icon mb-4 pb-3">
            <img src={TariffFailIcon} alt="tariff-fail-icon" />
          </div>

          <h2 dangerouslySetInnerHTML={{ __html: t('ek_tarif_error-msg_h1') }} />

          {/* <i dangerouslySetInnerHTML={{ __html: t('ek_tarif_error-msg_icon') }} /> */}

          <div className="d-flex justify-content-center text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: t('ek_tarif_error-msg_text1')
                  .replace('\\tariff_price_here', `${changeTariff?.price}&euro;`)
                  .replace('\\tariff_name_here', changeTariff.name)
              }}
            />
          </div>
          <div className="d-flex justify-content-center text-center">
            <p
              dangerouslySetInnerHTML={{
                __html: t('ek_tarif_error-msg_text2').replace(
                  '\\credit_costumer',
                  `<strong>${totalBalance}&euro;</strong>`
                )
              }}
            />
          </div>
          <div className="d-flex justify-content-center text-center">
            <Button
              buttonClass={appButtonType.PRIMARY}
              type="button"
              onClick={() => {
                setTariffFailModal(false);
                navigate(appRoute.CHARGES);
              }}
            >
              {t('ek_tarif_top-up-button')}
            </Button>
          </div>

          <div className="d-flex justify-content-center text-center mt-4">
            <Button
              buttonClass={appButtonType.LINK}
              type="button"
              onClick={() => {
                setTariffFailModal(false);
              }}
            >
              {t('ek_tarif_later-button')}
            </Button>
          </div>
        </div>
      </FullScreenModal>
    </div>
  );
}

export default ChangeTariff;
