import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import moment from 'moment';

import {
  simLockStatus,
  simLockStatusTitle,
  StatusCodes
} from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import {
  appAlert,
  appRegex,
  appRoute,
  appStorage,
  formValidation,
  generateUUID,
  inputValidation,
  validateDOB,
  validateDOBde,
  validateLowerCase,
  validateNumber,
  validateSpecial,
  validateStringLength,
  validateUpperCase
} from '@utils/globalConstant';
import { useAlert } from '@context/Utils';
import { deleteAllCookies } from '@utils/cookies';
import { AppDownloadAlert } from '@part/AppDownloadAlert';
import { LogoutAlert } from '@part/LogoutAlert';
import { useCustomer } from '../Customer';

export const AccountContext = createContext({});

export function AccountContextProvider({ children, config: { storage } }) {
  // Constants

  // Login initial value
  const loginInitialValue = {
    username: '',
    password: ''
  };

  const changePasswordInitialValue = {
    newPassword: '',
    confirmPassword: ''
  };

  const forgotPasswordInitialValue = {
    number: '',
    // puk: ''
    birthDate: ''
  };

  const cancelContractOptionalInitValue = {
    asap: '',
    tillDate: '',
    validUntil: '',
    extraOrdinary: 'NO',
    terminationReason: ''
  };

  // API Data Storage States
  const [isLoading, setIsLoading] = useState(false);
  const [forgotPassSuccess, setForgotPassSuccess] = useState(false);
  const [email, setEmail] = useState('');
  // const [domWithProduct, setDomWithProduct] = useState(null);
  const [lockStatus, setLockStatus] = useState();
  const [loginForm, setLoginFrom] = useState();
  const [changePasswordForm, setChangePasswordForm] = useState(changePasswordInitialValue);
  const [forgotPasswordForm, setForgotPasswordForm] = useState(forgotPasswordInitialValue);
  const [welcomePopup, setWelcomePopup] = useState('');
  const [contactSuccessModal, setContactSuccessModal] = useState(false);
  const [passwordSuccessModal, setPasswordSuccessModal] = useState(false);
  const [hotlineSuccessModal, setHotlineSuccessModal] = useState(false);
  const [cancelContractForm, setCancelContractForm] = useState({});
  const [cancelContractRes, setCancelContractRes] = useState({ downloadLink: false });
  const [changePasswordErrorMsg, setChangePasswordErrorMsg] = useState('');
  const [forcePasswordSuccessModal, setForcePasswordSuccessModal] = useState(false);
  const [emailUniqueId, setEmailUniqueId] = useState('');
  const [emailModal, setEmailModal] = useState(false);
  const [emailCodeError, setEmailCodeError] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [preferenceSuccessModal, setPreferenceSuccessModal] = useState(false);
  const [preferenceAlert, setPreferenceAlert] = useState(false);
  const [isLoginSubmit, setIsLoginSubmit] = useState(false);
  const [isLogoutClicked, setIsLogoutClicked] = useState(false);

  // Context
  const navigate = useNavigate();
  const { showAlert } = useAlert();
  const {
    onChangeCustomerDataCall,
    onChangePasswordCall,
    onSimLock,
    onUnSimLock,
    onCustomerFogotPasswordCall,
    onTwoFactorAuthPinCall,
    onTwoFactorAuthVerificationCall,
    onChangeOptinFlagsCall,
    domWithProductData
  } = useMobileOne();
  const {
    customerData,
    personalData: { alternatePhoneNumber = {} },
    personalData,
    customerProducts,
    getCustomerData,
    onDOMWithProduct
  } = useCustomer();
  const { onPasswordLogin, setUserLogin, onLogout, isUserLoggedIn } = useAuth();
  const { t, onContractTerminationCall } = useStaticContent();

  const hotlinePasswordInitialValue = {
    pswrdForHtLine: customerData ? customerData?.hotlinePassword : ''
  };

  const contactInitialValue = {
    email: personalData?.email?.emailAddress,
    isEmailDiff: 'NO',
    confirmEmail: '',
    emailAddress: '',
    emailCode: '',
    alternateNumberPrefix: alternatePhoneNumber?.prefix || '',
    alternateNumber: alternatePhoneNumber?.number || ''
  };

  const preferencesInitValue = {
    brandPartnerCustomMarketing:
      domWithProductData?.personalData?.flags?.brandPartnerCustomMarketing || false,
    marketingMultibrand: domWithProductData?.personalData?.flags?.marketingMultibrand || false,
    thirdParty: domWithProductData?.personalData?.flags?.thirdParty || false
  };

  const [changeEmailForm, setChangeEmailForm] = useState(contactInitialValue);
  const [hotlinePasswordForm, setHotlinePasswordForm] = useState(hotlinePasswordInitialValue);

  // VALIDATION

  const cancelContractValidation = yup.object().shape({
    extraOrdinary: formValidation({
      required: t('please_select_option')
    }),
    terminationReason: formValidation({}).when('extraOrdinary', {
      is: (value) => value === 'YES',
      then: formValidation({
        required: t('please_enter_reason')
        // validErrorMessage: t('please_enter_reason')
      })
    })
    // validUntil: yup.date()
    //   .required(t('ek_ap_personal_validation-date_error-msg'))
    //   .min(new Date(), t('ek_ap_personal_validation-date_error-msg'))
  });

  const customChangePersonalDataSchema = yup.object().shape({
    email: formValidation({
      required: t('ek_change-personal_h1_error-msg'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('ek_change-personal_h1_error-msg')
    }),
    confirmEmail: formValidation({})
      .when('email', {
        is: (value) => value,
        then: yup.string().required(`${t('ek_change-personal_h2_erro-msg')}`)
      })
      .oneOf([yup.ref('email'), null], t('ek_change-personal_h2_erro-msg')),
    alternateNumberPrefix: formValidation({
      regex: /^.{1,6}$/,
      validErrorMessage: t('ek_change-personal_h3_error-msg')
    }),
    alternateNumber: formValidation({
      regex: /^[0-9]{4,15}$/,
      validErrorMessage: t('ek_change-personal_h4_error-msg')
    })
  });

  const validateEmail = yup.object().shape({
    emailAddress: formValidation({
      required: t('ek_change-personal_h1_error-msg'),
      regex:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      validErrorMessage: t('ek_change-personal_h1_error-msg')
    })
  });

  const hotlinePasswordValidation = yup.object().shape({
    pswrdForHtLine: formValidation({
      required: t('ek_ap_personal_holine-pw_input1_error-msg'),
      regex: /^[(A-Z)|(Ö)|(Ä)|(Ü)|(a-z)|(0-9)|(ü)|(ö)|(ä)|(ß)]{4,20}$/,
      validErrorMessage: t('ek_ap_personal_holine-pw_input1_error-msg')
    }),
    cnfPswrdForHtLine: yup
      .string()
      .required(`${t('ek_ap_personal_holine-pw_input2_error-msg')}`)
      .oneOf([yup.ref('pswrdForHtLine')], t('ek_ap_personal_holine-pw_input2_error-msg'))
  });

  const changePasswordValidation = yup.object().shape({
    oldPassword: formValidation({
      required: t('ek_change-pw_h1_error-msg')
      // regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{12,20}$/,
      // validErrorMessage: 'please_valid_old_password'
    }),
    newPassword: formValidation({
      required: t('ek_ap_password_pw1_error-msg'),
      regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{12,20}$/,
      validErrorMessage: t('ek_ap_password_pw1_error-msg')
    }),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref('newPassword')], t('ek_ap_password_pw2_error-msg'))
      .required(t('ek_ap_password_pw2_error-msg'))
    // formValidation({
    //   required: 'please_enter_confirm_password',
    //   regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{12,20}$/,
    //   validErrorMessage: 'please_valid_confirm_password'
    // })
  });

  // Old Password Validation
  // const validatePasswordWithKeys = (values, firstPasswordKey, confirmPasswordKey) => {
  //   // string, controlName, password

  //   const errors = Object.keys(values).map((controlName) => {
  //     const string = values[controlName];
  //     if (!string) {
  //       return {
  //         [controlName]: t('ek_ap_password_pw1_error-msg')
  //       };
  //     }

  //     const hasLength = validateStringLength(values[firstPasswordKey], 12, 20);
  //     const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
  //     const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
  //     const hasNumber = validateNumber(values[firstPasswordKey]);
  //     const hasSpecial = validateSpecial(values[firstPasswordKey]);

  //     if (!hasLength || !hasUpperCase || !hasLowerCase || !hasNumber || !hasSpecial) {
  //       return {
  //         otherErrors: {
  //           [inputValidation.MIN_MAX_ERROR]: hasLength,
  //           [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
  //           [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
  //           [inputValidation.NUMBER_ERROR]: hasNumber,
  //           [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
  //           [inputValidation.MATCH]: values[firstPasswordKey] === values[confirmPasswordKey]
  //         }
  //       };
  //     }

  //     if (
  //       (values[firstPasswordKey] || values[confirmPasswordKey]) &&
  //       values[firstPasswordKey] !== values[confirmPasswordKey]
  //     ) {
  //       return { [confirmPasswordKey]: t('ek_ap_password_pw1_error-msg') };
  //     }

  //     return {};
  //   });
  //   return Object.assign(...errors);
  // };

  // New Password Validations

  const validatePwd = (password) => {
    const pattern =
      t('ek_password_regex') !== 'ek_password_regex'
        ? new RegExp(`${t('ek_password_regex')}`)
        : appRegex.validatePwd;
    return pattern.test(password);
  };

  const validatePwdStringLength = (password) => {
    const pattern =
      t('ek_password_string_length_regex') !== 'ek_password_string_length_regex'
        ? new RegExp(`${t('ek_password_string_length_regex')}`)
        : appRegex.validatePwdStringLength;
    return pattern.test(password);
  };
  const validatePwdUpperOrLowerCase = (password) => {
    const pattern =
      t('ek_password_upper_or_lower_case_regex') !== 'ek_password_upper_or_lower_case_regex'
        ? new RegExp(`${t('ek_password_upper_or_lower_case_regex')}`)
        : appRegex.validatePwdUpperOrLowerCase;
    return pattern.test(password);
  };
  const validatePwdNumbers = (password) => {
    const pattern =
      t('ek_password_numbers_regex') !== 'ek_password_numbers_regex'
        ? new RegExp(`${t('ek_password_numbers_regex')}`)
        : appRegex.validatePwdNumbers;
    return pattern.test(password);
  };
  const validatePwdSpecialChar = (password) => {
    const pattern =
      t('ek_password_special_chars_regex') !== 'ek_password_special_chars_regex'
        ? new RegExp(`${t('ek_password_special_chars_regex')}`)
        : appRegex.validatePwdSpecialChar;
    return pattern.test(password);
  };

  // Password Validation
  const validatePasswordWithKeys = (values, firstPasswordKey, confirmPasswordKey) => {
    // string, controlName, password
    setChangePasswordErrorMsg('');
    if (!values[firstPasswordKey] && !values[confirmPasswordKey]) return {};

    const errors = Object.keys(values).map((controlName) => {
      const string = values[controlName];
      if (!string) {
        return {
          [controlName]: t('ek_acc_data_chpwd_require5') // t('ek_ap_password_pw1_error-msg')
        };
      }

      // const hasLength = validateStringLength(values[firstPasswordKey], 12, 20);
      // const hasUpperCase = validateUpperCase(values[firstPasswordKey]);
      // const hasLowerCase = validateLowerCase(values[firstPasswordKey]);
      // const hasNumber = validateNumber(values[firstPasswordKey]);
      // const hasSpecial = validateSpecial(values[firstPasswordKey]);
      // const hasVerified = !validatePwd(values[firstPasswordKey]);
      const hasLength = !validatePwdStringLength(values[firstPasswordKey]);
      const hasUpperCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasLowerCase = !validatePwdUpperOrLowerCase(values[firstPasswordKey]);
      const hasNumber = !validatePwdNumbers(values[firstPasswordKey]);
      const hasSpecial = !validatePwdSpecialChar(values[firstPasswordKey]);

      let errorMsg = {};

      if (
        // hasVerified ||
        hasLength ||
        hasUpperCase ||
        hasLowerCase ||
        hasNumber ||
        hasSpecial ||
        values[firstPasswordKey] !== values[confirmPasswordKey]
      ) {
        errorMsg = {
          // [firstPasswordKey]: t('ek_change-pw_success'),
          otherErrors: {
            // [inputValidation.INVALID]: hasVerified,
            [inputValidation.MIN_MAX_ERROR]: hasLength,
            [inputValidation.UPPER_CASE_ERROR]: hasUpperCase,
            [inputValidation.LOWER_CASE_ERROR]: hasLowerCase,
            [inputValidation.NUMBER_ERROR]: hasNumber,
            [inputValidation.SPECIAL_CASE_ERROR]: hasSpecial,
            [inputValidation.MATCH]: values[firstPasswordKey] !== values[confirmPasswordKey]
          }
        };
      }

      if (values[firstPasswordKey] && values[firstPasswordKey] !== values[confirmPasswordKey]) {
        errorMsg = {
          ...errorMsg,
          [confirmPasswordKey]: t('ek_change-pw_success')
        };
      }

      if (
        // hasVerified ||
        hasLength ||
        hasUpperCase ||
        hasLowerCase ||
        hasNumber ||
        hasSpecial
      ) {
        errorMsg = {
          ...errorMsg,
          [firstPasswordKey]: t('ek_change-pw_success')
        };
      }
      return errorMsg;
    });
    return Object.assign(...errors);
  };

  const forgotPasswordValidation = yup.object().shape({
    number: formValidation({
      required: t('ek_forgot-pw_number_error-msg'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('ek_forgot-pw_number_error-msg')
    }),
    birthDate: yup  
      .date()
      .transform((value, originalValue) => {
        // Parse the date using Moment.js
        const parsedDate = moment(originalValue, 'DD.MM.YYYY', true); // strict parsing
        // If the date is valid and the day and month match the original values, return the date object
        // Otherwise, return an `InvalidDate`
        if (parsedDate.isValid()) {
          const [day, month, year] = originalValue.split('.');
          if (
            parsedDate.date() === parseInt(day, 10) &&
            parsedDate.month() + 1 === parseInt(month, 10)
          ) {
             return parsedDate.toDate();
          }
        }
        return new Date(NaN); // Invalid date
      })
      .max(validateDOBde(), t('ek_ap_personal_birth-date_input_error-msg'))
      .required(t('ek_ap_personal_birth-date_input_error-msg2'))
      .typeError(t('ek_ap_personal_birth-date_input_error-msg3'))

    // puk: formValidation({
    //   required: t('ek_forgot-pw_puk_error-msg'),
    //   regex: /^\d{4,8}$/,
    //   validErrorMessage: t('ek_forgot-pw_puk_error-msg')
    // })
  });

  const customPasswordSchema = (values) => {
    const errors = {};
    if (!values.oldPassword && !values.newPassword && !values.confirmPassword) {
      errors.oldPassword = 'please_enter_old_password';
      errors.newPassword = 'please_enter_new_password';
      errors.confirmPassword = 'please_enter_confirm_password';
    } else if (values.oldPassword === values.newPassword) {
      errors.newPassword = 'old_password_and_new_password_must_not_be_same';
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = 'Confirm_Password_new_password_must_be_same';
    }
    return errors;
  };

  // Phone number and password validation
  const loginFormValidations = yup.object().shape({
    username: formValidation({
      required: t('ek_login_nr_error-msg'),
      regex: /^\d{5,20}$/,
      validErrorMessage: t('ek_login_nr_error-msg')
    }),
    password: formValidation({
      required: t('ek_login_password_error-msg')
      // regex: /^(?=.*[0-9])(?=.*[!"$%&#§ÄÜÖßäöü])[a-zA-Z0-9|!"$%&#§ÄÜÖßäöü]{8,16}$/,
      // validErrorMessage: t('ek_login_password_error-msg')
    })
  });

  const validationSchemaCode = yup.object().shape({
    emailCode: yup
      .number()
      .typeError(t('ek_ap_email_err_wrong_code'))
      .required(t('ek_ap_email_err_no_code'))
      .test('code', t('ek_ap_email_err_wrong_code'), (hp) => /^\d{4}$/.test(hp))
  });

  // Functions
  // Personal Data -----------------------------
  // eslint-disable-next-line no-unused-vars
  const loadPersonalData = () => {
    try {
      setIsLoading(true);
      const { email: { emailAddress = '' } = {} } = personalData;
      if (emailAddress) setEmail(emailAddress);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Customer Products -------------------------
  // eslint-disable-next-line no-unused-vars
  const loadCustomerProducts = () => {
    try {
      setIsLoading(true);

      const findFirstProduct = customerProducts.find((a) => a.status && a.status.name);
      const {
        status: { name: simStatus, name = false }
      } = findFirstProduct;
      if (name) {
        if (simStatus === simLockStatus.ACTIVE) {
          setLockStatus(simLockStatus.ACTIVE);
        } else if (simStatus === simLockStatus.SIM_LOCK_INPROGRESS) {
          setLockStatus(simLockStatus.SIM_LOCK_INPROGRESS);
        } else if (simStatus === simLockStatus.SIM_LOCKED) {
          setLockStatus(simLockStatus.SIM_LOCKED);
        } else if (simStatus === simLockStatus.SIM_UNLOCK_REQUESTED) {
          setLockStatus(simLockStatus.SIM_UNLOCK_REQUESTED);
        } else if (simStatus === simLockStatus.ACTIVATION_INPROGRESS) {
          setLockStatus(simLockStatus.ACTIVATION_INPROGRESS);
        } else {
          setLockStatus(simStatus);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // change EMAIl--------------------
  const onChangeEmailSubmit = async (values) => {
    try {
      setIsLoading(true);

      // console.log('values--------', values);
      // const { prefix, altNumber } = values;
      const userEmail = personalData?.email?.emailAddress;
      // eslint-disable-next-line prefer-const
      let inputparams = {
        alternatePhoneNumber: {
          number: values.alternateNumber,
          prefix: values.alternateNumberPrefix
        },
        emailAddress: values.email
      };

      if (!(inputparams.alternatePhoneNumber.number || inputparams.alternatePhoneNumber.prefix))
        delete inputparams.alternatePhoneNumber;

      if (userEmail === values.email) {
        const { data, success } = await onChangeCustomerDataCall(inputparams);
        if (success) {
          await getCustomerData();
          setContactSuccessModal(!contactSuccessModal);

          setIsLoading(false);
          return data;
        }
      } else {
        const uniqueIdEmail = generateUUID();
        setEmailUniqueId(uniqueIdEmail);
        const { data, success } = await onTwoFactorAuthPinCall({
          email: values.email,
          uniqueId: uniqueIdEmail
        });
        if (data && success) {
          setIsLoading(false);
          setEmailModal(true);
          setTimeout(() => {
            setEmailModal(false);
          }, 4000);
          setChangeEmailForm({
            ...changeEmailForm,
            emailAddress: values.email,
            isEmailDiff: 'YES'
          });
        }
      }
      return true;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return false;
    }
  };

  const verifyEmail = (values) => {
    try {
      setIsLoading(true);

      // console.log('values-----', values);
      setChangeEmailForm({ ...changeEmailForm, emailAddress: values.emailAddress });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);

      console.log(error);
    }
  };

  const verifyEmailCode = async (values) => {
    try {
      const { emailCode } = values;
      setIsLoading(true);

      const { data, success } = await onTwoFactorAuthVerificationCall({
        pin: Number(emailCode),
        uniqueId: emailUniqueId
      });

      // eslint-disable-next-line prefer-const
      let inputparams = {
        alternatePhoneNumber: {
          number: changeEmailForm.alternateNumber,
          prefix: changeEmailForm.alternateNumberPrefix
        },
        emailAddress: changeEmailForm.emailAddress
      };

      if (!(inputparams.alternatePhoneNumber.number || inputparams.alternatePhoneNumber.prefix))
        delete inputparams.alternatePhoneNumber;

      if (data && success) {
        const response = await onChangeCustomerDataCall(inputparams);
        if (response.success) {
          await getCustomerData();
          setContactSuccessModal(!contactSuccessModal);
          setIsLoading(false);

          return response.data;
        }
      }
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);
      setEmailCodeError(true);

      console.log(error);
      return false;
    }
  };

  // Resend otp code
  const onResendCode = async () => {
    try {
      setIsLoading(true);

      const resendUniqueId = generateUUID();
      setEmailUniqueId(resendUniqueId);
      const { data, success } = await onTwoFactorAuthPinCall({
        email: changeEmailForm.emailAddress,
        uniqueId: resendUniqueId
      });
      if (data && success) {
        setIsLoading(false);

        setEmailModal(true);
        setTimeout(() => {
          setEmailModal(false);
        }, 4000);
      }
      return true;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      return false;
    }
  };

  // change hotline password--------------------
  const onHotlinePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);

      const { data, success } = await onChangeCustomerDataCall({
        hotlinePassword: values.pswrdForHtLine
      });
      if (success) {
        await getCustomerData();
        setHotlineSuccessModal(!hotlineSuccessModal);
        setIsLoading(false);
      } else {
        setIsLoading(false);

        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return false;
    }
  };

  // change password--------------------
  const onChangePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);

      if (!validatePwd(values.newPassword)) {
        setChangePasswordErrorMsg(t('ek_password_error_invalid-char'));
        setIsLoading(false);
        return false;
      }

      const { data, success } = await onChangePasswordCall({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      });
      if (success) {
        const { access_token: accessToken, refresh_token: refreshToken } = data;
        await storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, refreshToken);

        await getCustomerData();
        setIsLoading(false);

        setPasswordSuccessModal(!passwordSuccessModal);
      } else {
        setIsLoading(false);

        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        // showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      // setChangePasswordErrorMsg(t(error?.error[0]?.messageBody));
      setChangePasswordErrorMsg(t('ek_generic_err_txt'));
      return false;
    }
  };

  const onForceChangePasswordSubmit = async (values) => {
    try {
      setIsLoading(true);

      if (!validatePwd(values.newPassword)) {
        setChangePasswordErrorMsg(t('ek_password_error_invalid-char'));
        setIsLoading(false);
        return false;
      }

      const { data, success } = await onChangePasswordCall({
        oldPassword: values.oldPassword,
        newPassword: values.newPassword
      });
      if (success) {
        const { access_token: accessToken, refresh_token: refreshToken } = data;
        await storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, refreshToken);

        setIsLoading(false);

        setForcePasswordSuccessModal(!forcePasswordSuccessModal);
      } else {
        setIsLoading(false);

        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      // if (
      //   !(
      //     error?.status === StatusCodes.UNAUTHORIZED ||
      //     error?.status === StatusCodes.FORBIDDEN ||
      //     error?.response?.status === StatusCodes.UNAUTHORIZED ||
      //     error?.response?.status === StatusCodes.FORBIDDEN
      //   )
      // ) {
      //   showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      // }
      // setChangePasswordErrorMsg(t(error?.error[0]?.messageBody));
      setChangePasswordErrorMsg(t('ek_password_error_invalid-char'));
      return false;
    }
  };

  // Login grant type password-------------------
  const onLoginSubmit = async (values) => {
    try {
      setIsLoading(true);

      const { data, success } = await onPasswordLogin({
        username: values.username,
        password: values.password
      });
      if (success) {
        setTimeout(() => {
          setUserLogin(true);
          setWelcomePopup('welcome_popup');
        }, 100);
        setIsLoginSubmit(true);
      } else {
        setIsLoading(false);

        showAlert({
          type: appAlert.ERROR,
          message: data?.error[0]?.messageBody
        });
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
      return data;
    } catch (error) {
      setIsLoading(false);
      console.log(error);
      // eslint-disable-next-line max-len
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.status === StatusCodes.BAD_REQUEST ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.BAD_REQUEST
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      } else {
        setLoginError(true);
      }
      return false;
    }
  };

  const onLogoutPress = async () => {
    const authToken = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
    const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
    try {
      setIsLoading(true);

      const response = await onLogout(refreshToken, authToken);
      if (response.status === 200) {
        // console.log("success response--------", response);
        setUserLogin(false);
        localStorage.clear();
        sessionStorage.clear();

        setIsLoading(false);
        window.location.href = '/login';
      }
    } catch (error) {
      setIsLoading(false);

      console.log('Logout error', error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      localStorage.clear();
      sessionStorage.clear();
      deleteAllCookies();
      window.location.href = '/login';
    }
  };

  // Forgot password
  const onForgotPasswordSubmit = async (values) => {
    try {
      setIsLoading(true);
      const updatedDate = moment(values.birthDate, 'DD.MM.YYYY').format('DD.MM.YYYY');

      const { data, success } = await onCustomerFogotPasswordCall({
        birthdate: updatedDate,
        username: values.number
      });
      if (success) {
        setIsLoading(false);

        setForgotPassSuccess(true);
      }

      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return false;
    }
  };

  const onChangePreferencesSubmit = async (values) => {
    try {
      // console.log('values------------', values);
      setIsLoading(true);

      const { data, success } = await onChangeOptinFlagsCall(values);
      if (data && success) {
        setPreferenceSuccessModal(true);
        const currentTime = new Date().getTime();
        const flagShownTime = currentTime + 300000;
        localStorage.setItem(appStorage.PREFERENCE_SHOW_TIME, flagShownTime);
        localStorage.setItem(appStorage.MSISDN, customerData.msisdn);
        setIsLoading(false);
      }
      await onDOMWithProduct();
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return false;
    }
  };

  // Lock  SIM--------------------
  const lockSimCard = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onSimLock();
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return false;
    }
  };

  // unlock SIM--------------------
  const unLockSimCard = async () => {
    try {
      setIsLoading(true);
      const { data, success } = await onUnSimLock();
      if (success) {
        showAlert({ type: appAlert.SUCCESS, message: 'Hotline password updated' });
      } else {
        showAlert({ type: appAlert.ERROR, message: `Error in request status.` });
      }
      await getCustomerData();
      setIsLoading(false);

      return data;
    } catch (error) {
      setIsLoading(false);

      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return false;
    }
  };

  // handle LockTitleStatus
  const handleLockStatus = () => {
    switch (lockStatus) {
      case simLockStatus.ACTIVE:
        return simLockStatusTitle.ACTIVE;
      case simLockStatus.SIM_LOCK_INPROGRESS:
        return simLockStatusTitle.SIM_LOCK_INPROGRESS;
      case simLockStatus.SIM_LOCKED:
        return simLockStatusTitle.SIM_LOCKED;
      case simLockStatus.SIM_UNLOCK_REQUESTED:
        return simLockStatusTitle.SIM_UNLOCK_REQUESTED;
      default:
        return simLockStatusTitle.DEFAULT;
    }
  };

  // handle LockTitleStatus
  const onSimLockSubmit = () => {
    if (lockStatus !== simLockStatus.ACTIVATION_INPROGRESS) {
      if (handleLockStatus(lockStatus) === simLockStatusTitle.ACTIVE) {
        lockSimCard();
      } else if (handleLockStatus(lockStatus) === simLockStatusTitle.SIM_LOCKED) {
        unLockSimCard();
      }
    }
  };

  const onCancelTerminationCall = async (values) => {
    try {
      setIsLoading(true);
      // eslint-disable-next-line no-unneeded-ternary
      const ordinary = values.extraOrdinary === 'NO' ? true : false;
      // eslint-disable-next-line no-unneeded-ternary
      const extraOrdinary = values.extraOrdinary === 'YES' ? true : false;
      // eslint-disable-next-line no-unneeded-ternary
      const possibleDate = values.validUntil === '' ? false : true;

      const inputParams = JSON.stringify({
        client: 'edeka',
        'first name': cancelContractForm.userFirstName,
        'last name': cancelContractForm.userLastName,
        street: cancelContractForm.cancelStreet,
        'street number': cancelContractForm.cancelHouseNumber,
        zipcode: cancelContractForm.cancelZip,
        city: cancelContractForm.cancelCity,
        'email address': cancelContractForm.email,
        'customer ID': cancelContractForm.userCustomerNumber,
        'mobile phone number': cancelContractForm.telefonNummer,
        'date of termination': values.validUntil,
        'terminate on next possible': possibleDate,
        'ordinary termination': ordinary,
        'extraordinary termination': extraOrdinary,
        'reason for extraordinary termination': values.terminationReason
      });
      // console.log('input--------', inputParams);
      const response = await onContractTerminationCall(inputParams);
      console.log('terminationAfterCalldata------------', response);
      if (response.status === 200) {
        setCancelContractRes({ downloadLink: response.data.url });
      }
      setIsLoading(false);

      return response;
      // return true;
    } catch (error) {
      setIsLoading(false);
      console.log('error======', error);
      showAlert({
        type: appAlert.ERROR,
        message: `Error in request. ${t(error?.response?.data?.message)}`
      });
      return false;
    }
  };

  const downloadPDF = () => {
    window.location.href = cancelContractRes.downloadLink;
    setCancelContractRes({ downloadLink: false });
    setCancelContractForm({});
  };

  const onLoad = async () => {
    try {
      setIsLoading(true);
      await getCustomerData();
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  // Hooks
  // useEffect(()=>{
  //   const { firstName, lastName, ...restPersonalData } = personalData;
  //   if (firstName || email) {
  //     loadPersonalData();
  //   }
  // }, [personalData]);
  // useEffect(()=>{
  //   if (customerProducts && customerProducts.length>0) {
  //     loadCustomerProducts();
  //   }
  // }, [customerProducts]);
  // useEffect(() => {
  //   // setWelcomePopup('welcome_popup');
  //   setTimeout(() => {
  //     navigate(appRoute.DASHBOARD);
  //   }, 1500);
  //   return () => {
  //     setWelcomePopup('');
  //   };
  // }, [customerData.msisdn]);

  useEffect(() => {
    if (customerData.msisdn) {
      if (customerData.forcePasswordReset) {
        setTimeout(() => {
          setIsLoading(false);

          navigate(appRoute.FORCE_PASSWORD_CHANGE);
        }, 1000);
      } else if (isLoginSubmit) {
        setIsLoginSubmit(false);
        setTimeout(() => {
          navigate(appRoute.DASHBOARD);
          setIsLoading(false);
        }, 1500);
      }
    }
  }, [customerData.msisdn]);

  useEffect(() => {
    if (domWithProductData) {
      if (domWithProductData.customerData) {
        const flagShownTime = parseInt(localStorage.getItem(appStorage.PREFERENCE_SHOW_TIME), 10);
        const msisdn = localStorage.getItem(appStorage.MSISDN);
        const currentTime = new Date().getTime();
        setPreferenceAlert(
          currentTime < flagShownTime && domWithProductData.customerData.msisdn === msisdn
        );
        if (currentTime > flagShownTime && domWithProductData.customerData.msisdn === msisdn) {
          onLoad();
          localStorage.removeItem(appStorage.PREFERENCE_SHOW_TIME);
          localStorage.removeItem(appStorage.MSISDN);
        }
      }
    }
  }, [domWithProductData]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      email,
      setEmail,
      lockStatus,
      setLockStatus,
      loginInitialValue,
      loginForm,
      setLoginFrom,
      loginFormValidations,
      forgotPasswordInitialValue,
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordValidation,
      onForgotPasswordSubmit,
      forgotPassSuccess,
      setForgotPassSuccess,
      welcomePopup,
      setWelcomePopup,
      cancelContractForm,
      setCancelContractForm,
      cancelContractRes,
      contactSuccessModal,
      setContactSuccessModal,
      passwordSuccessModal,
      setPasswordSuccessModal,
      hotlineSuccessModal,
      setHotlineSuccessModal,
      changePasswordErrorMsg,
      forcePasswordSuccessModal,
      setForcePasswordSuccessModal,
      emailModal,
      setEmailModal,
      emailCodeError,
      setEmailCodeError,
      loginError,
      setLoginError,
      preferenceSuccessModal,
      setPreferenceSuccessModal,
      preferenceAlert,
      setPreferenceAlert,
      isLogoutClicked,
      setIsLogoutClicked,
      isLoginSubmit,
      setIsLoginSubmit,

      // API calls
      onChangeEmailSubmit,
      onHotlinePasswordSubmit,
      onChangePasswordSubmit,
      lockSimCard,
      unLockSimCard,
      onLoginSubmit,
      onForceChangePasswordSubmit,

      // Form Initial States & Validations
      contactInitialValue,
      customChangePersonalDataSchema,
      changeEmailForm,
      setChangeEmailForm,
      hotlinePasswordInitialValue,
      hotlinePasswordValidation,
      hotlinePasswordForm,
      setHotlinePasswordForm,
      changePasswordInitialValue,
      changePasswordValidation,
      validatePasswordWithKeys,
      changePasswordForm,
      setChangePasswordForm,
      customPasswordSchema,
      cancelContractValidation,
      cancelContractOptionalInitValue,
      validateEmail,
      verifyEmail,
      verifyEmailCode,
      onResendCode,
      validationSchemaCode,
      preferencesInitValue,

      // functions
      handleLockStatus,
      onSimLockSubmit,
      onLogoutPress,
      onCancelTerminationCall,
      onChangePreferencesSubmit,
      downloadPDF
    }),
    [
      // States
      isLoading,
      setIsLoading,
      email,
      setEmail,
      lockStatus,
      setLockStatus,
      loginInitialValue,
      loginForm,
      setLoginFrom,
      loginFormValidations,
      forgotPasswordInitialValue,
      forgotPasswordForm,
      setForgotPasswordForm,
      forgotPasswordValidation,
      onForgotPasswordSubmit,
      forgotPassSuccess,
      setForgotPassSuccess,
      welcomePopup,
      setWelcomePopup,
      cancelContractForm,
      setCancelContractForm,
      cancelContractRes,
      contactSuccessModal,
      setContactSuccessModal,
      passwordSuccessModal,
      setPasswordSuccessModal,
      hotlineSuccessModal,
      setHotlineSuccessModal,
      changePasswordErrorMsg,
      forcePasswordSuccessModal,
      setForcePasswordSuccessModal,
      emailModal,
      setEmailModal,
      emailCodeError,
      setEmailCodeError,
      loginError,
      setLoginError,
      preferenceSuccessModal,
      setPreferenceSuccessModal,
      preferenceAlert,
      setPreferenceAlert,
      isLogoutClicked,
      setIsLogoutClicked,
      isLoginSubmit,
      setIsLoginSubmit,

      // API calls
      onChangeEmailSubmit,
      onHotlinePasswordSubmit,
      onChangePasswordSubmit,
      lockSimCard,
      unLockSimCard,
      onLoginSubmit,
      onForceChangePasswordSubmit,

      // Form Initial States & Validations
      contactInitialValue,
      customChangePersonalDataSchema,
      changeEmailForm,
      setChangeEmailForm,
      hotlinePasswordInitialValue,
      hotlinePasswordValidation,
      hotlinePasswordForm,
      setHotlinePasswordForm,

      changePasswordInitialValue,
      changePasswordValidation,
      validatePasswordWithKeys,
      changePasswordForm,
      setChangePasswordForm,
      customPasswordSchema,
      cancelContractValidation,
      cancelContractOptionalInitValue,
      validateEmail,
      verifyEmail,
      verifyEmailCode,
      onResendCode,
      validationSchemaCode,
      preferencesInitValue,

      // functions
      handleLockStatus,
      onSimLockSubmit,
      onLogoutPress,
      onCancelTerminationCall,
      onChangePreferencesSubmit,
      downloadPDF
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return (
    <AccountContext.Provider value={contextPayload}>
      <AppDownloadAlert
        isUserLoggedIn={isUserLoggedIn}
        welcomePopup={welcomePopup}
        setWelcomePopup={setWelcomePopup}
        onLogoutPress={onLogoutPress}
      />
      {isUserLoggedIn && isLogoutClicked && (
        <LogoutAlert
          isLogoutClicked={isLogoutClicked}
          setIsLogoutClicked={setIsLogoutClicked}
          onLogoutPress={onLogoutPress}
        />
      )}
      {children}
    </AccountContext.Provider>
  );
}

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useAccount = () => useContext(AccountContext);

export default AccountContext;
