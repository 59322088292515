import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer, useOption } from '@context/MobileOne';
import { Button, Switch } from '@core/Inputs';
// import { PassOfferProduct } from '@part/PassOffer/Product';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { usePassOffer } from '@context/MobileOne/TariffOption/PassOffer';
import { useLayout, useLoader } from '@context/Utils';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { appButtonType, appRoute, displayPrice } from '@utils/globalConstant';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { ActiveTariffSection } from '@page/TariffOption/Option/ActiveTariffSection';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { FullScreenModal } from '@core/Utils';
import success from '@theme/assets/img/success.svg';
import TariffFailIcon from '@theme/assets/img/fail.svg';
import './style.scss';

export function BookPassOffers() {
  // States
  const [bookPassFailure, setBookPassFailure] = useState(false);
  const [passOfferDetails, setPassOfferDetails] = useState({});

  // Contexts
  const {
    customerBalance: { totalBalance = 0 },
    isLoading: isCustomerLoading
  } = useCustomer();
  const { t } = useStaticContent();
  const { termsValidations } = useOption();
  const { passCode } = useParams();
  const { passOffers, isLoading: isDashboardLoading } = useDashBoard();
  const navigate = useNavigate();
  const {
    bookPassOffer,
    bookPassSuccess,
    setBookPassSuccess,
    isLoading: isPassOfferLoading
  } = usePassOffer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();

  // Functions
  const onSubmit = () => {
    if (passOfferDetails.hasLowBalance) {
      setBookPassFailure(!bookPassFailure);
    } else {
      bookPassOffer(passCode);
    }
  };

  const getPrice = (price) => `${price} €`.replace('.', ',');
  const getCreditText = () =>
    t('ek_tarif_error-msg_text2').replace(
      '\\credit_costumer',
      `${displayPrice(totalBalance)} €`.replace('.', ',')
    );
  const getTariffText = () =>
    t('ek_tarif_error-msg_text3')
      .replace('\\tariff_name_here', passOfferDetails?.name)
      .replace('\\option_price_here', `${passOfferDetails?.price} €`.replace('.', ','));

  const onSuccessModalClick = () => {
    setBookPassSuccess(!bookPassSuccess);
    navigate(appRoute.DASHBOARD);
  };

  const onFailureModalClick = () => {
    setBookPassFailure(!bookPassFailure);
    navigate(appRoute.SET_AUTO_TOPUP);
  };

  const onFailureModalBack = () => {
    setBookPassFailure(!bookPassFailure);
    navigate(appRoute.BOOKABLE_DATA);
  };

  // Hooks
  useEffect(() => {
    if (passCode && passOffers.length > 0) {
      const findPassOffers = passOffers.find(({ passCode: id }) => id === passCode);
      if (findPassOffers) {
        setPassOfferDetails(findPassOffers);
      }
    }
  }, [passOffers, passCode]);

  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isPassOfferLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isPassOfferLoading
  ]);

  return (
    <>
      <div className="container">
        <MetaTitle
          title={t('ek_book_data_meta_title')}
          description={t('ek_book_data_meta_description')}
          keywords={t('ek_book_data_meta_keywords')}
        />
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.BOOKABLE_DATA}>{t('ek_bc_book_data')}</BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_book_data_options_h') }} />
          </div>
        </div>
        <div className="pt-4 mt-2">
          <div className="row">
            <div className="col-lg-4">
              <ActiveTariffSection />
            </div>
            <div className="offset-md-1 col-lg-7">
              <div className="card mt-4">
                <div className="card-body">
                  <p
                    className="copytext"
                    dangerouslySetInnerHTML={{ __html: t('ek_tarif_options_order_h2') }}
                  />
                  <p className="copytext-medium">{passOfferDetails?.name}</p>

                  <div className="offer-price mb-2">
                    <h6 className="d-inline-block"> {getPrice(passOfferDetails?.price)} </h6>
                    {' / '}
                    <span> {passOfferDetails?.duration?.valueFormatted} </span>
                  </div>
                  <Formik
                    initialValues={{
                      terms: false
                    }}
                    enableReinitialize
                    validationSchema={termsValidations}
                    onSubmit={onSubmit}
                  >
                    {({ values: { terms }, handleChange, errors, handleSubmit }) => (
                      <Form onSubmit={handleSubmit}>
                        <Switch
                          className="py-4"
                          label={t('ek_tarif_options_order_text1')}
                          id="terms"
                          name="terms"
                          value={terms}
                          onChange={() => {
                            handleChange({
                              target: {
                                id: 'terms',
                                name: 'terms',
                                value: !terms
                              }
                            });
                          }}
                          errors={errors.terms}
                        />
                        <div className="d-flex justify-content-center align-items-center">
                          <Button
                            buttonClass={appButtonType.PRIMARY}
                            type="submit"
                            disabled={!terms}
                          >
                            {t('ek_tarif_options_order_button')}
                          </Button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={bookPassSuccess} fullscreen>
        <div className="book-pass-success mx-auto d-flex justify-content-center flex-column align-items-center vh-100">
          <div className="success-icon mb-4 pb-2">
            {' '}
            <img src={success} alt="success-icon" />{' '}
          </div>
          <div className="align-text-center">
            <h2
              className="text-center"
              dangerouslySetInnerHTML={{ __html: t('ek_tarif_success-msg_h2') }}
            />
            <p
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: t('ek_tarif_success-msg_text2').replace(
                  '\\booked_option',
                  passOfferDetails?.name
                )
              }}
            />

            <Button buttonClass={appButtonType.PRIMARY} type="button" onClick={onSuccessModalClick}>
              {t('ek_tarif_back-overview')}
            </Button>
          </div>
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={bookPassFailure} fullscreen>
        <div className="book-pass-fail mx-auto d-flex justify-content-center flex-column align-items-center vh-100">
          <div className="tariff-fail-icon mb-4 pb-3">
            <img src={TariffFailIcon} alt="tariff-fail-icon" />
          </div>

          <h2
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_tarif_error-msg_h1') }}
          />

          <p className="text-center pb-0" dangerouslySetInnerHTML={{ __html: getTariffText() }} />

          <p className="text-center" dangerouslySetInnerHTML={{ __html: getCreditText() }} />

          <Button buttonClass={appButtonType.PRIMARY} type="button" onClick={onFailureModalClick}>
            {t('ek_tarif_top-up-button')}
          </Button>

          <Button buttonClass={appButtonType.LINK} type="button" onClick={onFailureModalBack}>
            {t('ek_tarif_later-button2')}
          </Button>
        </div>
      </FullScreenModal>
    </>
  );
}

export default BookPassOffers;
