import React from 'react';
import { Formik, Form } from 'formik';

import { Button } from '@core/Inputs';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appButtonType } from '@utils/globalConstant';
import { useActivation } from '@context/MobileOne';
import { AddressDetails } from './AddressDetails';
import { Contact } from './Contact';
import { General } from './General';
// import { IdData } from './IdData';
import { HotlinePassword } from './HotLinePassword';

export function PersonalData() {
  // Context
  const {
    isLoading,
    personalDataForm,
    tariffActivationForm,
    phoneNumberActivationForm,
    personalDataValidations,
    personalDataFormSubmit,
    // salutations,
    // identityTypes,
    countries
  } = useActivation();
  const { t } = useStaticContent();

  return (
    <div className="container">
      <div className="card mx-auto">
        <div className="card-body">
          <Formik
            initialValues={{
              ...personalDataForm,
              ...tariffActivationForm,
              ...phoneNumberActivationForm
            }}
            validationSchema={personalDataValidations}
            onSubmit={personalDataFormSubmit}>
            {({
              values: {
                // salutation,
                firstName,
                lastName,
                birthDate,
                houseNumber,
                street,
                zip,
                city,
                countryCode,
                emailAddress,
                // alternateNumberPrefix,
                // alternateNumber,
                // identityType,
                // identityNumber,
                // nationality,
                // placeOfIssue,
                // validUntil,
                hotlinePassword,
                confirmHotlinePassword
              },
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              errors
            }) => (
              <>
                <Form onSubmit={handleSubmit}>
                  <General
                    // salutation={salutation}
                    // salutations={salutations}
                    firstName={firstName}
                    lastName={lastName}
                    birthDate={birthDate}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    errors={errors}
                  />

                  <AddressDetails
                    countries={countries}
                    houseNumber={houseNumber}
                    street={street}
                    zip={zip}
                    city={city}
                    countryCode={countryCode}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    errors={errors}
                  />

                  <Contact
                    emailAddress={emailAddress}
                    // alternateNumberPrefix={alternateNumberPrefix}
                    // alternateNumber={alternateNumber}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    errors={errors}
                  />

                  {/* <IdData
                identityType={identityType}
                identityNumber={identityNumber}
                nationality={nationality}
                placeOfIssue={placeOfIssue}
                validUntil={validUntil}
                handleChange={handleChange}
                handleBlur={handleBlur}
                handleSubmit={handleSubmit}
                touched={touched}
                errors={errors}
                identityTypes={identityTypes}
                countries={countries}
              /> */}
                  <HotlinePassword
                    hotlinePassword={hotlinePassword}
                    confirmHotlinePassword={confirmHotlinePassword}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleSubmit={handleSubmit}
                    touched={touched}
                    errors={errors}
                  />
                  <div className="d-flex justify-content-end mt-5">
                    <Button
                      buttonClass={appButtonType.PRIMARY}
                      isIcon
                      icon={<i className="bi bi-arrow-right" />}
                      type="submit"
                      isLoading={isLoading}>
                      {t('ek_ap_personal_next_button')}
                    </Button>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}

export default PersonalData;
