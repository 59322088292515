import React, { useEffect } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount, useCustomer } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';

import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import PDF from '@theme/assets/img/pdf_download.svg';
import './style.scss';

export function Documents() {
  // Constants

  // States

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { getPdfs, productInfoPdfs, termsInfoPdfs, isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isAccountLoading } = useAccount();

  // Functions

  // Hooks
  useEffect(() => {
    if (staticContentData) {
      getPdfs();
    }
  }, [staticContentData]);

  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_legal_meta_title')}
        description={t('ek_legal_meta_description')}
        keywords={t('ek_legal_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.HELP_AND_SERVICE}>
              {t('ek_bc_help_service')}
            </BreadcrumbItem>
            <BreadcrumbItem route={appRoute.LEGAL}>{t('ek_service_legal')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_documents_header')}</h4>
          <br />
        </div>
      </div>

      <div className="mt-4 pt-2">
        <div className="row">
          <div className="col-lg-6">
            <div className="terms-pdf">
              <h6 className="copytext-medium mb-5">{t('ek_documents_h2')}</h6>
              <ul>
                {termsInfoPdfs &&
                  termsInfoPdfs.map((pdf, index) => (
                    <li key={index}>
                      <i>
                        <img src={PDF} alt="pdf-icon" />
                      </i>
                      <a key={index} href={pdf.media_url} target="_blank" rel="noreferrer">
                        {pdf?.name}
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="terms-pdf">
              <h6 className="copytext-medium mb-5">{t('ek_documents_h3')}</h6>
              <ul>
                {productInfoPdfs &&
                  productInfoPdfs.map((pdf, index) => (
                    <>
                      <li>
                        <i>
                          <img src={PDF} alt="pdf-icon" />
                        </i>
                        <a key={index} href={pdf.media_url} target="_blank" rel="noreferrer">
                          {pdf?.name}
                        </a>
                      </li>
                    </>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Documents;
