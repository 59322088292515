import React, { useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useAccount, useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useLayout, useLoader } from '@context/Utils';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Button, Radio, Text, TextArea } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appButtonType, appRoute, appTextInputClass } from '@utils/globalConstant';
import errorIcon from '@theme/assets/img/error.svg';
import './style.scss';

export function CancelContract() {
  // Constants

  // States
  // const [asapRadio, setAsapRadio] = useState(false);
  // const [dateRadio, setDateRadio] = useState(false);

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  // eslint-disable-next-line max-len
  const {
    personalData: {
      firstName = '',
      lastName = '',
      birthDate = '',
      email,
      address: { city = '', countryName = '', street = '', houseNumber = '', zip = '' } = {}
    },
    customerData: { msisdn = '', customerNumber = '' },
    personalData,
    customerData,
    isLoading: isCustomerLoading
  } = useCustomer();
  const {
    tariffDetails: { name = '' },
    isLoading: isDashboardLoading
  } = useDashBoard();
  const {
    setCancelContractForm,
    isLoading: isAccountLoading,
    cancelContractOptionalInitValue,
    onCancelTerminationCall,
    cancelContractValidation,
    cancelContractRes,
    downloadPDF
  } = useAccount();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();

  // Functions\
  const formatDate = (date) => moment(date).format('DD.MM.YYYY');

  // Hooks
  useEffect(() => {
    if (email && city && firstName) {
      const terminationForm = {
        userCustomerNumber: customerNumber,
        telefonNummer: msisdn,
        userFirstName: firstName,
        userLastName: lastName,
        cancelStreet: street,
        cancelHouseNumber: houseNumber,
        cancelCity: city,
        cancelZip: zip,
        email: email.emailAddress
      };

      setCancelContractForm(terminationForm);
    }
  }, [personalData, customerData]);

  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_cancel_contract_meta_title')}
        description={t('ek_cancel_contract_meta_description')}
        keywords={t('ek_cancel_contract_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.HELP_AND_SERVICE}>
              {t('ek_bc_help_service')}
            </BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_termination_h') }} />
          <br />
        </div>
      </div>
      {!cancelContractRes.downloadLink && (
        <div className="mt-4 pt-2">
          <div className="row">
            <div className="col-lg-5">
              <div className="cancel-contract-left">
                <div className="pb-4 mb-2">
                  <div className="d-flex cancel-contract-left-inner">
                    <p className="copytext-medium mb-2">{t('ek_termination_text1')}</p>
                    <span className="copytext ps-4 ms-2"> {msisdn}</span>
                  </div>

                  <div className="d-flex cancel-contract-left-inner">
                    <p className="copytext-medium mb-2">{t('ek_termination_text2')}</p>
                    <span className="copytext ps-4 ms-2">{customerNumber}</span>
                  </div>
                </div>

                <div className="pb-4 mb-2">
                  <div className="d-flex cancel-contract-left-inner">
                    <p className="copytext-medium mb-2">{t('ek_termination_text3')}</p>
                    <span className="copytext ps-4 ms-2"> {`${firstName} ${lastName}`}</span>
                  </div>

                  <div className="d-flex cancel-contract-left-inner">
                    <p className="copytext-medium mb-2">{t('ek_termination_text4')}</p>
                    <span className="copytext ps-4 ms-2">{formatDate(birthDate)}</span>
                  </div>
                  <div className="d-flex cancel-contract-left-inner">
                    <p className="copytext-medium mb-2">{t('ek_termination_text5')}</p>
                    <span className="copytext ps-4 ms-2">
                      {`${street} ${houseNumber} ${zip} ${city} ${countryName}`}{' '}
                    </span>
                  </div>
                </div>

                <div className="d-flex cancel-contract-left-inner">
                  <p className="copytext-medium mb-2">{t('ek_termination_tariff_text')}</p>
                  <span className="copytext ps-4 ms-2">{name}</span>
                </div>
                <Button
                  buttonClass={appButtonType.LINK}
                  className="copytext-medium"
                  onClick={() => {
                    navigate(appRoute.TARIFF_OPTION);
                  }}
                >
                  {t('ek_tariff-details_change')}
                </Button>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="cancel-contract-right mb-4">
                <div className="card ms-3">
                  <div className="card-body">
                    <Formik
                      initialValues={cancelContractOptionalInitValue}
                      validationSchema={cancelContractValidation}
                      onSubmit={onCancelTerminationCall}
                    >
                      {({
                        // eslint-disable-next-line no-unused-vars
                        values: { asap, validUntil, extraOrdinary, terminationReason },
                        handleBlur,
                        handleChange,
                        handleSubmit,
                        touched,
                        errors
                      }) => (
                        <Form onSubmit={handleSubmit}>
                          <p
                            className="copytext-medium mb-2 pb-2"
                            dangerouslySetInnerHTML={{ __html: t('ek_termination_h1') }}
                          />
                          <div className="pb-2">
                            <Radio
                              label={t('ek_termination_option1')}
                              id="asap"
                              name="asap"
                              value="true"
                              onClick={() => {
                                handleChange({
                                  target: { id: 'validUntil', name: 'validUntil', validUntil: '' }
                                });
                                handleChange({
                                  target: { id: 'asap', name: 'asap', value: 'true' }
                                });
                              }}
                              isDefaultChecked={asap === 'true'}
                            />
                          </div>
                          <div className="row">
                            <div className="col-lg-8">
                              <Radio
                                label={t('ek_termination_option2')}
                                id="asap2"
                                name="asap"
                                value="false"
                                onClick={() =>
                                  handleChange({
                                    target: {
                                      id: 'asap',
                                      name: 'asap',
                                      value: 'false'
                                    }
                                  })
                                }
                                isDefaultChecked={asap === 'false'}
                              />
                            </div>
                            <div className="col-lg-4">
                              {asap === 'false' && (
                                <div>
                                  <Text
                                    type="date"
                                    className="mt-0"
                                    inputCustomClass={appTextInputClass.V2}
                                    name="validUntil"
                                    id="validUntil"
                                    value={validUntil}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              )}
                            </div>
                          </div>

                          <p
                            className="copytext-medium mb-2 mt-4"
                            dangerouslySetInnerHTML={{ __html: t('ek_termination_h2') }}
                          />
                          <p
                            className="copytext-medium"
                            dangerouslySetInnerHTML={{ __html: t('ek_termination_text7') }}
                          />

                          <div className="pb-2">
                            <Radio
                              inline
                              label={t('ek_termination_option3')}
                              id="ordinary"
                              name="extraOrdinary"
                              onChange={handleChange}
                              onClick={() => {
                                handleChange({
                                  target: {
                                    id: 'extraOrdinary',
                                    name: 'extraOrdinary',
                                    value: 'NO'
                                  }
                                });
                                handleChange({
                                  target: {
                                    id: 'terminationReason',
                                    name: 'terminationReason',
                                    value: ''
                                  }
                                });
                              }}
                              value="NO"
                              onBlur={handleBlur}
                              isDefaultChecked={extraOrdinary === 'NO'}
                            />
                          </div>
                          <div className="pb-2">
                            <Radio
                              inline
                              label={t('ek_termination_option4')}
                              id="extraOrdinary"
                              name="extraOrdinary"
                              onChange={handleChange}
                              onClick={() => {
                                handleChange({
                                  target: {
                                    id: 'extraOrdinary',
                                    name: 'extraOrdinary',
                                    value: 'YES'
                                  }
                                });
                                handleChange({
                                  target: {
                                    id: 'terminationReason',
                                    name: 'terminationReason',
                                    value: ''
                                  }
                                });
                              }}
                              value="YES"
                              onBlur={handleBlur}
                              isDefaultChecked={extraOrdinary === 'YES'}
                            />
                          </div>

                          <div className="d-block invalid-feedback">
                            {!!errors.extraOrdinary && (
                              <img className="error-icon" src={errorIcon} alt="error_icon" />
                            )}
                            {errors.extraOrdinary}
                          </div>
                          {extraOrdinary && extraOrdinary === 'YES' && (
                            <>
                              <p
                                className="copytext-medium mt-4"
                                dangerouslySetInnerHTML={{ __html: t('ek_termination_h3') }}
                              />
                              <div className="col-lg-12">
                                <div className="cancel-contract-area">
                                  <TextArea
                                    placeholder="Hiermit möchte ich …"
                                    rows="3"
                                    id="terminationReason"
                                    name="terminationReason"
                                    value={terminationReason}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    isValid={touched.terminationReason && !errors.terminationReason}
                                    isInvalid={!!errors.terminationReason}
                                    error={
                                      touched.terminationReason && errors.terminationReason
                                        ? errors.terminationReason
                                        : null
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          )}

                          <p
                            className="mt-3 contract-text-small"
                            dangerouslySetInnerHTML={{ __html: t('ek_termination_text6') }}
                          />

                          <div className="d-flex justify-content-center mt-4">
                            <Button
                              buttonClass={appButtonType.PRIMARY}
                              type="submit"
                              isLoading={isAccountLoading}
                            >
                              {t('ek_termination_button')}
                            </Button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {cancelContractRes.downloadLink && (
        <div className="mt-4 pt-2">
          <div className="card mx-auto">
            <div className="card-body">
              <div className="col-lg-12">
                <h3
                  className="mb-3"
                  dangerouslySetInnerHTML={{ __html: t('ek_termination_confirm_title') }}
                />

                <p
                  className="copytext"
                  dangerouslySetInnerHTML={{ __html: t('ek_termination_confirm_text') }}
                />

                <p
                  className="copytext"
                  dangerouslySetInnerHTML={{ __html: t('ek_termination_confirm_print-pdf') }}
                />

                <div className="flex justify-center align-center mt-4 pt-2">
                  <Button onClick={downloadPDF}>{t('ek_termination_confirm_btn')}</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CancelContract;
