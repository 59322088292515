import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { FullScreenModal } from '@core/Utils';
import { Button } from '@core/Inputs';
import {
  AutoTopUpFor,
  appTopUpType,
  appButtonType,
  appPaymentOrderStatus,
  appRoute,
  displayPrice
} from '@utils/globalConstant';
import PaymentSucessIcon from '@theme/assets/img/success.svg';

export function SuccessModal({
  isSuccessModalOpen,
  setIsSuccessModalOpen,
  directTopUpAmount,
  topupReason,
  topUpType,
  emptySessionData,
  isStaticContentLoading,
  orderStatus
}) {
  // Constants
  const autoTopTitle = 'ek_generic_autotopup_suc_hdl';
  // Contexts
  const { setCurrentStep, setIsActivationClicked } = useActivation();
  const navigate = useNavigate();
  const { t } = useStaticContent();
  // Functions
  const getSuccessText = () => {
    const isCreationPending = orderStatus === appPaymentOrderStatus.CREATION_PENDING;

    switch (topupReason) {
      case AutoTopUpFor.ACTIVATION:
        return (
          <>
            <h2
              className="text-center my-3"
              dangerouslySetInnerHTML={{ __html: t(autoTopTitle) }}
            />

            <p
              className="copytext my-4 pb-0"
              dangerouslySetInnerHTML={{
                __html: t(
                  isCreationPending
                    ? 'ek_generic_autotopup_suc_pending_title'
                    : 'ek_generic_autotopup_suc_title'
                )
              }}
            />

            <Button
              customClass="mt-3"
              onClick={() => {
                emptySessionData();
                setIsSuccessModalOpen(false);
                setIsActivationClicked(true);
                setCurrentStep(6);
                navigate(appRoute.ACTIVATION);
              }}>
              {t(
                isCreationPending
                  ? 'ek_generic_auto_topup_suc_pending_btn'
                  : 'ek_generic_auto_topup_suc_btn'
              )}
            </Button>
          </>
        );
      case AutoTopUpFor.LOGIN:
        return getLoginSuccessText();
      default:
        return (
          <>
            <p
              className="copytext my-4 pb-0"
              dangerouslySetInnerHTML={{
                __html: t(
                  isCreationPending
                    ? 'ek_generic_autotopup_suc_pending_title'
                    : 'ek_generic_autotopup_suc_title'
                )
              }}
            />

            <Button
              buttonClass={appButtonType.PRIMARY}
              onClick={() => {
                emptySessionData();
                setIsSuccessModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_autotop-up_success-msg_back')}
            </Button>
          </>
        );
    }
  };

  const getLoginSuccessText = () => {
    const isCreationPending = orderStatus === appPaymentOrderStatus.CREATION_PENDING;

    switch (topUpType) {
      case appTopUpType.DIRECT:
        return (
          <>
            <h2
              className="text-center my-3"
              dangerouslySetInnerHTML={{ __html: t('ek_topup_suc_immi_hdl') }}
            />

            <p className="copytext my-4 pb-0">
              {t('ek_topup_suc_immi_txt')
                .replace('{amount}', `${displayPrice(directTopUpAmount)} `)
                .replace('.', ',')}
            </p>

            <Button
              customClass="mt-3"
              onClick={() => {
                emptySessionData();
                setIsSuccessModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_topup_suc_immi_btn')}
            </Button>
          </>
        );
      case appTopUpType.AUTOMATIC:
        return (
          <>
            <h4
              className="my-3"
              dangerouslySetInnerHTML={{ __html: t('ek_generic_autotopup_suc_hdl') }}
            />

            <p
              className="copytext my-4 pb-0"
              dangerouslySetInnerHTML={{
                __html: t(
                  isCreationPending
                    ? 'ek_generic_autotopup_suc_pending_title'
                    : 'ek_generic_autotopup_suc_title'
                )
              }}
            />

            <Button
              customClass="mt-3"
              onClick={() => {
                emptySessionData();
                setIsSuccessModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t(
                isCreationPending
                  ? 'ek_generic_auto_topup_suc_pending_btn'
                  : 'ek_generic_auto_topup_suc_btn'
              )}
            </Button>
          </>
        );
      default:
        return (
          <>
            <h2
              className="text-center my-3"
              dangerouslySetInnerHTML={{ __html: t('ek_generic_autotopup_suc_hdl') }}
            />

            <p
              className="copytext my-4 pb-0"
              dangerouslySetInnerHTML={{
                __html: t(
                  isCreationPending
                    ? 'ek_generic_autotopup_suc_pending_title'
                    : 'ek_generic_autotopup_suc_title'
                )
              }}
            />

            <Button
              onClick={() => {
                emptySessionData();
                setIsSuccessModalOpen(false);
                navigate(appRoute.CHARGES);
              }}>
              {t('ek_autotop-up_success-msg_back')}
            </Button>
          </>
        );
    }
  };

  // useEffect(() => {
  //   if (topupReason === AutoTopUpFor.ACTIVATION && !isStaticContentLoading) {
  // setTimeout(() => {
  //   emptySessionData();
  //   setIsSuccessModalOpen(false);
  //   setIsActivationClicked(true);
  //   setCurrentStep(6);
  //   navigate(appRoute.ACTIVATION);
  // }, 4000);
  //   }
  // }, [isSuccessModalOpen, isStaticContentLoading]);

  return (
    <FullScreenModal isOpen={isSuccessModalOpen && !isStaticContentLoading} fullscreen>
      <div className="modal-wh payment-sucess vh-100 d-flex flex-column align-items-center justify-content-center mx-auto">
        <div className="payment-sucess-icon mb-4">
          <img src={PaymentSucessIcon} alt="paymentsuccessicon" />
        </div>
        {getSuccessText()}
      </div>
    </FullScreenModal>
  );
}

export default SuccessModal;
