/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

import { StatusCodes, appStorage } from '@dom-digital-online-media/dom-app-config-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { generateRandomValue, storageKeys } from '@utils/globalConstant';

export function AxiosManager({ children, config }) {
  // Global refresh promise handler
  let refreshTokenPromise;

  // Context
  const { onTokenExpire, onRegistrationToken, setIsUserLogoutProcessing, isUserLoggedIn } =
    useAuth();
  const { storage } = config;

  // States

  // Functions
  // eslint-disable-next-line consistent-return
  const getTempAccessToken = async () => {
    try {
      const {
        data: { access_token: accessToken },
        success
      } = await onRegistrationToken();
      if (success && accessToken) {
        await config.storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        return { data: { access_token: accessToken } };
      }
    } catch (error) {
      console.log(error);
      alert('Error in config');
      return { data: { access_token: false } };
    }
    // let url = `${env.REACT_APP_SERVER}api/temp-access-token`;

    // let headers = {
    //   'Content-Type': 'application/x-www-form-urlencoded',
    // };
    // return axios({
    //   method: 'GET',
    //   url,
    //   headers,
    // });
  };

  const handleLogout = async () => {
    await storage.encryptedSetItem(appStorage.AUTH_TOKEN, '');
    await storage.encryptedSetItem(appStorage.AUTH_TEMP_TOKEN, '');
    await storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, '');
    await storage.encryptedSetItem(appStorage.USER_AUTH_DATA, '');
    await storage.encryptedSetItem(appStorage.USER_FORBIDDEN, '');
    setIsUserLogoutProcessing(true);
    window.location.href = '/login';
  };

  axios.interceptors.response.use(
    (res) => res,
    async (error) => {
      // console.log('axios.interceptors.response error-----', error);
      const token = await storage.encryptedGetItem(appStorage.AUTH_TOKEN);
      const refreshToken = await storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN);
      if (
        (error?.status === StatusCodes.UNAUTHORIZED || error?.status === StatusCodes.FORBIDDEN) &&
        !token &&
        !refreshToken &&
        isUserLoggedIn
      ) {
        // Check for 401 error and retry is true or not
        handleLogout();
      }
      if (
        (error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN) &&
        !token &&
        !refreshToken &&
        isUserLoggedIn
      ) {
        // Check for 401 error and retry is true or not
        handleLogout();
      }
      // return Promise.reject(error)
      throw error; // Throwing error if again there is an not error related to temp-token
    }
  );

  // #50218 - Use interceptor to inject the custom headers for log tags
  axios.interceptors.request.use(async (request) => {
    let tag = await config.storage.getItem(storageKeys.X_LOG_TAG);
    if (!tag) {
      tag = generateRandomValue();
      await config.storage.setItem(storageKeys.X_LOG_TAG, tag);
    }
    request.headers["X-Log-Tag"] = tag;
    return request;
  });

  // eslint-disable-next-line consistent-return
  const getRefreshAccessToken = async () => {
    try {
      const {
        data: { access_token: accessToken, refresh_token: refreshToken, ...restData },
        success
      } = await onTokenExpire();
      if (success) {
        await config.storage.encryptedSetItem(appStorage.AUTH_TOKEN, accessToken);
        await config.storage.encryptedSetItem(appStorage.AUTH_REFRESH_TOKEN, refreshToken);
        await config.storage.encryptedSetItem(appStorage.USER_AUTH_DATA, JSON.stringify(restData));
        return { data: { access_token: accessToken, refresh_token: refreshToken } };
      }
    } catch (error) {
      console.log(error);
      alert('Error in config');
      return { data: { access_token: false } };
    }
  };

  // Config for axios authorise requests
  // eslint-disable-next-line no-unused-vars
  const axiosConfig = () => {
    // Function that will be called to refresh authorization
    const refreshAuthLogic = async (failedRequest) => {
      try {
        // Fetch temp token or refresh request once received 401;
        refreshTokenPromise =
          (await config.storage.encryptedGetItem(appStorage.AUTH_REFRESH_TOKEN)) &&
          (await config.storage.encryptedGetItem(appStorage.AUTH_TOKEN))
            ? getRefreshAccessToken()
            : getTempAccessToken();

        const {
          data: { access_token: accessToken = false }
        } = await refreshTokenPromise;

        // eslint-disable-next-line no-param-reassign
        failedRequest.response.config.headers.Authorization = `Bearer ${accessToken}`;
        return Promise.resolve();
      } catch (error) {
        console.log(error);
        // eslint-disable-next-line no-alert
        // alert('Error in refreshing session!');
        return Promise.reject(error);
      }
    };
    // Use interceptor to inject the token to requests
    axios.interceptors.request.use(async (request) => {
      // Fetch token from storage
      const token = await config.storage.encryptedGetItem(appStorage.AUTH_TOKEN);
      // TODO: Add payment token into payment token api
      // const token = await config.storage.encryptedGetItem(appStorage.);
      if (
        token &&
        (request.url.includes(config.env.REACT_APP_MO_URL) ||
          request.url.includes(config.env.REACT_APP_MO_USER_URL))
      ) {
        // Assign token to request
        request.headers.Authorization = `Bearer ${token}`;
      }
      return request;
    });

    // Instantiate the interceptor (you can chain it as it returns the axios instance)
    createAuthRefreshInterceptor(axios, refreshAuthLogic);
  };

  // /**
  //  * Fix refresh token erorr after invalid session on logout
  //  */
  // const refreshLogoutFix = () => {
  //   try {
  //     Axios.interceptors.response.use(response => response, error => {
  //       console.log({response, error});
  //       // const status = error.response ? error.response.status : null
  //       // const isRefreshTokenReq = error.request.url
  //       // if (status === 401) {
  //       //   // will loop if refreshToken returns 401
  //       //   return refreshToken(store).then(_ => {
  //       //     error.config.headers['Authorization'] = 'Bearer ' + store.state.auth.token;
  //       //     error.config.baseURL = undefined;
  //       //     return Axios.request(error.config);
  //       //   })
  //       //   // Would be nice to catch an error here, which would work, if the interceptor is omitted
  //       //   .catch(err => err);
  //       // }

  //       // return Promise.reject(error);
  //     });
  //   } catch (error) {
  //     console.log(error);
  //     // eslint-disable-next-line no-alert
  //     alert('Error in refreshing session!');
  //     return Promise.reject(error);
  //   }
  // };

  // axiosConfig();

  return children;
}
