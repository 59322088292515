import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { Button, Text } from '@core/Inputs';
import { FullScreenModal } from '@core/Utils';
import { appRoute, appTextInputClass } from '@utils/globalConstant';
import ArrowLeft from '@theme/assets/img/arrow_left.svg';
import EDEKAsmartLogoBlue from '@theme/assets/img/EDEKAsmart_logo.svg';
import forgotPasswordIcon from '@theme/assets/img/passwort_angefordert.svg';

import './style.scss';

export function ForgetPassword() {
  // States

  // Contexts
  const { t } = useStaticContent();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const {
    forgotPasswordInitialValue,
    forgotPasswordValidation,
    onForgotPasswordSubmit,
    forgotPassSuccess,
    setForgotPassSuccess,
    isLoading
  } = useAccount();

  // Functions
  const onBackPress = () => {
    navigate(appRoute.LOGIN);
  };

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isLoading]);

  return (
    <>
      <div className="light-gradient h-100 w-100 vh-100">
        <div className="container">
          <div className="sticky-top">
            <div className="logo">
              <img src={EDEKAsmartLogoBlue} alt="logo" />
            </div>
          </div>
          <div className="card mx-auto mt-3">
            <div className="card-body">
              <center>
                <div className="login-top-title">
                  <img
                    aria-hidden
                    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
                    role="button"
                    className="arrow-left"
                    src={ArrowLeft}
                    alt="Leftarrow"
                    onClick={onBackPress}
                  />
                  <h4
                    className="text-center d-inline-block align-middle"
                    dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_h1') }}
                  />
                </div>
              </center>
              <div className="login-form">
                <Formik
                  initialValues={forgotPasswordInitialValue}
                  validationSchema={forgotPasswordValidation}
                  onSubmit={onForgotPasswordSubmit}>
                  {({
                    values: { number, birthDate },
                    handleBlur,
                    handleChange,
                    errors,
                    touched,
                    handleSubmit
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Text
                        type="text"
                        inputCustomClass={appTextInputClass.V2}
                        label={t('ek_forgot-pw_number')}
                        id="number"
                        name="number"
                        onChange={handleChange}
                        value={number}
                        placeholder={t('ek_forgot-pw_number_input')}
                        isValid={touched.number && !errors.number}
                        isInvalid={!!touched.number && !!errors.number}
                        onBlur={handleBlur}
                        error={touched.number && errors.number ? errors.number : null}
                      />

                      <Text
                        type="date"
                        inputCustomClass={appTextInputClass.V2}
                        label={t('ek_ap_personal_birth-date')}
                        placeholder={t('ek_input_placehoder_forget_passwod_birthday')}
                        name="birthDate"
                        id="birthDate"
                        onChange={handleChange}
                        value={birthDate}
                        isValid={touched.birthDate && !errors.birthDate}
                        isInvalid={!!touched.birthDate && !!touched.birthDate && !!errors.birthDate}
                        onBlur={handleBlur}
                        error={touched.birthDate && errors.birthDate ? errors.birthDate : null}
                        onfocus={"this.type='date'"}
                      />

                      {/* <Text
                        type="text"
                        inputCustomClass={appTextInputClass.V2}
                        toolTip={t('ek_forgot-pw_text2')}
                        label={t('ek_forgot-pw_puk')}
                        id="puk"
                        name="puk"
                        onChange={handleChange}
                        value={puk}
                        placeholder={t('ek_input_placehoder_PUK')}
                        isInvalid={!!touched.puk && !!errors.puk}
                        isValid={touched.puk && !errors.puk}
                        onBlur={handleBlur}
                        error={touched.puk && errors.puk ? errors.puk : null}
                      /> */}
                      <div className="d-flex justify-content-center text-center mt-5 pt-5 mb-4">
                        <Button variant="primary" type="submit" isLoading={isLoading}>
                          {t('ek_forgot-pw_send_button')}
                        </Button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
              <center>
                <div
                  className="copytext-medium text-center"
                  dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_text4') }}
                />
              </center>
            </div>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={forgotPassSuccess} fullscreen>
        <div className="forget-password-modal vh-100 d-flex justify-content-center flex-column align-items-center ">
          <div className="d-flex justify-content-center text-center">
            <div className="forgot-password-icon mb-4 pb-2">
              <img src={forgotPasswordIcon} alt="forgot-icon" />
            </div>
          </div>

          <div className="d-flex justify-content-center text-center mb-3">
            <h2
              className="text-uppercase"
              dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_success_h1') }}
            />
          </div>

          <div className="d-flex justify-content-center text-center">
            <p
              className="copytext"
              dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_success_text1') }}
            />
          </div>

          <div className="d-flex justify-content-center text-center">
            <p
              className="copytext"
              dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_success_text2') }}
            />
          </div>

          <div className="d-flex justify-content-center text-center mt-5">
            <Button
              variant="primary"
              onClick={() => {
                setForgotPassSuccess(false);
                navigate(appRoute.LOGIN);
              }}>
              {t('ek_forgot-pw_success_back')}
            </Button>
          </div>
        </div>
      </FullScreenModal>
    </>
  );
}

export default ForgetPassword;

export * from './ForcePasswordChange';
