import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useActivation } from '@context/MobileOne';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, DropDown, Radio } from '@core/Inputs';

import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import {
  appAutoTopUpPeriodType,
  appButtonType,
  appIconsPath,
  appPaymentCertainDay,
  appPaymentMethods,
  appPaymentProductType,
  appTopUpTo,
  appTopUpType
} from '@utils/globalConstant';
import { DirectPaymentProduct, PaymentMethod, PaymentProduct } from '@part/Payment';
import errorIcon from '@theme/assets/img/error.svg';

import { PaymentMethods } from './PaymentMethods';
import { useNavigate } from 'react-router-dom';

export function SetUpAutoTopup() {
  // State
  const [isPaymentClicked, setIsPaymentClicked] = useState(false);

  // Context
  const { t, staticContentData } = useStaticContent();
  const navigate = useNavigate();

  const {
    paymentMethod,
    // nextStep,

    isLoading,
    paymentProducts,
    processToPaymentForm,
    processToPayment,
    productValidation
  } = useActivation();

  // Functions
  const filterProduct = () =>
    (paymentProducts || [])
      .filter(
        (product) =>
          // Direct & Prepaid recharge
          (processToPaymentForm.type === appTopUpType.DIRECT &&
            (product.productType === appTopUpType.DIRECT ||
              product.productType === appPaymentProductType.PREPAID)) ||
          // Low Balance Auto Topup
          (processToPaymentForm.type === appTopUpType.AUTOMATIC &&
            product.productType === appPaymentProductType.LOW_BALANCE) // ||
      )
      .filter((product) => Number(product.id) !== 887189 && Number(product.id) !== 887525)
      .sort((a, b) => a.amount - b.amount);

  const filterPaymentMethod = () =>
    (paymentMethod || [])
      .filter(
        ({ paymentTokenEnabled, onceOffEnabled }) =>
          (processToPaymentForm.type === appTopUpType.AUTOMATIC && paymentTokenEnabled) ||
          (processToPaymentForm.type === appTopUpType.DIRECT && onceOffEnabled)
      )
      .sort((a, b) => {
        const filterList = staticContentData?.ek_paymentMethodSortOrder?.activationTopUp || [];
        return filterList.indexOf(a.name) - filterList.indexOf(b.name);
      });

  const getProductLabel = (product) => {
    if (product.amount > 0 || Number(product.minAmount) === Number(product.maxAmount)) {
      return `${product.amount} €`;
    }
    return '';
  };

  const getDirectProductLabel = (amount) => `${displayPrice(amount)} €`.replace('.', ',');

  const onClickPosident = () => {
    // navigate(appRoute.ACTIVATION_LEGITIMATION);
    console.log('onClickPosident');
  };

  return (
    <div>
      <Formik
        initialValues={processToPaymentForm}
        validationSchema={productValidation}
        validateOnMount
        onSubmit={processToPayment}>
        {({ values, handleChange, handleBlur, handleSubmit, setValues, errors, touched }) => (
          <Form onSubmit={handleSubmit}>
            <h3 className="py-4">{t('ek_autotop-up_setup_h4')}</h3>
            <div className="price-radio">
              <div className="row">
                {filterProduct().map((item) =>
                  item.amount > 0 || Number(item.minAmount) === Number(item.maxAmount) ? (
                    <div className="col-lg-3" key={`select-payment-product-${item.id}`}>
                      <PaymentProduct
                        id={item.id}
                        amount={item.amount}
                        isSelected={values.productId === item.id}
                        onBlur={handleBlur}
                        onSelect={(id) => {
                          setValues({
                            ...values,
                            productId: id,
                            productType: item.productType,
                            isDirectProduct: 'NO',
                            productAmount: ''
                          });
                        }}
                      />
                    </div>
                  ) : (
                    <div className="py-2" key={`select-direct-payment-product-${item.id}`}>
                      <DirectPaymentProduct
                        id={item.id}
                        isSelected={values.productId === item.id}
                        otherAmount={values.productAmount}
                        onBlur={handleBlur}
                        error={
                          touched.productAmount && errors.productAmount
                            ? errors.productAmount
                            : null
                        }
                        onSelect={(id) => {
                          setValues({
                            ...values,
                            productId: id,
                            productType: item.productType,
                            isDirectProduct: 'YES'
                          });
                        }}
                        onAmountChange={(amount) => {
                          setValues({
                            ...values,
                            productId: item.id,
                            productType: item.productType,
                            isDirectProduct: 'YES',
                            productAmount: amount
                          });
                        }}
                      />
                    </div>
                  )
                )}
              </div>
            </div>
            {touched.productId && errors.productId && (
              <div className="d-block invalid-feedback">
                {!!errors.productId && (
                  <img className="error-icon" src={errorIcon} alt="error_icon" />
                )}
                {errors.productId}
              </div>
            )}

            {/* <div className="mt-5 pt-5" /> */}

            <h3 className="py-4">{t('ek_reg_step6_topuppay_hdl')}</h3>

            <div className="row">
              {filterPaymentMethod().map(
                (payment) => (
                  // payment.name === appPaymentMethods.PAYPAL ||
                  // payment.name === appPaymentMethods.CREDIT_CARD ? (
                  <div
                    className="payment-method col-lg-4 py-1"
                    key={`${payment.name}-method`}
                    role="button">
                    <PaymentMethod
                      name={payment.name}
                      onSelect={(id) => {
                        handleChange('paymentMethodName')({
                          target: { name: 'paymentMethodName', value: id }
                        });
                      }}
                      isSelected={values.paymentMethodName === payment.name}
                    />
                  </div>
                )
                // ) : (
                //   <></>
                // )
              )}
              <div>
                {values.paymentMethodName === appPaymentMethods.SEPA_DIRECT_DEBIT && (
                  <div
                    className="copytext pt-3"
                    dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topuppay_sepa_active') }}
                  />
                )}
              </div>
            </div>
            <div className="py-4">
              <Radio
                label={t('ek_reg_step6_alphacom_txt')}
                id="terms"
                name="terms"
                isDefaultChecked={values.terms}
                onClick={() => {
                  handleChange({
                    target: {
                      value: !values.terms,
                      name: 'terms',
                      id: 'terms'
                    }
                  });
                }}
              />
            </div>

            <div className="py-4 text-center">
              <img src={appIconsPath.ALPHACOMM_LOGO} alt="alphacomm-logo" />
            </div>

            <div className="py-4 d-flex justify-content-center">
              <Button
                buttonClass={appButtonType.PRIMARY}
                isLoading={isLoading}
                disabled={
                  isLoading ||
                  (values.chargeTo === appTopUpTo.OTHER && !values.phone) ||
                  !values.productId ||
                  !values.paymentMethodName ||
                  !values.terms ||
                  (errors &&
                    Object.keys(errors || {}).filter((v) => errors && errors && errors[v]).length >
                      0)
                }
                isIcon
                icon={<i className="bi bi-arrow-right" />}
                type="submit">
                {t('ek_reg_step6_topup_btn')}
              </Button>
            </div>
            {/* <div className="py-4 d-flex justify-content-center">
              <a
                href="javascript:void(0)"
                className=""
                onClick={() => nextStep()}
                dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_btn2') }}
              />
            </div> */}
          </Form>
        )}
      </Formik>

      {/* {isPaymentClicked ? (
        <PaymentMethods
          isLoading={isLoading}
          paymentMethod={paymentMethod}
          chargingForm={chargingForm}
          chargingValidations={chargingValidations}
          chargingFormSubmit={chargingFormSubmit}
        />
      ) : ( */}
      {/* <OptionsAndProducts
        processToPaymentForm={processToPaymentForm}
        processToPaymentValidations={processToPaymentValidations}
        processToPayment={processToPayment}
        setPaymentProducts={setPaymentProducts}
        paymentProducts={paymentProducts}
        allPaymentProducts={allPaymentProducts}
        otherAmount={otherAmount}
        setOtherAmount={setOtherAmount}
        isPaymentClicked={isPaymentClicked}
        setIsPaymentClicked={setIsPaymentClicked}
        isLoading={isLoading}
        paymentMethod={paymentMethod}
        chargingForm={chargingForm}
        chargingValidations={chargingValidations}
        chargingFormSubmit={chargingFormSubmit}
      /> */}
      {/* )} */}
    </div>
  );
}

export default SetUpAutoTopup;
