/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useCustomer } from '@context/MobileOne/Customer';
import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { appAlert, appStorage } from '@utils/globalConstant';
import { useAlert, useLoader } from '@context/Utils';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';

export const TariffContext = createContext({});

export function TariffContextProvider({ children }) {
  // Context
  const { showAlert } = useAlert();
  const { staticContentData, onStaticContentCall, t } = useStaticContent();
  // const { hideLoader, showLoader } = useLoader();
  const { customerData, getCustomerData, tariffChangeInProgress } = useCustomer();
  const { onActiveProductCall, onBookableTariffCall, onChangeTariffCall, onVviDocuments } =
    useMobileOne();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [allTariff, setAllTariff] = useState([]);
  const [activeTariff, setActiveTariff] = useState([]);
  const [bookableTariff, setBookableTariff] = useState([]);
  const [tariffSuccessModal, setTariffSuccessModal] = useState(false);
  const [showTariffBanner, setShowTariffBanner] = useState(false);
  const [changeTariffName, setChangeTariffName] = useState('');
  const [vviDocuments, setVviDocuments] = useState([]);

  // Validations
  const termsValidations = Yup.object().shape({
    terms: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });

  // Functions

  // eslint-disable-next-line consistent-return
  const staticTariffManipulation = (tariffApiData) => {
    if (staticContentData && staticContentData.tariff && staticContentData.tariff.length > 0) {
      return tariffApiData
        .map((tariff) => staticContentData.tariff.find((tr) => tr.id === tariff.id))
        .sort((x, y) => x.sortOrder - y.sortOrder);
    }
  };

  const tariffManipulationUnsort = (tariffApiData) => {
    const staticApiData = staticContentData;
    let filterArray = [];

    if (staticApiData != null) {
      if (staticApiData.tariff != null && staticApiData.tariff.length > 0) {
        const staticContentTariff = staticApiData.tariff;
        const apiTariffData = tariffApiData;

        filterArray = staticContentTariff.filter((staticContentTariffItem) =>
          apiTariffData.some(
            (apiTariffDataitem) => staticContentTariffItem.id === apiTariffDataitem.id
          )
        );
        return filterArray;
      }
      return filterArray;
    }
    return filterArray;
  };

  // Manipulate the single tariff data.
  const staticTariffManipulate = (tariffApiData) => {
    const staticApiData = staticContentData;

    if (staticApiData != null) {
      if (staticApiData.tariff != null && staticApiData.tariff.length > 0) {
        const staticContentTariff = staticApiData.tariff;
        const apiTariffData = tariffApiData;
        let filterArray = [];

        filterArray = staticContentTariff.find(
          (staticContentTariffItem) => staticContentTariffItem.id === apiTariffData.id
        );

        return filterArray;
      }
      return tariffApiData;
    }
    return tariffApiData;
  };

  // Get customer active tariff and storing it in state
  const getActiveProductCall = async () => {
    try {
      let activeTariffDetails = [];
      const { data } = await onActiveProductCall();
      if (data) {
        data.forEach((elem, i) => {
          let localTariff = elem;
          let tariffDetails = staticTariffManipulate(elem.tariff);
          localTariff.tariff = tariffDetails;
          activeTariffDetails.push(localTariff);
        });
        setActiveTariff(activeTariffDetails);
      }
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Get customer bookable tariff and storing it in state
  const getBookableTariffCall = async () => {
    try {
      const { data } = await onBookableTariffCall();
      if (!staticContentData) {
        const response = await onStaticContentCall({
          platform: 'web',
          lang: 'de',
          client: 'edeka'
        });
        if (response.status === 200 && data) {
          setBookableTariff([
            ...data
              .map((tariff) => response.data.tariff.find((tr) => tr.id === tariff.id))
              .sort((x, y) => x.sortOrder - y.sortOrder)
          ]);
        }
      } else if (data) {
        setBookableTariff(staticTariffManipulation(data));
      }
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Function loads after we get customer data, and returns active and bookable tariff
  const afterLoad = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      await getActiveProductCall();
      await getBookableTariffCall();
      setIsLoading(false);
      // hideLoader();
      return null;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      return error;
    }
  };

  // Find tariff status name to display in tariff product card.
  const getTariffStatusName = (tariffId) => {
    const tariff = activeTariff.find((trf) => trf.tariff.id.toString() === tariffId.toString());
    if (tariff) {
      return tariff.status.name;
    }
    return false;
  };

  // Switch Active Tariff
  const onSubmit = async ({ tariffId }) => {
    try {
      setIsLoading(true);
      // showLoader();
      const selectedTariff = bookableTariff.find(
        ({ id }) => parseInt(id, 10) === parseInt(tariffId, 10)
      );
      const { data, success, status } = await onChangeTariffCall({ tariffId });
      console.log({ data, success, status });
      if (success) {
        setChangeTariffName(selectedTariff.name);
        setTariffSuccessModal(true);
        const currentTime = new Date().getTime();
        const tarrifShownTime = currentTime + 300000;
        localStorage.setItem(appStorage.TARIFF_SHOWN_TIME, tarrifShownTime);
        localStorage.setItem(appStorage.MSISDN, customerData.msisdn);
      } else {
        setIsLoading(false);
        showAlert({
          type: appAlert.ERROR,
          message: 'Error in processing, try again after sometime.'
        });
      }
      await getCustomerData();
      setIsLoading(false);
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Call this function to get VVI documents for tariff change page.
  const getVviDocuments = async (id) => {
    try {
      setIsLoading(true);
      const { data, success } = await onVviDocuments({ tariffId: id, mnp: true });
      if (success) {
        setVviDocuments(data);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Hooks
  useEffect(() => {
    const { msisdn, ...restCustomerData } = customerData;
    if (restCustomerData && msisdn && staticContentData) {
      afterLoad();
    }
  }, [customerData, staticContentData]);

  // Merge both Active Tariffs & Bookable Tariffs to show all list
  useEffect(() => {
    if (activeTariff && bookableTariff) {
      const activeProduct = activeTariff;

      const bookableProduct = bookableTariff;
      // eslint-disable-next-line prefer-const
      let bookableTariffs = [];
      let finalBookableTariffs = [];
      let finalActiveTariff = [];
      let flags = [];

      /* Finding unique Tariff for Tariff overview page. */
      // eslint-disable-next-line no-unused-vars
      activeProduct.forEach((active, i) => {
        // eslint-disable-next-line no-unused-vars
        bookableProduct.forEach((bookable, j) => {
          if (active.tariff.id !== bookable.id) {
            bookableTariffs.push(bookable);
          }
        });
      });

      let a;
      // eslint-disable-next-line no-plusplus
      for (a = 0; a < bookableTariffs.length; a++) {
        // eslint-disable-next-line no-continue
        if (flags[bookableTariffs[a].id]) continue;
        flags[bookableTariffs[a].id] = true;
        finalBookableTariffs.push(bookableTariffs[a]);
      }

      const allTariffs = [
        ...activeTariff.map(({ tariff }) => ({
          ...tariff,
          additionalInfo: tariff.additionalInfo
            ? tariff.additionalInfo
            : {
                primaryColor: '#fff',
                secondaryColor: '#fff',
                bullets: tariff?.additionalInfo?.bullets ? tariff?.additionalInfo?.bullets : []
              }
        })),
        ...finalBookableTariffs.map((tariff) => ({
          ...tariff,
          additionalInfo: tariff.additionalInfo
            ? tariff.additionalInfo
            : {
                primaryColor: '#fff',
                secondaryColor: '#fff',
                bullets: tariff.bullets ? tariff.bullets : []
              }
        }))
      ];

      let b;
      // eslint-disable-next-line no-plusplus
      for (b = 0; b < allTariffs.length; b++) {
        // eslint-disable-next-line no-continue
        if (flags[allTariffs[b].id]) continue;
        flags[allTariffs[b].id] = true;
        finalActiveTariff.push(allTariffs[b]);
      }

      setAllTariff([...finalActiveTariff, ...finalBookableTariffs]);
    }
    return () => {
      setAllTariff([]);
    };
  }, [activeTariff, bookableTariff]);

  useEffect(() => {
    if (customerData.msisdn) {
      const tariffShownTime = parseInt(localStorage.getItem(appStorage.TARIFF_SHOWN_TIME), 10);
      const currentTime = new Date().getTime();
      const msisdn = localStorage.getItem(appStorage.MSISDN);
      setShowTariffBanner(currentTime < tariffShownTime && customerData.msisdn === msisdn);
      // setShowTariffBanner(true);
      if (currentTime > tariffShownTime && customerData.msisdn === msisdn) {
        localStorage.removeItem(appStorage.TARIFF_SHOWN_TIME);
        localStorage.removeItem(appStorage.MSISDN);
      }
    }
  }, [customerData.msisdn]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      tariffSuccessModal,
      setTariffSuccessModal,
      showTariffBanner,
      changeTariffName,
      setChangeTariffName,
      vviDocuments,

      allTariff,
      setAllTariff,
      activeTariff,
      bookableTariff,
      termsValidations,

      // Functions
      getTariffStatusName,
      getVviDocuments,
      onSubmit,
      staticTariffManipulation,
      tariffManipulationUnsort,
      staticTariffManipulate
    }),
    [
      // States
      isLoading,
      setIsLoading,
      tariffSuccessModal,
      setTariffSuccessModal,
      showTariffBanner,
      changeTariffName,
      setChangeTariffName,
      vviDocuments,

      allTariff,
      setAllTariff,
      activeTariff,
      bookableTariff,
      termsValidations,

      // Functions
      getTariffStatusName,
      getVviDocuments,
      onSubmit,
      staticTariffManipulation,
      tariffManipulationUnsort,
      staticTariffManipulate
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <TariffContext.Provider value={contextPayload}>{children}</TariffContext.Provider>;
}
TariffContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useTariff = () => useContext(TariffContext);

export default TariffContext;
