import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useCustomer, useTariff } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appRoute } from '@utils/globalConstant';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import Profile from '@theme/assets/img/profil.svg';
import tariffDetail from '@theme/assets/img/tarifdetails.svg';
import vorteile from '@theme/assets/img/vorteile.svg';
import service from '@theme/assets/img/service.svg';
import einstellungen from '@theme/assets/img/einstellungen.svg';
import aktionscode from '@theme/assets/img/aktionscode.svg';
import './style.scss';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';

export function Account() {
  // Constants
  const cardTitles = [
    { title: 'ek_public_account_profile', route: appRoute.PROFILE, icon: Profile, className: '' },
    {
      title: 'ek_public_account_tariff',
      route: appRoute.TARIFF_DETAILS,
      icon: tariffDetail,
      className: ''
    },
    {
      title: 'ek_public_account_advantages',
      route: appRoute.ADVANTAGES,
      icon: vorteile,
      className: ''
    },
    {
      title: 'ek_public_account_service',
      route: appRoute.HELP_AND_SERVICE,
      icon: service,
      className: ''
    },
    {
      title: 'ek_public_account_settings',
      route: appRoute.PREFERENCES,
      icon: einstellungen,
      className: ''
    },
    {
      title: 'ek_public_account_vouchers',
      route: '',
      icon: aktionscode,
      className: 'blurred'
    }
  ];

  // States

  // Contexts
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const navigate = useNavigate();
  const { showTariffBanner, changeTariffName } = useTariff();
  const { onSsoTokenCallWithQuery, isLoading: isOnlineTopUpLoading } = useOnlineTopUp();
  const {
    tariffChangeInProgress,
    isLoading: isCustomerLoading,
    getEvnData,
    customerData
  } = useCustomer();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();

  // Functions

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isCustomerLoading || isOnlineTopUpLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isCustomerLoading, isOnlineTopUpLoading]);

  useEffect(() => {
    if (isUserLoggedIn && customerData) {
      onSsoTokenCallWithQuery();
      getEvnData();
    }
  }, [isUserLoggedIn, customerData]);

  return (
    <>
      {(tariffChangeInProgress || showTariffBanner) && (
        <div
          className="alert alert-warning text-center"
          role="alert"
          dangerouslySetInnerHTML={{
            __html: t('ek_tarif_success-msg_text1').replace('{tariff}', changeTariffName)
          }}
        />
      )}
      <div className="container">
        <MetaTitle
          title={t('ek_account_meta_title')}
          description={t('ek_account_meta_description')}
          keywords={t('ek_account_meta_keywords')}
        />
        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            </Breadcrumbs>
            <h4>{t('ek_account_header')}</h4>
            <br />
          </div>
          <div className="user-account-main d-flex flex-wrap">
            {cardTitles.map((elem) => (
              <div
                className={`user-account d-flex flex-column align-items-center justify-content-center ${elem.className}`}
                aria-hidden
                role="button"
                onClick={() => {
                  navigate(elem.route);
                }}
                key={`user-account-${elem.title}`}
              >
                <center>
                  <div>
                    <i>
                      <img src={elem.icon} alt={`${elem.title}-icon`} />
                    </i>
                    <h6 dangerouslySetInnerHTML={{ __html: t(elem.title) }} />
                  </div>
                </center>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Account;

export * from './SimLock';
export * from './Profile';
export * from './TariffDetails';
export * from './Advantages';
export * from './Preferences';
