/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from 'react';

import { appAlert } from '@utils/globalConstant';
import { useAlert } from '@context/Utils';

import './index.scss';

// function SuccessAlert({ message, type }, i) {
//   return <BAlert {...{ variant: type, key: `${type}_${i}` }}>{message}</BAlert>;
// }

// function ErrorAlert({ message, type }, i) {
//   return <BAlert {...{ variant: type, key: `${type}_${i}` }}>{message}</BAlert>;
// }

// function WarningAlert({ message, type }, i) {
//   return <BAlert {...{ variant: type, key: `${type}_${i}` }}>{message}</BAlert>;
// }

// function InfoAlert({ message, type }, i) {
//   return <BAlert {...{ variant: type, key: `${type}_${i}` }}>{message}</BAlert>;
// }

function SuccessAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-success d-flex align-items-center"
      role="alert"
      key={`alert-success-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Success:">
        <use xlinkHref="#check-circle-fill" />
      </svg>
      <h3>{message}</h3>
    </div>
  );
}

function ErrorAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-danger d-flex align-items-center"
      role="alert"
      key={`alert-danger-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Danger:">
        <use xlinkHref="#exclamation-triangle-fill" />
      </svg>
      <h3>{message}</h3>
    </div>
  );
}

function WarningAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-warning d-flex align-items-center"
      role="alert"
      key={`alert-warning-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Warning:">
        <use xlinkHref="#exclamation-triangle-fill" />
      </svg>
      <h3>{message}</h3>
    </div>
  );
}

function InfoAlert({ message, type }, i) {
  return (
    <div
      className="alert alert-primary d-flex align-items-center"
      role="alert"
      key={`alert-primary-${i}`}
    >
      <svg className="bi flex-shrink-0 me-2" role="img" aria-label="Info:">
        <use xlinkHref="#info-fill" />
      </svg>
      <h3>{message}</h3>
    </div>
  );
}

export function Alert() {
  const { alert } = useAlert();
  if (!alert) {
    return <></>;
  }

  const showAlert = (al, i) => {
    let defaultAlert = <></>;
    switch (al.type) {
      case appAlert.SUCCESS:
        defaultAlert = SuccessAlert(al, i);
        break;
      case appAlert.ERROR:
        defaultAlert = ErrorAlert(al, i);
        break;
      case appAlert.WARNING:
        defaultAlert = WarningAlert(al, i);
        break;

      default:
        // appAlert.INFO
        defaultAlert = InfoAlert(al, i);
        break;
    }
    return defaultAlert;
  };

  return (
    <div className="app-alert">
      <div className="container position-relative">
        <svg xmlns="http://www.w3.org/2000/svg" className="d-none">
          <symbol id="check-circle-fill" viewBox="0 0 16 16">
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </symbol>
          <symbol id="info-fill" viewBox="0 0 16 16">
            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
          </symbol>
          <symbol id="exclamation-triangle-fill" viewBox="0 0 16 16">
            <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
          </symbol>
        </svg>
        <div className="app-alert-list copytext-semibold">{alert.map(showAlert)}</div>
      </div>
    </div>
  );
}

export default Alert;
