import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useAccount, useCustomer, useTariff } from '@context/MobileOne';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Button } from '@core/Inputs';
import { CustomerHeader } from '@part/DashBoard';
import { FullScreenModal, Modal } from '@core/Utils';
import { useLayout, useLoader } from '@context/Utils';
import { Slider } from '@part/Slider';
import { appRoute } from '@utils/globalConstant';
import EDEKAsmartLogo from '@theme/assets/img/EDEKAsmart_logo_neg.svg';
import { PackDetailCard } from './PackDetails';
import './index.scss';

export function DashBoard() {
  // Constants

  // States
  const [tariffChangeAlert, setTariffChangeAlert] = useState(false);
  const [customerName, setCustomerName] = useState('');

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { showLoader, hideLoader } = useLoader();
  const navigate = useNavigate();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showTariffBanner, changeTariffName, isLoading: isTariffLoading } = useTariff();
  const {
    personalData: { firstName = '', lastName = '' },
    customerUsage,
    tariffChangeInProgress,
    loginBgImage,
    isLoading: isCustomerLoading
  } = useCustomer();
  const {
    bookedOptions,
    handleCounterTDMView,
    counterTDMList,
    smsUsage,
    isLoading: isDashBoardLoading,
    activeProduct: { tariff = {} }
  } = useDashBoard();
  const { welcomePopup, setWelcomePopup, isLoading: isAccountLoading } = useAccount();

  // Functions
  const showDownloadAlert = () => {
    setWelcomePopup('appDownloadAlert');
  };

  // Hooks

  useEffect(() => {
    if (welcomePopup === 'welcome_popup' && firstName) {
      setCustomerName(`${firstName} ${lastName}`);
      setTimeout(() => {
        setWelcomePopup('');
        showDownloadAlert();
      }, 3000);
    }
  }, [welcomePopup && firstName]);

  useEffect(() => {
    if (tariffChangeInProgress || showTariffBanner) {
      setTariffChangeAlert(tariffChangeInProgress || showTariffBanner);
    }
  }, [tariffChangeInProgress, showTariffBanner]);

  useEffect(() => {
    if (Object.keys(customerUsage).length > 0 && smsUsage.length > 0) {
      handleCounterTDMView();
    }
  }, [bookedOptions, smsUsage]);

  useEffect(() => {
    if (staticContentData) {
      document.querySelector('#tariffredirect').addEventListener('click', () => {
        navigate(appRoute.TARIFF_OPTION);
      });
      document.querySelector('#tariffredirect').setAttribute('role', 'button');
      () => {
        document.querySelector('#tariffredirect').removeEventListener('click');
      };
    }
  }, [staticContentData]);

  useEffect(() => {
    if (
      isTariffLoading ||
      isCustomerLoading ||
      isDashBoardLoading ||
      isStaticContentLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isTariffLoading,
    isCustomerLoading,
    isDashBoardLoading,
    isStaticContentLoading,
    isAccountLoading
  ]);

  return (
    <>
      {(tariffChangeInProgress || showTariffBanner) && (
        <div
          className="alert alert-warning text-center mt-2"
          role="alert"
          dangerouslySetInnerHTML={{
            __html: t('ek_tarif_success-msg_text1').replace('{tariff}', changeTariffName)
          }}
        />
      )}
      <div className="container">
        <MetaTitle
          title={t('ek_dashboard_meta_title')}
          description={t('ek_dashboard_meta_description')}
          keywords={t('ek_dashboard_meta_keywords')}
        />

        <CustomerHeader />
      </div>
      <div
        className="dashboard-banner text-center my-4 d-none"
        dangerouslySetInnerHTML={{
          __html: t('ek_dashboard_campaign_bannertext')
        }}
      />
      <div className="container">
        <div className="dashboard-slider mt-4 mb-5 pt-2">
          <Slider
            items={counterTDMList.map(
              (item, index) => item && <PackDetailCard key={index} item={item} tariff={tariff} />
            )}
            isDashboard
          />
        </div>

        <Modal
          isOpen={tariffChangeAlert}
          title={t('ek_web_tarif-pending_popup_h1')}
          onClose={() => {
            setTariffChangeAlert(false);
          }}
          isCloseButton>
          <div className="tariff-change-alert">
            <div
              className="copytext-semibold text-white"
              dangerouslySetInnerHTML={{
                __html: t('ek_web_tarif-pending_popup_salutation').replace(
                  '{name}',
                  `${customerName}`
                )
              }}
            />

            <p
              className="copytext text-white"
              dangerouslySetInnerHTML={{ __html: t('ek_web_tarif-pending_popup_text1') }}
            />

            <div className="d-flex justify-content-center text-center">
              <Button
                variant="primary"
                type="button"
                onClick={() => {
                  setTariffChangeAlert(false);
                }}>
                {t('ek_web_tarif-pending_popup_btn')}
              </Button>
            </div>
          </div>
        </Modal>

        <FullScreenModal isOpen={welcomePopup === 'welcome_popup' && firstName !== ''} fullscreen>
          <section className="welcome-screen">
            <div className="dark-gradient-welcome h-100 w-100 vh-100">
              <div
                className="welcome-bg"
                style={{
                  backgroundImage: `url(${
                    loginBgImage.responsive_image ? loginBgImage?.responsive_image['1x'] : ''
                  })`
                }}>
                <div className="container">
                  <div className="logo text-start">
                    <img src={EDEKAsmartLogo} alt="logo" />
                  </div>
                  <div className="d-flex align-items-center activation-middle justify-content-center flex-column">
                    <h1
                      className="text-white text-center"
                      dangerouslySetInnerHTML={{
                        __html: t('ek_welcome-screen_h1').replace('users_name', customerName)
                      }}
                    />
                    <p
                      className="text-white copytext mt-4"
                      dangerouslySetInnerHTML={{ __html: t('ek_welcome-screen_copy') }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </FullScreenModal>
      </div>
    </>
  );
}

export default DashBoard;
