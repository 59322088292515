/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation, useCustomer } from '@context/MobileOne';
import { TariffProduct } from '@part/Tariff';
import { Button } from '@core/Inputs';
import { appButtonType, appTariffs } from '@utils/globalConstant';
import warning from '@theme/assets/img/warning.svg';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import './style.scss';
import { Tooltip } from '@core/Utils';

export function ChooseTariff() {
  // States
  const [selectedTariff, setSelectedTariff] = useState(null);

  // Context
  const {
    isLoading,
    tariffActivationForm,
    setTariffActivationForm,
    // tariffActivationValidation,
    tariffActivationFormSubmit,
    bookableTariffs
  } = useActivation();
  const { t } = useStaticContent();
  const { productInfoPdfs } = useCustomer();

  // Functions
  function onTariffClicked(id, tariffName, color) {
    setTariffActivationForm({
      chosenTariffId: id,
      chosenTariffName: tariffName,
      tariffColor: color
    });
  }

  const getTariffPDF = () => {
    const tariffPdfId = selectedTariff
      ? selectedTariff.additionalInfo
        ? selectedTariff.additionalInfo.document
          ? selectedTariff.additionalInfo.document.productInformation
            ? selectedTariff.additionalInfo.document.productInformation.id
              ? selectedTariff.additionalInfo.document.productInformation.id
              : 0
            : 0
          : 0
        : 0
      : 0;
    const infoPdf = productInfoPdfs.find(({ id }) => Number(id) === tariffPdfId);
    return infoPdf?.media_url;
  };

  // Hooks

  useEffect(() => {
    const activeTariff = bookableTariffs.find(
      ({ id }) => id === tariffActivationForm.chosenTariffId
    );
    setSelectedTariff(activeTariff);

    return () => {
      setSelectedTariff({});
    };
  }, [tariffActivationForm]);

  useEffect(() => {
    if (bookableTariffs) {
      if (bookableTariffs.length === 1) {
        const localTariff = bookableTariffs[0];
        const {
          name,
          id,
          additionalInfo: { primaryColor }
        } = localTariff;
        onTariffClicked(id, name, primaryColor);
      }
    }
  }, [bookableTariffs]);

  return (
    <div className="container">
      <div className="row">
        {tariffActivationForm.chosenTariffId === 0 ? (
          <>
            <div className="activation-slide-align mb-5 pb-2">
              <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_tarif_choose_overview_h1') }} />
            </div>

            <div className="d-flex justify-content-center choose-tarrif-center">
              {bookableTariffs.map((item) => (
                <div className="me-5 ms-2" key={`select-tariff-${item.id}`}>
                  {item.defaultTariff && (
                    <div className="listing-max-width mx-auto mb-3">
                      <span className="listing-badge d-flex badge rounded-pill text-dark bg-light">
                        <img src={EmpfehLung} alt="Icon" />{' '}
                        <h6
                          className="mb-0 px-2"
                          dangerouslySetInnerHTML={{ __html: t('ek_our-recommendation') }}
                        />
                      </span>
                    </div>
                  )}

                  <TariffProduct
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...{ ...item }}
                    id={item.id}
                    header={`${item.name} - ${item.price} €`}
                    title={item.name}
                    desc={item.description}
                    // isSelected={chosenTariffId === item.id}
                    currency={item.currency}
                    key={`tariff-${item.id}`}
                    isActivationOverView
                    // eslint-disable-next-line react/jsx-no-bind
                    onButtonClick={onTariffClicked}
                  />
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="activation-slide-align activation-slide-align-details mb-3">
              <h4>{t('ek_ap_tarif_overview_h1')}</h4>
            </div>
            {selectedTariff !== null && (
              <div>
                <TariffProduct
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...{ ...selectedTariff }}
                  isActivationDetail
                />

                <div className="product-info mt-3 mb-3">
                  <h6>
                    <a href={getTariffPDF()} target="_blank" rel="noreferrer">
                      <u dangerouslySetInnerHTML={{ __html: t('ek_ap_tarif_production-info') }} />
                    </a>
                  </h6>
                </div>

                {selectedTariff?.id === appTariffs.TALK_JAHRESTARIF_START && (
                  <div>
                    <div>
                      <h3 className="text-center mt-5 mb-3">
                        <img src={warning} alt="warning" />
                        <span
                          dangerouslySetInnerHTML={{ __html: t('ek_ap_tariff-start_info_h1') }}
                        />
                      </h3>
                    </div>
                    <ol className="text-left talk-start-tariff mt-5">
                      <li className="copytext py-1">{t('ek_ap_tariff-start_info_list-1')}</li>
                      <li className="copytext py-1">
                        {t('ek_ap_tariff-start_info_list-2')}
                        <Tooltip title={t('ek_ap_tariff-start_info_help')}>
                          <img className="tool-tip-icon" src={tooltipIcon} alt="tooltip_icon" />
                        </Tooltip>
                      </li>
                      <li className="copytext py-1">{t('ek_ap_tariff-start_info_list-3')}</li>
                      <li className="copytext py-1">{t('ek_ap_tariff-start_info_list-4')}</li>
                    </ol>
                  </div>
                )}

                <br />
                <div className="slide-button-end">
                  <Button
                    buttonClass={appButtonType.PRIMARY}
                    onClick={() => {
                      tariffActivationFormSubmit(tariffActivationForm);
                    }}
                    isLoading={isLoading}
                  >
                    {t('ek_ap_overview_confirm_button1')}
                  </Button>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default ChooseTariff;
