import React from 'react';
import Lottie from 'react-lottie';
import { useLoader } from '@context/Utils';
import LoaderAnimation from './loading_EDEKA.json';
import './index.scss';

export function Loader() {
  // Constants
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  // Contexts
  const { loader } = useLoader();

  if (!loader) {
    return <></>;
  }

  return (
    <div id="loader" className="d-flex justify-content-center align-items-center">
      <Lottie options={defaultOptions} height={500} width={500} />
    </div>
  );
}

export default Loader;
