import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useOption, useCustomer } from '@context/MobileOne'; // useCustomer
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Button, Switch } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs, Card } from '@core/Layout';
import { FullScreenModal } from '@core/Utils';
// import { OptionProduct } from '@part/Option';
import { appButtonType, appCustomCardClass, appRoute, displayPrice } from '@utils/globalConstant';
import { ActiveTariffSection } from '../ActiveTariffSection';

export function BookOption() {
  // States
  const [bookOption, setBookOption] = useState({});
  const [bookOptionFailure, setBookOptionFailure] = useState(false);

  // Contexts
  const { optionId } = useParams();
  const { t } = useStaticContent();
  const {
    customerBalance: { totalBalance = 0 },
    isLoading: isCustomerLoading
  } = useCustomer();
  const navigate = useNavigate();
  const {
    // allOptions,
    bookableOptions,
    termsValidations,
    bookAnOption,
    bookOptionSuccess,
    setBookOptionSuccess
  } = useOption(); // isLoading, termsValidations, bookAnOption
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isDashboardLoading } = useDashBoard();

  const isBookingAllowed =
    totalBalance >= bookOption?.price &&
    bookableOptions.findIndex(({ id }) => parseInt(id, 10) === parseInt(optionId, 10)) > -1;

  // Functions
  const onSubmit = () => {
    if (isBookingAllowed) {
      bookAnOption(optionId);
    } else {
      setBookOptionFailure(!bookOptionFailure);
    }
  };

  const onSuccessModalClick = () => {
    setBookOptionSuccess(!bookOptionSuccess);
  };

  const onFailureModalClick = () => {
    setBookOptionFailure(!bookOptionFailure);
    navigate(appRoute.SET_AUTO_TOPUP);
  };

  const onFailureModalBack = () => {
    setBookOptionFailure(!bookOptionFailure);
    navigate(appRoute.BOOKABLE_DATA);
  };

  const getCreditText = () => {
    t('ek_tarif_error-msg_text2').replace('\\credit_costumer', `${displayPrice(totalBalance)} €`);
  };

  const getPrice = (price) => `${price} €`.replace('.', ',');

  const getTariffText = () => {
    t('ek_tarif_error-msg_text3')
      .replace('\\tariff_name_here', bookOption?.name)
      .replace('\\option_price_here', `${bookOption?.price} €`.replace('.', ','));
  };

  // Hooks
  useEffect(() => {
    if (optionId && bookableOptions.length > 0) {
      const findOption = bookableOptions.find(
        ({ id }) => parseInt(id, 10) === parseInt(optionId, 10)
      );
      if (findOption) {
        setBookOption(findOption);
      }
    }
    // else if (optionId && bookableOptions.length > 0) {
    //   const findOption = bookableOptions.find(
    //     ({ id }) => parseInt(id, 10) === parseInt(optionId, 10)
    //   );
    //   if (findOption) {
    //     setBookOption(findOption);
    //   }
    // }
  }, [bookableOptions, optionId]);

  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <>
      <div className="container">
        <MetaTitle
          title={t('ek_book_data_meta_title')}
          description={t('ek_book_data_meta_description')}
          keywords={t('ek_book_data_meta_keywords')}
        />
        <div className="row">
          <div className="col-md-12 col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.BOOKABLE_DATA}>{t('ek_bc_book_data')}</BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_book_data_options_h') }} />
            <br />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5">
            <ActiveTariffSection />
          </div>
          <div className="col-lg-7">
            <Card customClass={appCustomCardClass.OPTION_CARD}>
              <br />
              <p dangerouslySetInnerHTML={{ __html: t('ek_tarif_options_order_h2') }} />
              <p className="copytext-medium">{bookOption?.name}</p>

              <div className="offer-price mb-2">
                <h6 className="d-inline-block">{getPrice(bookOption?.price)}</h6>
                {' / '}
                <span>
                  {bookOption?.duration} {bookOption?.durationUnit}
                </span>
              </div>
              <Formik
                initialValues={{
                  terms: false
                }}
                enableReinitialize
                validationSchema={termsValidations}
                onSubmit={onSubmit}
              >
                {({ values: { terms }, handleChange, errors, touched, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    <Switch
                      className="py-4"
                      label={t('ek_tarif_options_order_text1')}
                      id="terms"
                      name="terms"
                      value={terms}
                      onChange={() => {
                        handleChange({
                          target: {
                            id: 'terms',
                            name: 'terms',
                            value: !terms
                          }
                        });
                      }}
                      isInvalid={!!touched.terms && !!errors.terms}
                      errors={errors.terms}
                    />
                    <Button buttonClass={appButtonType.PRIMARY} type="submit" disabled={!terms}>
                      {t('ek_tarif_options_order_button')}
                    </Button>
                  </Form>
                )}
              </Formik>
              <br />
            </Card>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={bookOptionSuccess} fullscreen>
        <div className="justify-content-center">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h4
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_tarif_success-msg_h2') }}
          />
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: t('ek_tarif_success-msg_text2').replace('\\booked_option', bookOption?.name)
            }}
          />
          <Button buttonClass={appButtonType.PRIMARY} type="button" onClick={onSuccessModalClick}>
            {t('ek_tarif_back-overview')}
          </Button>
        </div>
      </FullScreenModal>

      <FullScreenModal isOpen={bookOptionFailure} fullscreen>
        <div className="justify-content-center">
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <h4
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_tarif_error-msg_h1') }}
          />
          <div className="text-center" dangerouslySetInnerHTML={{ __html: getTariffText() }} />
          <div className="text-center" dangerouslySetInnerHTML={{ __html: getCreditText() }} />
          <Button buttonClass={appButtonType.PRIMARY} type="button" onClick={onFailureModalClick}>
            {t('ek_tarif_top-up-button')}
          </Button>
          <Button buttonClass={appButtonType.LINK} type="button" onClick={onFailureModalBack}>
            {t('ek_tarif_later-button2')}
          </Button>
        </div>
      </FullScreenModal>
    </>
  );
}

export default BookOption;
