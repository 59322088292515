import { ThemeProvider } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './theme.scss';
// import { createTheme } from '@mui/material/styles';

// export const appTheme = createTheme({
//   palette: {
//     type: 'light',
//     primary: {
//       main: '#3f51b5'
//     },
//     secondary: {
//       main: '#f50057'
//     }
//   }
// });

// TODO: extend
export const appTheme = {};
export function WebThemeProvider({ children }) {
  return (
    <>
      <ThemeProvider>{children}</ThemeProvider>
    </>
  );
}
WebThemeProvider.propTypes = {
  children: PropTypes.node.isRequired
};
export default WebThemeProvider;
