import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import OTPInput from 'otp-input-react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useActivation } from '@context/MobileOne';
import { Button, DropDown, Switch, Text } from '@core/Inputs';
import { appButtonType, appTextFieldLabelClass } from '@utils/globalConstant';
import { FullScreenModal, Modal } from '@core/Utils';
import ReloadIcon from '@theme/assets/img/Reload.svg';
import errorIcon from '@theme/assets/img/error.svg';
import passwortAngeFordert from '@theme/assets/img/passwort_angefordert.svg';
import { DownloadMNPDoc } from './DownloadMNPDoc';

import './style.scss';

export function PhoneNumberActivation() {
  // States
  const [mnpAlert, setMnpAlert] = useState(false);
  const [isMnpChecked, setIsMnpChecked] = useState(false);

  // Context
  const {
    isLoading,
    phoneNumberActivationForm,
    phoneNumberValidations,
    phoneNumberSubmit,
    phoneNumberValidationsWithMNP,
    PhoneNumberCustomValidation,
    phoneNumberActivationFormSubmit,
    simProvider,
    validationSchemaEmail,
    validationSchemaCode,
    verifyEmailCode,
    onResendCode,
    verifyEmail,
    // isSimInvalid,
    // setIsSimInvalid,
    emailModal,
    emailCodeError,
    setEmailCodeError
  } = useActivation();
  const [selectedDropDown, setSelectedDropDown] = useState({});
  const { t } = useStaticContent();

  // Function
  const handleMnpAlert = () => {
    setMnpAlert(false);
  };

  const mnpClicked = () => {
    setIsMnpChecked(true);
    setMnpAlert(false);
  };

  const onChangeSwitch = () => {
    if (isMnpChecked) {
      setIsMnpChecked(!isMnpChecked);
    } else {
      setMnpAlert(!isMnpChecked);
    }
  };

  // Hooks
  // useEffect(() => {
  //   if (isSimInvalid) {
  //     setTimeout(() => setIsSimInvalid(false), 6000);
  //   }
  // }, [isSimInvalid]);

  return (
    <div className="container">
      <div className="card activation-card mx-auto">
        {/* {isSimInvalid && (
          <div
            className="alert alert-danger text-center"
            role="alert"
            dangerouslySetInnerHTML={{ __html: t('ek_web_login_error-msg') }}
          />
        )} */}

        <div className="card-body">
          {phoneNumberActivationForm.msisdn === '' && (
            <Formik
              initialValues={phoneNumberActivationForm}
              validationSchema={phoneNumberValidations}
              validate={PhoneNumberCustomValidation}
              onSubmit={phoneNumberSubmit}
            >
              {({
                values: { msisdn, puk },
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                errors
              }) => (
                <Form onSubmit={handleSubmit}>
                  <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_nr_h1') }} />
                  <div className="activation-form d-flex justify-content-between flex-column">
                    <div className="form-group">
                      <Text
                        type="text"
                        labelCustomClass={appTextFieldLabelClass.V1}
                        label={t('ek_ap_activation_nr_input')}
                        id="msisdn"
                        name="msisdn"
                        onChange={handleChange}
                        value={msisdn}
                        placeholder={t('ek_ap_personal_nr_input')}
                        isValid={touched.msisdn && !errors.msisdn}
                        isInvalid={!!touched.msisdn && !!errors.msisdn}
                        onBlur={handleBlur}
                        error={touched.msisdn && errors.msisdn ? errors.msisdn : null}
                      />
                      <div className="pt-15">
                        <Text
                          type="text"
                          labelCustomClass={appTextFieldLabelClass.V1}
                          label={t('ek_ap_activation_puk_input')}
                          toolTip={t('ek_ap_activation_puk_info')}
                          id="puk"
                          name="puk"
                          onChange={handleChange}
                          value={puk}
                          placeholder={t('ek_input_placehoder_PUK')}
                          isValid={touched.puk && !errors.puk}
                          isInvalid={!!touched.puk && !!errors.puk}
                          onBlur={handleBlur}
                          error={touched.puk && errors.puk ? errors.puk : null}
                          isErrorIcon
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <Button
                        isIcon
                        icon={<i className="bi bi-arrow-right" />}
                        variant="primary"
                        type="submit"
                        isLoading={isLoading}
                      >
                        {t('ek_ap_next_button')}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {phoneNumberActivationForm.email === '' && phoneNumberActivationForm.msisdn !== '' && (
            <Formik
              initialValues={{ email: '' }}
              validationSchema={validationSchemaEmail}
              onSubmit={verifyEmail}
            >
              {({ values: { email }, handleBlur, handleChange, handleSubmit, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_email_h1') }} />
                  <div className="activation-form d-flex justify-content-between flex-column mt-5">
                    <div className="form-group">
                      <Text
                        type="text"
                        labelCustomClass={appTextFieldLabelClass.V1}
                        label={t('ek_ap_personal_email1')}
                        id="email"
                        name="email"
                        onChange={handleChange}
                        value={email}
                        placeholder={t('ek_ap_personal_email1_input')}
                        isValid={touched.email && !errors.email}
                        isInvalid={!!touched.email && !!errors.email}
                        onBlur={handleBlur}
                        error={touched.email && errors.email ? errors.email : null}
                      />
                      <div className="email-text pt-5 mt-3">
                        <p className="copytext-semibold mb-0">{t('ek_ap_email_text')}</p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-end">
                      <Button
                        buttonClass={appButtonType.PRIMARY}
                        type="submit"
                        isLoading={isLoading}
                      >
                        {t('ek_ap_overview_confirm_button1')}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {phoneNumberActivationForm.email !== '' && phoneNumberActivationForm.emailCode === '' && (
            <Formik
              initialValues={{ emailCode: '' }}
              validationSchema={validationSchemaCode}
              onSubmit={verifyEmailCode}
            >
              {({ values: { emailCode }, handleChange, handleSubmit, touched, errors }) => (
                <Form onSubmit={handleSubmit}>
                  <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_email_h1') }} />
                  <p className="copytext-semibold mb-0 pt-5 pb-5">
                    {t('ek_ap_activation_email_code_text1')}
                  </p>
                  <div className="email-varification-password">
                    <div className="d-flex justify-content-center">
                      <div>
                        <OTPInput
                          value={emailCode}
                          onChange={(e) => {
                            setEmailCodeError(false);
                            handleChange({
                              target: {
                                id: 'emailCode',
                                name: 'emailCode',
                                value: e
                              }
                            });
                          }}
                          inputClassName={
                            (!!touched.emailCode && !!errors.emailCode) || emailCodeError
                              ? 'is-invalid'
                              : 'is-valid'
                          }
                          autoFocus
                          OTPLength={4}
                          otpType="number"
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                  {touched.emailCode && errors.emailCode && (
                    <div className="d-block invalid-feedback text-center">
                      <img className="error-icon" src={errorIcon} alt="error_icon" />
                      {errors.emailCode}
                    </div>
                  )}
                  {emailCodeError && (
                    <div className="d-block invalid-feedback text-center">
                      <img className="error-icon" src={errorIcon} alt="error_icon" />
                      {t('ek_ap_activation_code_fail')}
                    </div>
                  )}
                  <div className="d-block text-center pt-5 pb-4 mb-2">
                    <Button
                      buttonClass={appButtonType.LINK}
                      type="button"
                      className="Copytext-Medium"
                      onClick={onResendCode}
                    >
                      <img className="me-2 reload-icon" src={ReloadIcon} alt="ReloadIcon" />{' '}
                      <span
                        dangerouslySetInnerHTML={{ __html: t('ek_ap_email_resend_code_text') }}
                      />
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Button buttonClass={appButtonType.PRIMARY} type="submit" isLoading={isLoading}>
                      {t('ek_ap_overview_confirm_button1')}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          )}
          {phoneNumberActivationForm.email !== '' && phoneNumberActivationForm.emailCode !== '' && (
            <Formik
              initialValues={phoneNumberActivationForm}
              validationSchema={isMnpChecked ? phoneNumberValidationsWithMNP : ''}
              onSubmit={(data) => {
                phoneNumberActivationFormSubmit({ ...data, mnp: isMnpChecked });
              }}
            >
              {({
                values: { oldNumberPrefix, oldNumber },
                handleBlur,
                handleChange,
                errors,
                touched,
                handleSubmit
              }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="activation-toggle-step">
                    <h4 dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_nr_h1') }} />
                    <p className="pt-5 mt-2 copytext-medium">
                      {t('ek_ap_activation_mnp_text').replace(
                        '{SIM}',
                        phoneNumberActivationForm?.msisdn
                      )}
                    </p>
                    <div className="activation-form activation-form-toggle d-flex justify-content-between flex-column pt-5 mt-5">
                      <div aria-hidden role="button" onClick={onChangeSwitch}>
                        <Switch
                          reverse
                          label={t('ek_ap_nr-portability_h1')}
                          id="mnp"
                          name="mnp"
                          onChange={onChangeSwitch}
                          value={isMnpChecked}
                          checked={isMnpChecked}
                        />
                      </div>
                      {isMnpChecked ? (
                        <>
                          <div className="d-flex justify-content-between custom_dropdown mt-3">
                            <div className="w-40">
                              <label className="form-label" htmlFor="currentProvider">
                                {t('ek_ap_nr-portability_input1')}
                              </label>
                            </div>
                            <div className="toggle-dropdown">
                              <DropDown
                                label={t('ek_ap_personal_id_input')}
                                id="currentProvider"
                                name="currentProvider"
                                apiData={simProvider}
                                setSelectedItem={(e) => {
                                  setSelectedDropDown(e);
                                  handleChange({
                                    target: {
                                      id: 'currentProvider',
                                      name: 'currentProvider',
                                      value: e.id
                                    }
                                  });
                                }}
                                selectedItem={selectedDropDown}
                                error={
                                  touched.currentProvider && errors.currentProvider
                                    ? errors.currentProvider
                                    : null
                                }
                              />
                            </div>
                          </div>
                          <div>
                            <Text
                              type="text"
                              labelCustomClass={appTextFieldLabelClass.V1}
                              label={t('ek_ap_nr-portability_input2')}
                              id="oldNumberPrefix"
                              name="oldNumberPrefix"
                              placeholder={t('ek_ap_personal_prefix-alternative_placehoder')}
                              value={oldNumberPrefix}
                              onChange={handleChange}
                              isInvalid={!!touched.oldNumberPrefix && !!errors.oldNumberPrefix}
                              onBlur={handleBlur}
                              error={
                                touched.oldNumberPrefix && errors.oldNumberPrefix
                                  ? errors.oldNumberPrefix
                                  : null
                              }
                            />
                          </div>
                          <div>
                            <Text
                              type="text"
                              labelCustomClass={appTextFieldLabelClass.V1}
                              label={t('ek_ap_nr-portability_input3')}
                              id="oldNumber"
                              name="oldNumber"
                              onChange={handleChange}
                              value={oldNumber}
                              placeholder={t('ek_ap_nr-portability_input3_placehoder')}
                              isValid={touched.oldNumber && !errors.oldNumber}
                              isInvalid={!!touched.oldNumber && !!errors.oldNumber}
                              onBlur={handleBlur}
                              error={
                                touched.oldNumber && errors.oldNumber ? errors.oldNumber : null
                              }
                            />
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="d-flex justify-content-end mt-4 pt-3">
                        <Button
                          buttonClass={appButtonType.PRIMARY}
                          isIcon
                          icon={<i className="bi bi-arrow-right" />}
                          type="submit"
                          isLoading={isLoading}
                        >
                          {t('ek_ap_next_button')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </div>
      {phoneNumberActivationForm.email !== '' && phoneNumberActivationForm.emailCode === '' && (
        <div className="email-contact text-center pt-4 mt-2">
          {' '}
          <div
            className="copytext"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_activation_email_code_text2') }}
          />{' '}
        </div>
      )}

      <FullScreenModal isOpen={emailModal} fullscreen>
        <div className="emailmodal">
          <div className="d-flex justify-content-center text-center align-item-center vh-30 flex-column">
            <img className="mx-auto successicon" src={passwortAngeFordert} alt="successIcon" />
            <h2
              className="text-center email-text mt-3"
              dangerouslySetInnerHTML={{ __html: t('ek_ap_email-popup_text') }}
            />
          </div>
        </div>
      </FullScreenModal>

      <Modal isOpen={mnpAlert} onClose={handleMnpAlert} isCloseButton>
        <DownloadMNPDoc
          formSubmit={phoneNumberActivationFormSubmit}
          formData={phoneNumberActivationForm}
          onMnpClicked={mnpClicked}
        />
      </Modal>
    </div>
  );
}

export default PhoneNumberActivation;
