import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

const LayoutContext = createContext();

export const LayoutProvider = function ({ children }) {
  // Constants

  // States
  const [isLoading, setIsLoading] = useState(true);
  const [screenSize, setScreenSize] = useState(
    window && window.innerWidth ? window.innerWidth : 1080
  );

  // Context
  const { onStaticContentCall, onStaticKeyValuePairCall } = useStaticContent();

  const getStaticContent = async () => {
    try {
      setIsLoading(true);
      await onStaticKeyValuePairCall(
        JSON.stringify({
          platform: 'web',
          lang: 'de',
          client: 'edeka'
        })
      );
      await onStaticContentCall({
        platform: 'web',
        lang: 'de',
        client: 'edeka'
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  // Hooks
  useEffect(() => {
    getStaticContent();

    if (window) {
      window.addEventListener('resize', () => {
        setScreenSize(window.innerWidth);
      });
    }
  }, []);

  // We wrap it in a useMemo for performance reasons.
  const contextPayload = useMemo(
    () => ({
      // States
      screenSize,
      isLoading,
      // Functions
      getStaticContent
    }),
    [
      // States
      screenSize,
      isLoading,
      // Functions
      getStaticContent
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <LayoutContext.Provider value={contextPayload}>{children}</LayoutContext.Provider>;
};

LayoutProvider.propTypes = {
  children: PropTypes.node
};

LayoutProvider.defaultProps = {
  children: null
};

export default LayoutProvider;

// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useLayout = () => useContext(LayoutContext);
