import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Radio } from '@core/Inputs';
import { Modal } from '@core/Utils';
import './style.scss';

export function Terms({
  termsAndConditions,
  thirdParty,
  handleChange,
  handleBlur,
  errors,
  touched,
  termsInfoPdfs,
  privacyInfoPdfs
}) {
  // Constants
  const filteredData = termsInfoPdfs ? termsInfoPdfs.filter((item) => item.id !== '90') : '';
  const agbPdf = termsInfoPdfs ? termsInfoPdfs.filter((item) => item.id === '90') : '';

  // States
  const [openModal, setOpenModal] = useState(false);
  const [modalValue, setModalValue] = useState('');
  const [agbPdfData, setAgbPdfData] = useState({});

  // Contexts
  const { t } = useStaticContent();
  const [thirdPartyRadio, setThirdPartyRadio] = useState(thirdParty);
  const [tncRadio, setTncRadio] = useState(termsAndConditions);

  // Functions
  const onInfoClick = () => {
    setOpenModal(true);
    setModalValue('info_modal');
  };

  const onAgbClick = () => {
    setOpenModal(true);
    setModalValue('agb_modal');
  };

  const onContractClick = () => {
    setOpenModal(true);
    setModalValue('contract_modal');
  };

  const onPrivacyClick = () => {
    setOpenModal(true);
    setModalValue('privacy_modal');
  };

  // Hooks
  useEffect(() => {
    setAgbPdfData(agbPdf[0]);
  }, [agbPdf]);

  return (
    <>
      <div className="mt-5 pt-4">
        <div className="row">
          <div className="terms-radio mb-2">
            <div className="d-flex">
              <Radio
                id="termsAndConditions"
                name="termsAndConditions"
                isDefaultChecked={termsAndConditions}
                onClick={(e) => {
                  e.target.checked = !tncRadio;
                  setTncRadio(!tncRadio);
                  handleChange({
                    target: {
                      id: 'termsAndConditions',
                      name: 'termsAndConditions',
                      value: !tncRadio
                    }
                  });
                }}
                isValid={touched.termsAndConditions && !errors.termsAndConditions}
                isInvalid={!!touched.termsAndConditions && !!errors.termsAndConditions}
                onBlur={handleBlur}
                // error={
                //   touched.termsAndConditions && errors.termsAndConditions
                //     ? errors.termsAndConditions
                //     : null
                // }
              />

              <div
                aria-hidden
                role="button"
                onClick={onAgbClick}
                dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_consent_text1') }}
              />
            </div>
            <span className="text-danger">
              {touched.termsAndConditions && errors.termsAndConditions
                ? errors.termsAndConditions
                : null}
            </span>
          </div>
          <div className="d-flex">
            <Radio
              // label={t('ek_ap_overview_text2')}
              id="thirdParty"
              name="thirdParty"
              isDefaultChecked={thirdParty}
              onClick={(e) => {
                e.target.checked = !thirdPartyRadio;
                setThirdPartyRadio(!thirdPartyRadio);
                handleChange({
                  target: {
                    id: 'thirdParty',
                    name: 'thirdParty',
                    value: !thirdPartyRadio
                  }
                });
              }}
              isValid={touched.thirdParty && !errors.thirdParty}
              isInvalid={!!touched.thirdParty && !!errors.thirdParty}
              onBlur={handleBlur}
              error={touched.thirdParty && errors.thirdParty ? errors.thirdParty : null}
            />

            <div
              aria-hidden
              role="button"
              onClick={onInfoClick}
              dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_consent_text2') }}
            />
          </div>
          <div className="d-flex ps-5">
            <p>
              <span>{t('ek_ap_overview_consent_text3')}</span>
              <span aria-hidden role="button" onClick={onPrivacyClick}>
                {' '}
                <u>{t('ek_ap_overview_consent_text3a')}</u>{' '}
              </span>
              <span>{t('ek_ap_overview_consent_text3b')}</span>
            </p>
          </div>

          <div className="mb-2 ps-5" aria-hidden role="button" onClick={onContractClick}>
            <p>
              <u dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_text3') }} />
            </p>
          </div>
        </div>
      </div>

      <Modal
        isOpen={openModal}
        isCloseButton
        onClose={() => {
          setOpenModal(false);
          setModalValue('');
        }}
      >
        <div>
          {modalValue && modalValue === 'agb_modal' ? (
            <p>
              <a
                style={{ color: 'white' }}
                href={agbPdfData?.media_url}
                target="_blank"
                rel="noreferrer"
              >
                {agbPdfData?.name}
              </a>
            </p>
          ) : (
            ''
          )}
          {modalValue && modalValue === 'info_modal' ? (
            <div
              className="infoModel"
              dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_consent_text2_popup') }}
            />
          ) : (
            ''
          )}
          {modalValue && modalValue === 'contract_modal'
            ? filteredData.map((item, index) => (
                <div className="mt-3 mb-3 pt-2" key={`info-tag-${index}`}>
                  <a
                    className="fussnote-link"
                    style={{ color: 'white' }}
                    href={item?.media_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.name}
                  </a>
                </div>
              ))
            : ''}
          {modalValue && modalValue === 'privacy_modal'
            ? privacyInfoPdfs.map((item, index) => (
                <div className="mt-3 mb-3 pt-2" key={`info-tag-${index}`}>
                  <a
                    className="fussnote-link"
                    style={{ color: 'white' }}
                    href={item?.redirectionURL ? item?.redirectionURL : item?.media_url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item?.name}
                  </a>
                </div>
              ))
            : ''}
        </div>
      </Modal>
    </>
  );
}

Terms.propTypes = {
  termsAndConditions: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  thirdParty: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  touched: PropTypes.shape({}),
  errors: PropTypes.shape({})
};
Terms.defaultProps = {
  handleChange: () => {},
  handleBlur: () => {},
  touched: () => {},
  errors: {}
};
export default Terms;
