/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { Tooltip as Btooltip } from 'bootstrap';
import './style.scss';

export function Tooltip({ title, toggle = 'tooltip', children, placement = 'right' }) {
  useEffect(() => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map((tooltipTriggerEl) => new Btooltip(tooltipTriggerEl));
  }, []);

  return (
    <div
      className="d-inline-block"
      type="button"
      data-bs-html="true"
      data-bs-toggle={toggle}
      data-bs-title={title}
      data-bs-placement={placement}
    >
      {children}
    </div>
  );
}

export default Tooltip;
