import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Button } from '@core/Inputs';
import { appButtonType, appRoute } from '@utils/globalConstant';
import { Tooltip } from '@core/Utils/Tooltip';
import tooltipIcon from '@theme/assets/img/tooltip.svg';
import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import ArrowRightSmall from '@theme/assets/img/arrow_right_small.svg';

import { ChargeHeader } from '../ChargeHeader';
// import { ChargeHistory } from '../ChargeHistory';

import './style.scss';

export function RechargeCredit() {
  // Constant

  // States

  // Context
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { topupOnLoad, setAutoTopupMoreInfo } = useOnlineTopUp();

  // Hooks
  useEffect(() => {
    topupOnLoad();
  }, []);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_recharge_credit_meta_title')}
        description={t('ek_recharge_credit_meta_description')}
        keywords={t('ek_recharge_credit_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.CHARGES}>{t('ek_bc_charging')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_charging_options_h') }} />
          <br />
        </div>
      </div>
      <div className="pt-4 mt-2">
        <div className="row">
          <div className="card auto-topup recharge-credit mx-auto p-0">
            <div className="card-body">
              <ChargeHeader />
              <div className="col-lg-12">
                <br />
                <div>
                  <h3>{t('ek_top-up_h2')}</h3>
                </div>
                <br />
                <div>
                  <div className="welcome-badge listing-badge d-flex">
                    <img src={EmpfehLung} alt="Icon" />{' '}
                    <h6
                      className="mb-0 px-2"
                      dangerouslySetInnerHTML={{ __html: t('ek_top-up_recommendation') }}
                    />
                  </div>
                </div>
                <br />
                <div className="d-flex justify-content-between">
                  <div
                    className="copytext-medium"
                    dangerouslySetInnerHTML={{ __html: t('ek_top-up_text1') }}
                  />
                  <Button
                    buttonClass={appButtonType.LINK}
                    type="button"
                    className="copytext-medium"
                    onClick={() => {
                      setAutoTopupMoreInfo(false);
                      navigate(appRoute.SET_AUTO_TOPUP);
                    }}>
                    {t('ek_top-up_autotop-up')}
                    <img className="ps-2" src={ArrowRightSmall} alt="rightarrow-icon" />
                  </Button>
                </div>

                <hr />

                <div className="d-flex justify-content-between align-items-center">
                  <div
                    className="copytext-medium"
                    dangerouslySetInnerHTML={{ __html: t('ek_top-up_text2') }}
                  />
                  <Button
                    buttonClass={appButtonType.LINK}
                    className="copytext-medium"
                    type="button"
                    onClick={() => {
                      navigate(appRoute.SET_DIRECT_TOPUP);
                    }}>
                    {t('ek_top-up_immediate')}
                    <img className="ps-2" src={ArrowRightSmall} alt="rightarrow-icon" />
                  </Button>
                </div>

                <hr />

                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <span
                      className="copytext-medium d-inline-block col-lg-8"
                      dangerouslySetInnerHTML={{ __html: t('ek_top-up_text3') }}
                    />
                    <Tooltip title={t('ek_top-up_voucher_info')}>
                      <img
                        className="charging-tool-tip tool-tip-icon"
                        src={tooltipIcon}
                        alt="tooltip_icon"
                      />
                    </Tooltip>
                  </div>
                  <Button
                    buttonClass={appButtonType.LINK}
                    type="button"
                    onClick={() => {
                      navigate(appRoute.RECHARGE_VOUCHER);
                    }}>
                    {t('ek_top-up_voucher')}
                    <img className="ps-2" src={ArrowRightSmall} alt="rightarrow-icon" />
                  </Button>
                </div>

                {/* <ChargeHistory /> */}
                <br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RechargeCredit;

export * from './SetAutoTopup';
export * from './SetDirectTopup';
