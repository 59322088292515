import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useCustomer } from '@context/MobileOne';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appButtonType, appRoute, appTextInputClass } from '@utils/globalConstant';
import { Button, Text } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import PDF from '@theme/assets/img/pdf_download.svg';
import { ProfileInfo } from './ProfileInfo';
import './style.scss';

export function Profile() {
  // Constants

  // States

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { isUserLoggedIn } = useAuth();
  const {
    customerData: { hotlinePassword = '' },
    personalData: { email = {} },
    personalData: { alternatePhoneNumber = {} },
    getEvnPdf,
    evnPdfs,
    evnData,
    evnPdfData,
    getEvnPdfData
  } = useCustomer();
  // Functions

  const downloadEvnPdf = (pdfContent) => {
    const linkSource = `data:application/pdf;base64,${pdfContent.data}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = pdfContent.fileName;
    downloadLink.click();
    downloadLink.remove();
  };

  // Hooks

  useEffect(() => {
    if (isUserLoggedIn) {
      getEvnPdfData();
      getEvnPdf();
    }
  }, []);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_user_profile_meta_title')}
        description={t('ek_user_profile_meta_description')}
        keywords={t('ek_user_profile_meta_keywords')}
      />

      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_account_h')}</h4>
          <br />
        </div>
      </div>
      <div className="mt-4 pt-2">
        <div className="user-profile">
          <div className="row">
            <div className="col-lg-3">
              <ProfileInfo />
            </div>
            <div className="col-lg-7 offset-md-2">
              <div className="user-profile-card ms-2 ps-2">
                <div className="card mb-4">
                  <div className="card-body py-3">
                    <div className="d-flex justify-content-between">
                      <p className="copytext-medium mb-1 pb-1">{t('ek_account_h1')}</p>
                      <Button
                        buttonClass={appButtonType.LINK}
                        onClick={() => {
                          navigate(appRoute.CHANGE_USER_INFO);
                        }}
                      >
                        <i className="bi bi-pencil p-0" />
                      </Button>
                    </div>
                    <p className="text-black copytext mb-0 profile-email">{email?.emailAddress}</p>

                    <span className="text-black copytext profile-email">
                      {alternatePhoneNumber?.prefix} {alternatePhoneNumber?.number}
                    </span>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body py-3">
                    <div className="d-flex justify-content-between">
                      <p className="copytext-medium mb-1 pb-1">{t('ek_account_h2')}</p>
                      <Button
                        buttonClass={appButtonType.LINK}
                        onClick={() => {
                          navigate(appRoute.CHANGE_PASSWORD);
                        }}
                      >
                        <i className="bi bi-pencil p-0" />
                      </Button>
                    </div>
                  </div>
                </div>

                <div className="card mb-4">
                  <div className="card-body py-3 hotline-card-body">
                    <div className="d-flex justify-content-between">
                      <p className="copytext-medium mb-1 pb-1">{t('ek_account_h3')}</p>
                      <Button
                        buttonClass={appButtonType.LINK}
                        onClick={() => {
                          navigate(appRoute.CHANGE_HOTLINE);
                        }}
                      >
                        <i className="bi bi-pencil p-0" />
                      </Button>
                    </div>

                    <div className="col-lg-12">
                      <Text
                        type="password"
                        inputCustomClass={appTextInputClass.V2}
                        id="pswrdForHtLine"
                        name="pswrdForHtLine"
                        value={hotlinePassword}
                        isDisabled
                      />
                    </div>
                  </div>
                </div>

                <div className="card mb-4 item-bill-card">
                  <div className="card-body py-3">
                    <p className="copytext-medium mb-1 pb-1">{t('ek_account_evn_h')}</p>
                    {evnData.evnAllowed ? (
                      <ul className="list-unstyled">
                        {evnPdfData.length > 0 ? (
                          evnPdfData.map((pdf, index) => (
                            <div
                              className="d-flex justify-content-between"
                              key={`evn-pdf-${index}`}
                              role="button"
                              onClick={() => downloadEvnPdf(pdf)}
                            >
                              <i className="mt-1">
                                <img src={PDF} alt="pdf-icon" />
                              </i>
                              <li className="copytext ms-2">{pdf?.fileName}</li>
                            </div>
                          ))
                        ) : (
                          <p
                            className="copytext"
                            dangerouslySetInnerHTML={{ __html: t('ek_account_evn_notavailable') }}
                          />
                        )}
                      </ul>
                    ) : (
                      <>
                        <p
                          className="copytext"
                          dangerouslySetInnerHTML={{ __html: t('ek_account_evn_text1') }}
                        />
                        <p
                          className="copytext"
                          dangerouslySetInnerHTML={{ __html: t('ek_account_evn_text2') }}
                        />
                        <a href={evnPdfs?.media_url} target="_blank" rel="noreferrer">
                          {t('ek_account_evn_application')}
                        </a>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Profile;

export * from './ProfileInfo';
export * from './ChangeEmail';
export * from './PasswodChange';
export * from './ChangeHotlinePassword';
