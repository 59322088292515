/* eslint-disable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { appButtonType, appTariffs, appUsageMeasurementsUnits } from '@utils/globalConstant';
import { useCustomer } from '@context/MobileOne';
import { Button } from '@core/Inputs';

import './index.scss';

/**
 * Individual Tariff Product
 * TODO: Extend it with different type ACTIVE, INPROGRESS & RECOMMENDED
 * @param id
 * @param name
 * @param title
 * @param description
 * @param isSelected
 * @param onSelect
 * @returns
 */
export function TariffProduct({
  id,
  name,
  // eslint-disable-next-line no-unused-vars
  isSelected,
  isActivationDetail,
  duration,
  durationUnit,
  description,
  price,
  starterPackPrice,
  additionalInfo: {
    activationCopy = '',
    primaryColor = '#fffff',
    ribbonCopy = '',
    image_ref = '',
    bullets = []
  } = {},
  onSelect,
  // eslint-disable-next-line no-unused-vars
  isChangeTariffAllowed,
  showTariffStatus,
  isOverView,
  onClick,
  isActivationOverView,
  onButtonClick,
  customClass
}) {
  const [tariffBullets, setTariffBullets] = useState([]);
  const [activationBullets, setActivationBullets] = useState([]);
  // Context
  const { t } = useStaticContent();
  const { mediaImages } = useCustomer();

  const tariffImage = mediaImages.find((img) => img.image_ref === image_ref);

  // Functions
  const getButtonText = () => t('ek_ap_tarif_choose_button').replace('{tariff}', name);

  const getRibbonBg = () => {
    if (id === appTariffs.KOMBI_M) {
      return '#FFFF12';
    } else if (id === appTariffs.KOMBI_S) {
      return '#75DD7D';
    } else {
      return primaryColor;
    }
  };

  useEffect(() => {
    // eslint-disable-next-line prefer-const
    let tBullets = [];

    if (bullets) {
      if (isActivationOverView) {
        tBullets.push(bullets[0]);
        tBullets.push(bullets[1]);
        setActivationBullets(tBullets);
      } else {
        tBullets.push(bullets[0]);
        tBullets.push(bullets[1]);
        // tBullets.push(bullets[2]);
        setTariffBullets(tBullets);
      }
    }
  }, [bullets]);

  return (
    <>
      <div
        id={`tariff${id}`}
        onClick={() => {
          if (isOverView) {
            onClick(id, showTariffStatus);
          }
          //  else if (isActivationOverView) {
          //   onClick(id, name, primaryColor);
          // }
          else {
            onSelect(id);
          }
        }}
      >
        <div
          className={`card tarrif-card tarrif-listing${isActivationDetail ? ' tarrif-detail' : ''}${
            isActivationOverView && id === 1803 ? ' talk-small' : ''
          } ${customClass}`}
          border="primary"
        >
          <div
            className="card-body"
            style={{
              backgroundImage: `url(${
                tariffImage?.responsive_image ? tariffImage?.responsive_image['1x'] : ''
              })`,
              opacity: `${showTariffStatus ? '0.5' : '1'}`
            }}
          >
            <div>
              <div className="justify-content-center p-0">
                <h2
                  style={{ color: primaryColor }}
                  className="card-title text-center text-uppercase"
                >
                  {name}
                </h2>
              </div>
              {(isActivationOverView || isOverView) &&
                (showTariffStatus ? (
                  <div className="active-plan-ribbon">
                    <span
                      className="text-uppercase d-block text-center"
                      style={{
                        background: getRibbonBg(),
                        color: id === appTariffs.KOMBI_M ? '#174570' : '#fff'
                      }}
                    >
                      {showTariffStatus}
                    </span>
                  </div>
                ) : (
                  <div className="active-plan-ribbon">
                    <span
                      className="text-uppercase d-block text-center"
                      // style={{
                      //   background:
                      //     id === appTariffs.KOMBI_M ||
                      //     id === appTariffs.KOMBI_L ||
                      //     id === appTariffs.KOMBI_XL ||
                      //     id === appTariffs.JAHRESTARIF_PREMIUM
                      //       ? '#FFFF12'
                      //       : primaryColor,
                      //   color:
                      //     id === appTariffs.KOMBI_M ||
                      //     id === appTariffs.KOMBI_L ||
                      //     id === appTariffs.KOMBI_XL ||
                      //     id === appTariffs.JAHRESTARIF_PREMIUM
                      //       ? '#174570'
                      //       : '#fff'
                      // }}
                      style={{
                        backgroundColor: primaryColor
                      }}
                    >
                      {ribbonCopy}
                    </span>
                  </div>
                ))}
            </div>

            <div className="pt-3 pb-1">
              {/* eslint-disable-next-line react/no-array-index-key */}{' '}
              {/* eslint-disable-next-line react/no-danger */}
              <ul>
                {isActivationOverView
                  ? activationBullets.map(({ text }) => (
                      <li key={`bullets-${text}`}>
                        <i style={{ color: primaryColor }} className="bi bi-chevron-right" />
                        <div
                          dangerouslySetInnerHTML={{ __html: text }}
                          key={`bullet-item-${id}-${text}`}
                        />
                      </li>
                    ))
                  : isOverView
                  ? tariffBullets.map(({ text }) => (
                      <li key={`bullets-${text}`}>
                        <i style={{ color: primaryColor }} className="bi bi-chevron-right" />
                        <div
                          dangerouslySetInnerHTML={{ __html: text }}
                          key={`bullet-item-${id}-${text}`}
                        />
                      </li>
                    ))
                  : bullets.map(({ text }) => (
                      <li key={`bullets-${text}`}>
                        <i style={{ color: primaryColor }} className="bi bi-chevron-right" />
                        <div
                          dangerouslySetInnerHTML={{ __html: text }}
                          key={`bullet-item-${id}-${text}`}
                        />
                      </li>
                    ))}
              </ul>
            </div>
            <hr className="my-1 p-0" />

            <div className="pt-2 pb-0">
              <div className="tarrif-total">
                <p className="mb-0">
                  {appTariffs.TALK_JAHRESTARIF_START === id && isActivationDetail ? (
                    <span>{t('ek_ap_tariff-start_price_text')}</span>
                  ) : (
                    <>
                      <span>
                        {isActivationOverView || isActivationDetail ? starterPackPrice : price}{' '}
                        &euro;
                      </span>
                      / {duration}&nbsp;{durationUnit}
                    </>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isActivationOverView && (
        <div className="mt-2 pt-4 listing-max-width mx-auto">
          {activationCopy ? (
            <p className="copytext mb-5" dangerouslySetInnerHTML={{ __html: activationCopy }} />
          ) : (
            <p className="copytext mb-5" dangerouslySetInnerHTML={{ __html: description }} />
          )}
          <Button
            buttonClass={appButtonType.PRIMARY}
            onClick={() => {
              onButtonClick(id, name, primaryColor);
            }}
          >
            {getButtonText()}
          </Button>
        </div>
      )}
    </>
  );
}
TariffProduct.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  description: PropTypes.string,
  customClass: PropTypes.string,
  additionalInfo: PropTypes.shape({}),
  isSelected: PropTypes.bool,
  isOverView: PropTypes.bool,
  isActivationOverView: PropTypes.bool,
  isActivationDetail: PropTypes.bool,
  onSelect: PropTypes.func,
  onButtonClick: PropTypes.func,
  isChangeTariffAllowed: PropTypes.bool,
  showTariffStatus: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  duration: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  durationUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  // isPausedTariff: PropTypes.bool,
};
TariffProduct.defaultProps = {
  id: 0,
  name: '',
  description: '',
  customClass: '',
  additionalInfo: {},
  isSelected: false,
  onSelect: () => {},
  onButtonClick: () => {},
  isChangeTariffAllowed: false,
  showTariffStatus: false,
  isOverView: false,
  isActivationOverView: false,
  isActivationDetail: false,
  duration: '',
  durationUnit: appUsageMeasurementsUnits.GB,
  price: 0
  // isPausedTariff: false
};
export default TariffProduct;
