import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount, useCustomer } from '@context/MobileOne';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs, Card, CardBody } from '@core/Layout';
import { appButtonType, appRoute } from '@utils/globalConstant';
import { Button, Switch } from '@core/Inputs';
import { FullScreenModal, Modal } from '@core/Utils';
import { useLayout, useLoader } from '@context/Utils';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';

export function Preferences() {
  // States
  const [openModal, setOpenModal] = useState(false);

  // Contexts
  const { t } = useStaticContent();
  const { onDOMWithProduct, isLoading: isCustomerLoading } = useCustomer();
  const navigate = useNavigate();
  const {
    preferencesInitValue,
    onChangePreferencesSubmit,
    preferenceSuccessModal,
    setPreferenceSuccessModal,
    preferenceAlert,
    isLoading: isAccountLoading
  } = useAccount();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();

  // Functions
  const onTextClick = () => {
    setOpenModal(true);
  };

  const onModalClose = () => {
    setOpenModal(false);
  };

  const onBackClick = () => {
    navigate(appRoute.USER_ACCOUNT);
  };

  // Hooks
  useEffect(() => {
    onDOMWithProduct();
  }, []);

  useEffect(() => {
    if (isAutoTopUpLoading || isStaticContentLoading || isCustomerLoading || isAccountLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isCustomerLoading, isAccountLoading, isAutoTopUpLoading, isStaticContentLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_preferences_meta_title')}
        description={t('ek_preferences_meta_description')}
        keywords={t('ek_preferences_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_settings_notifications_h') }} />
          <br />
        </div>
      </div>
      <div className="pt-2 mt-4">
        <Card customClass="mx-auto">
          <CardBody>
            <Formik
              initialValues={preferencesInitValue}
              enableReinitialize
              onSubmit={onChangePreferencesSubmit}
            >
              {({ values: { marketingMultibrand }, handleSubmit, handleChange }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="row">
                    <div aria-hidden role="button" className="col-lg-10" onClick={onTextClick}>
                      <p dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_consent_text2') }} />
                    </div>
                    <div className="col-lg-2">
                      <Switch
                        name="marketingMultibrand"
                        id="marketingMultibrand"
                        checked={marketingMultibrand}
                        onChange={() => {
                          handleChange({
                            target: {
                              id: 'marketingMultibrand',
                              name: 'marketingMultibrand',
                              value: !marketingMultibrand
                            }
                          });
                        }}
                      />
                    </div>
                    <div className="col-lg-12">
                      <div className="d-flex justify-content-between pt-4">
                        <Button
                          buttonClass={appButtonType.LINK}
                          type="button"
                          onClick={onBackClick}
                        >
                          {t('ek_change-back_button')}
                        </Button>
                        <Button buttonClass={appButtonType.PRIMARY} type="submit">
                          {t('ek_change-personal_save')}
                        </Button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </CardBody>
        </Card>
      </div>

      <Modal isOpen={openModal} onClose={onModalClose} isCloseButton>
        <div
          className="infoModel"
          dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_consent_text2_popup') }}
        />
      </Modal>

      <Modal isOpen={preferenceAlert}>
        <div>
          <h3
            className="text-center text-white mb-4 pb-2"
            dangerouslySetInnerHTML={{ __html: t('ek_settings_kek_success_text') }}
          />
          <Button
            onClick={() => {
              navigate(appRoute.USER_ACCOUNT);
            }}
          >
            {t('ek_tarif_back-overview')}
          </Button>
        </div>
      </Modal>

      <FullScreenModal isOpen={preferenceSuccessModal} fullscreen>
        <div className="d-flex align-items-center flex-column justify-content-center mx-auto mr-auto vh-100">
          <h2
            className="text-center mb-4 pb-2"
            dangerouslySetInnerHTML={{ __html: t('ek_settings_kek_success_text') }}
          />
          <Button
            onClick={() => {
              setPreferenceSuccessModal(!preferenceSuccessModal);
              navigate(appRoute.USER_ACCOUNT);
            }}
          >
            {t('ek_tarif_back-overview')}
          </Button>
        </div>
      </FullScreenModal>
    </div>
  );
}

export default Preferences;
