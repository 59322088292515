import React from 'react';

import { PassOfferProduct } from '@part/PassOffer/Product';
import { useDashBoard } from '@context/MobileOne/DashBoard';

export function PassOffer() {
  // Context
  const { passOffers } = useDashBoard();

  return (
    <>
      <div className="row">
        {passOffers.map((item) => (
          <div className="col-lg-6 py-3" key={`option-cat-${item.passCode}`}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <PassOfferProduct {...{ ...item, isBookableAllowed: true }} />
          </div>
        ))}
      </div>
    </>
  );
}

export default PassOffer;
