import React, { useEffect } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';

import { useCustomer, useDocument } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import LoyaltyStore from '@theme/assets/img/ek_app-icons-loyality-store.svg';
import LoyaltyBasket from '@theme/assets/img/ek_app-icons-loyality-basket.svg';
import LoyaltyAppStore from '@theme/assets/img/ek_app-icons-loyality-appstore.svg';
import './style.scss';

export function TrueBonus() {
  // Constants
  const { advantagesLoyaltyImgBg, advantagesLoyalityContentImg } = useDocument();
  // States

  // Contexts
  const { t } = useStaticContent();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div
      className="advanatges-bg"
      style={{
        backgroundImage: `url(${
          advantagesLoyaltyImgBg?.responsive_image
            ? advantagesLoyaltyImgBg.responsive_image['2x']
            : ''
        })`
      }}
    >
      <div className="container">
        <MetaTitle
          title={t('ek_true_bonus_meta_title')}
          description={t('ek_true_bonus_meta_description')}
          keywords={t('ek_true_bonus_meta_keywords')}
        />

        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.ADVANTAGES}>
                {t('ek_public_account_advantages')}
              </BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_advantages_no1_breadcrumb') }} />
            <br />
          </div>
        </div>

        <div className="row">
          <div className="card true-bonus-card pt-4 mt-4">
            <div className="true-bonus-center truebonus-content-main">
              <div className="col-lg-12">
                <center>
                  <div className="truebonus-content-img">
                    <img
                      src={advantagesLoyalityContentImg?.media_url_display}
                      alt="loyalty-content-img"
                    />
                  </div>

                  <p
                    className="copytext pt-4 pb-3"
                    dangerouslySetInnerHTML={{ __html: t('ek_advantages_no1_text1') }}
                  />

                  <hr />

                  <p className="copytext-medium pt-3 pb-3">{t('ek_advantages_no1_h1')}</p>
                </center>
              </div>
              <div className="col-lg-12">
                <div className="row">
                  <div className="d-flex true-bonus-bottom">
                    <div>
                      <div className="text-center pb-3">
                        <img src={LoyaltyAppStore} alt="logo" />
                      </div>
                      <p
                        className="text-center copytext"
                        dangerouslySetInnerHTML={{ __html: t('ek_advantages_no1_text4') }}
                      />
                    </div>
                    <div>
                      <div className="text-center pb-3">
                        <img src={LoyaltyStore} alt="logo" />
                      </div>
                      <p
                        className="text-center copytext"
                        dangerouslySetInnerHTML={{ __html: t('ek_advantages_no1_text5') }}
                      />
                    </div>
                    <div>
                      <div className="text-center pb-3">
                        <img src={LoyaltyBasket} alt="logo" />
                      </div>
                      <p
                        className="text-center copytext pb-4"
                        dangerouslySetInnerHTML={{ __html: t('ek_advantages_no1_text6') }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrueBonus;
