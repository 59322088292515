import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { StatusCodes, useAppConfig } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAuth } from '@dom-digital-online-media/dom-auth-sdk';
import { useAccount, useActivation, useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useLayout, useLoader } from '@context/Utils';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { Modal } from '@core/Utils';
import { Button } from '@core/Inputs';
import { appRoute, appButtonType } from '@utils/globalConstant';
import EDEKAsmartLogo from '@theme/assets/img/EDEKAsmart_logo_neg.svg';
import EDEKAsmartLogoBlue from '@theme/assets/img/EDEKAsmart_logo.svg';
import warning from '@theme/assets/img/warning.svg';
import { ClientLogin } from './ClientLogin';

import './style.scss';

export function Login() {
  // Constants

  // State
  const [isLogin, setIsLogin] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  // Context
  const navigate = useNavigate();
  const { onLogin, isUserLoggedIn } = useAuth();
  const { env } = useAppConfig();
  const { t } = useStaticContent();
  const { setIsActivationClicked, onClickBack } = useActivation();
  const { isLoading: isAccountLoading, setIsLoginSubmit } = useAccount();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { loginBgImage } = useCustomer();
  const { isLoading: isDashBoardLoading } = useDashBoard();
  const { showLoader, hideLoader } = useLoader();

  // Functions
  // Grant type password login and normal login
  const processLogin = async () => {
    if (env.REACT_APP_IS_CLIENT_LOGIN === 'true') {
      setIsLogin(true);
    } else {
      try {
        const { data: { url } = {}, status, success } = await onLogin();
        if (success && status === StatusCodes.OK && url) {
          window.location.href = url;
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const onPressForgetPassword = () => {
    setShowAlert(true);
  };

  const onClickActivation = () => {
    setIsActivationClicked(false);
    navigate(appRoute.ACTIVATION);
  };

  // Hooks
  useEffect(() => {
    if (isStaticContentLoading || isAccountLoading || isDashBoardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAccountLoading, isDashBoardLoading]);

  useEffect(() => {
    if (isUserLoggedIn) {
      setIsLoginSubmit(true);
    }
  }, [isUserLoggedIn]);

  useEffect(() => {
    onClickBack();
  }, []);

  return (
    <>
      <MetaTitle
        title={t('ek_login_meta_title')}
        description={t('ek_login_meta_description')}
        keywords={t('ek_login_meta_keywords')}
      />
      <section className="welcome-screen">
        <div className="light-gradient h-100 w-100 vh-100">
          {isLogin ? (
            <div className="container">
              <div className="sticky-top">
                <div className="logo">
                  <img src={EDEKAsmartLogoBlue} alt="logo" />
                </div>
              </div>
              <div className="card mt-3 mx-auto">
                <div className="card-body">
                  <ClientLogin
                    isLogin={isLogin}
                    setIsLogin={setIsLogin}
                    onPressForgetPassword={onPressForgetPassword}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div
              className="welcome-bg"
              style={{
                backgroundImage: `url(${
                  loginBgImage.responsive_image ? loginBgImage?.responsive_image['1x'] : ''
                })`
              }}>
              <div className="container">
                <div className="sticky-top">
                  <div className="logo">
                    <img src={EDEKAsmartLogo} alt="logo" />
                  </div>
                </div>
                <div className="row">
                  <div className="d-flex align-items-center welcome-middle justify-content-center flex-column">
                    <div className="title pb-5">
                      <h1 className="text-center">{t('ek_start_login_h1')}</h1>
                    </div>
                    <div>
                      <Button type="button" onClick={processLogin}>
                        {t('ek_start_login')}
                      </Button>
                      <Button
                        type="button"
                        buttonClass={appButtonType.SECONDARY}
                        onClick={onClickActivation}>
                        {t('ek_start_activation')}
                      </Button>
                    </div>
                    <div className="pt-5 text-center">
                      <p
                        className="mb-0 copytext-medium"
                        dangerouslySetInnerHTML={{ __html: t('ek_start_text1') }}
                      />
                      <a href="https://www.edeka-smart.de/" target="_blank" rel="noreferrer">
                        <span dangerouslySetInnerHTML={{ __html: t('ek_start_text2') }} />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>

      <Modal
        isOpen={showAlert}
        title={t('ek_forgot-pw_screen_h1')}
        isCloseButton
        onClose={() => setShowAlert(false)}>
        <div className="forgot-modal">
          <div className="warning-icon pt-2 pb-5">
            <img src={warning} alt="warning" />
          </div>
          <div className="d-flex justify-content-center text-center">
            <p
              className="copytext"
              dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_screen_text3') }}
            />
          </div>

          <div className="d-flex justify-content-center text-center mt-4">
            <Button
              buttonClass={appButtonType.SECONDARY}
              onClick={() => {
                setShowAlert(false);
                navigate(appRoute.FORGOT_PASSWORD);
              }}>
              {t('ek_forgot-pw_screen_next')}
            </Button>
          </div>

          <div className="d-flex justify-content-center text-center pt-3">
            <Button
              buttonClass={appButtonType.LINK}
              onClick={() => {
                setShowAlert(false);
              }}>
              {t('ek_forgot-pw_screen_back')}
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default Login;
