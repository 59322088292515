/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Switch } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import EmpfehLung from '@theme/assets/img/empfehlung.svg';

export function ChargingWelcome({ isChargingClicked, setIsChargingClicked }) {
  const { t } = useStaticContent();
  const { nextStep, setShowBackButton } = useActivation();

  useEffect(() => {
    setShowBackButton(false);

    return () => {
      setShowBackButton(true);
    };
  }, []);

  return (
    <div>
      <div className="welcome-badge listing-badge d-flex">
        <img src={EmpfehLung} alt="Icon" />{' '}
        <h6
          className="mb-0 px-2"
          dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_recommendation') }}
        />
      </div>

      {/* <b dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_h1') }} /> */}
      <div className="d-flex align-items-center justify-content-between">
        <h3
          className="mt-5 pb-2 mb-4"
          dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_hdl1') }}
        />

        <Switch
          id="isTopUpEnabled"
          name="isTopUpEnabled"
          onChange={() => setIsChargingClicked(!isChargingClicked)}
          checked={isChargingClicked}
          // isInvalid
          // isValid
          // error="Error message"
        />
      </div>
      <p className="copytext" dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_txt') }} />
    </div>
  );
}

ChargingWelcome.propTypes = {
  startTopUpProcess: PropTypes.func.isRequired
};

export default ChargingWelcome;

// <div className="mx-auto mb-3">
// <div className="charge-wel-popup">
//   <div className="card mx-auto">
//     <div className="card-body">
//       <div className="mx-auto mb-3">
//         <div className="welcome-badge listing-badge d-flex">
//           <img src={EmpfehLung} alt="Icon" />{' '}
//           <h6 className="mb-0 px-2" dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_recommendation') }} />
//         </div>
//       </div>

//       {/* <b dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_h1') }} /> */}

//       <h3
//         className="mt-5 pb-2 mb-4"
//         dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_hdl1') }}
//       />

//       <p className="copytext" dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_txt') }} />
//       <div className="d-flex justify-content-center mt-5 pt-4">
//         <Button onClick={() => startTopUpProcess()}>{t('ek_ap_topup_next_button')}</Button>
//       </div>
//     </div>
//   </div>
//   <div className="text-center mt-4" onClick={() => nextStep()}>
//     <u>
//       <b
//         className="fussnote-link"
//         dangerouslySetInnerHTML={{ __html: t('ek_ap_topup_without_button') }}
//       />
//     </u>
//   </div>
// </div>
