import React, { useEffect } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useAccount, useCustomer } from '@context/MobileOne';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appRoute } from '@utils/globalConstant';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import Sperren from '@theme/assets/img/sperren.svg';
import PhoneIcon from '@theme/assets/img/anruf.svg';
import MailIcon from '@theme/assets/img/mail.svg';
import NachrichtIcon from '@theme/assets/img/nachricht.svg';
import HandyIcon from '@theme/assets/img/handy.svg';

export function LockUnlockSIM() {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isAccountLoading } = useAccount();

  // Functions

  // Hooks
  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_lock_unlock_sim_meta_title')}
        description={t('ek_lock_unlock_sim_meta_description')}
        keywords={t('ek_lock_unlock_sim_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.HELP_AND_SERVICE}>
              {t('ek_bc_help_service')}
            </BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_block-sim_h') }} />
          <br />
        </div>
      </div>
      <div className="mt-4 pt-2">
        <div className="card mx-auto">
          <div className="card-body">
            <div className="sim-lock-unlock">
              <h3>
                <img src={Sperren} alt="Sperren-icon" /> {t('ek_block-sim_h2')}
              </h3>
              <p dangerouslySetInnerHTML={{ __html: t('ek_block-sim_text2') }} />
              <p dangerouslySetInnerHTML={{ __html: t('ek_block-sim_text1') }} />
              <h3 dangerouslySetInnerHTML={{ __html: t('ek_contact_h1') }} />
              <div className="d-flex align-items-center contact-phone pt-4 mt-3 ps-2">
                <div className="contact-phone-icon me-3">
                  <img src={PhoneIcon} alt="phone-icon" />
                </div>

                <div
                  className="copy-text text-start"
                  dangerouslySetInnerHTML={{
                    __html: t('ek_contact_text1').replace(
                      'ek_contact_service-number',
                      `${t('ek_contact_service-number')}`
                    )
                  }}
                />
              </div>

              <div className="d-flex align-items-center contact-phone pt-4 mt-2 ps-2">
                <div className="contact-phone-icon me-3">
                  <img src={HandyIcon} alt="phone-icon" />
                </div>

                <div
                  className="copy-text text-start"
                  dangerouslySetInnerHTML={{
                    __html: t('ek_contact_text2').replace(
                      'ek_contact_edeka-number',
                      `${t('ek_contact_edeka-number')}`
                    )
                  }}
                />
              </div>

              <div className="d-flex align-items-center contact-phone pt-4 mt-2 pb-3 ps-2">
                <div className="contact-phone-icon me-3">
                  <img src={NachrichtIcon} alt="phone-icon" />
                </div>
                <div
                  className="copy-text text-start"
                  dangerouslySetInnerHTML={{ __html: t('ek_contact_text3') }}
                />
              </div>

              <hr />

              <p
                className="copy-text pt-3 text-start ps-4 ms-3 mb-0"
                dangerouslySetInnerHTML={{ __html: t('ek_contact_h2') }}
              />

              <div className="d-flex align-items-center pt-4 mt-0 contact-phone ps-2">
                <div className="contact-phone-icon me-3">
                  <img src={MailIcon} alt="phone-icon" />
                </div>
                <p
                  className="copytext-medium text-start"
                  dangerouslySetInnerHTML={{ __html: t('ek_contact_text4') }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LockUnlockSIM;
