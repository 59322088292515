import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useVoucher } from '@context/AlphaComm';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { Button, Text } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { FullScreenModal } from '@core/Utils';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appButtonType, appRoute, appTextFieldLabelClass } from '@utils/globalConstant';
import { ChargeHeader } from '../ChargeHeader';

import './style.scss';

export function Voucher() {
  //  States

  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const {
    isLoading: isVoucherLoading,
    voucherCodeForm,
    voucherValidations,
    onSubmitTDM2,
    successModal,
    setSuccessModal
  } = useVoucher();
  const { alphaComToken, onAutoTopupQuerycall, isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();

  // Functions

  const onSuccessClick = () => {
    setSuccessModal(!successModal);
    navigate(appRoute.CHARGES);
  };

  // Hooks
  useEffect(() => {
    if (alphaComToken) {
      onAutoTopupQuerycall();
    }
  }, []);

  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isVoucherLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isVoucherLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_voucher_charge_meta_title')}
        description={t('ek_voucher_charge_meta_description')}
        keywords={t('ek_voucher_charge_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.CHARGES}>{t('ek_bc_charging')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.RECHARGE_CREDIT}>
              {t('ek_bc_charging_options')}
            </BreadcrumbItem>
          </Breadcrumbs>
          <h4 dangerouslySetInnerHTML={{ __html: t('ek_web_voucher-sofort-top-up_h1') }} />
          <br />
        </div>
      </div>

      <div className="row justify-content-center">
        <div className="card p-0 mt-5 recharge-voucher">
          <div className="card-body">
            <ChargeHeader />

            <h3
              className="pt-4 pb-2 mb-0"
              dangerouslySetInnerHTML={{ __html: t('ek_voucher-sofort-top-up_h3') }}
            />
            <Formik
              initialValues={voucherCodeForm}
              enableReinitialize
              validationSchema={voucherValidations}
              onSubmit={onSubmitTDM2}
            >
              {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <Form onSubmit={handleSubmit}>
                  <div className="col-lg-12">
                    <Text
                      label={t('ek_voucher-sofort-top-up_text4')}
                      labelCustomClass={appTextFieldLabelClass.V1}
                      type="text"
                      id="voucherCode"
                      name="voucherCode"
                      onChange={handleChange}
                      value={values.voucherCode}
                      placeholder={t('ek_voucher-sofort-top-up_input')}
                      isValid={touched.voucherCode && !errors.voucherCode}
                      isInvalid={touched.voucherCode && !!errors.voucherCode}
                      onBlur={handleBlur}
                      error={errors.voucherCode}
                    />
                  </div>

                  <div className="col-lg-12">
                    <div className="d-flex justify-content-between pt-5 mt-2">
                      <Button
                        buttonClass={appButtonType.LINK}
                        role="button"
                        onClick={() => {
                          navigate(appRoute.RECHARGE_CREDIT);
                        }}
                      >
                        {t('ek_change-back_button')}
                      </Button>
                      <Button
                        buttonClass={appButtonType.PRIMARY}
                        disabled={!values.voucherCode || !!errors.voucherCode}
                        type="submit"
                        isLoading={isVoucherLoading}
                      >
                        {t('ek_voucher-sofort-top-up_redeem')}
                      </Button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <FullScreenModal isOpen={successModal} fullscreen>
        <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <h4
            className="text-center"
            dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_success-msg_h1') }}
          />
          <br />
          <br />
          <p dangerouslySetInnerHTML={{ __html: t('ek_sofort-top-up_success-msg_text1') }} />
          <br />
          <br />
          <br />
          <Button buttonClass={appButtonType.PRIMARY} type="button" onClick={onSuccessClick}>
            {t('ek_sofort-top-up_success-msg_back')}
          </Button>
        </div>
      </FullScreenModal>
    </div>
  );
}

Voucher.propTypes = {};

export default Voucher;
