import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer, useDocument } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { Button } from '@core/Inputs';
import './style.scss';

export function AdvantageAutoTopup() {
  // Constants
  const { advantagesTopupImgBg, advantagesTopupContentImg } = useDocument();
  // States

  // Contexts
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  // Hooks

  // Code for showing loader
  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div
      className="advanatges-bg"
      style={{
        backgroundImage: `url(${
          advantagesTopupImgBg?.responsive_image ? advantagesTopupImgBg.responsive_image['2x'] : ''
        })`
      }}
    >
      <div className="container">
        <MetaTitle
          title={t('ek_birthday_meta_title')}
          description={t('ek_birthday_meta_description')}
          keywords={t('ek_birthday_meta_keywords')}
        />

        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.ADVANTAGES}>
                {t('ek_public_account_advantages')}
              </BreadcrumbItem>
            </Breadcrumbs>
            <h4 dangerouslySetInnerHTML={{ __html: t('ek_advantages_no6_breadcrumb') }} />
            <br />
          </div>
        </div>

        <div className="row">
          <div className="card auto-card pt-4 mt-4">
            <div className="col-lg-12">
              <div className="auto-top-center">
                <div className="auto-topup-content-img">
                  <img src={advantagesTopupContentImg?.media_url_display} alt="topup-content-img" />
                </div>

                <p
                  className="copytext mb-4 pb-2"
                  dangerouslySetInnerHTML={{ __html: t('ek_advantages_no6_text1') }}
                />

                <div className="pb-4">
                  <Button
                    onClick={() => {
                      navigate(appRoute.SET_AUTO_TOPUP);
                    }}
                  >
                    {t('ek_advantages_no6_text-btn')}
                  </Button>
                </div>

                <hr />

                <p className="copytext-medium pt-2">{t('ek_advantages_no6_text2')}</p>

                <p
                  className="copytext w-60 pb-4"
                  dangerouslySetInnerHTML={{ __html: t('ek_advantages_no6_text3') }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdvantageAutoTopup;
