/* eslint-disable no-shadow */
/* eslint-disable eqeqeq */
/* eslint-disable arrow-body-style */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { useMobileOne } from '@dom-digital-online-media/dom-mo-sdk';
import { StatusCodes } from '@dom-digital-online-media/dom-app-config-sdk';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne/Customer';
import { useAlert, useLoader } from '@context/Utils';
import { appAlert } from '@utils/globalConstant';

export const OptionContext = createContext({});

export function OptionContextProvider({ children }) {
  // Context
  const { t } = useStaticContent();
  const { showAlert } = useAlert();
  const { getCustomerData, customerBalance } = useCustomer();
  const { showLoader, hideLoader } = useLoader();
  const { onAllOptions, onBookableOptions, onBookedOptions, onBookOption } = useMobileOne();

  // States
  const [isLoading, setIsLoading] = useState(false);
  const [bookOptionSuccess, setBookOptionSuccess] = useState(false);
  const [allOptions, setAllOptions] = useState([]);
  const [bookableOptions, setBookableOptions] = useState([]);
  const [bookedOptions, setBookedOptions] = useState([]);

  // Validations
  const termsValidations = Yup.object().shape({
    terms: Yup.boolean().isTrue('please_select_terms_and_conditions')
  });

  // Functions

  const onOptionfilteredData = (data) => {
    let finalFiltered = [];
    let comingData = [...data];

    for (let [index, value] of comingData.entries()) {
      // Main Array for Whole Data

      let currentGroupID = value?.group?.id;

      if (currentGroupID) {
        // Checking conditions for avilablibily for Group ID

        if (
          finalFiltered.length > 0 &&
          !finalFiltered.find((elem) => {
            return elem.id == value?.group?.id;
          })
        ) {
          let NewData = comingData.filter((elem, index) => {
            return value.group.id === elem.group.id;
          });

          finalFiltered[finalFiltered.length] = {
            ...NewData[0].group,
            ListData: NewData
          };
        } else if (finalFiltered.length === 0) {
          let NewData = comingData.filter((elem, index) => {
            return value.group.id === elem.group.id;
          });

          finalFiltered[finalFiltered.length] = {
            ...NewData[0].group,
            ListData: NewData
          };
        }
      }
    }
    return finalFiltered;
  };

  // Get all options and storing it in state
  const getAllOptions = async () => {
    try {
      const { data } = await onAllOptions();
      // if (data) setAllOptions(data);
      return data;
    } catch (error) {
      console.log(error);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      setIsLoading(false);
      return error;
    }
  };

  // Get bookable options and storing it in state
  const getBookableOptions = async () => {
    let bookableData = [];
    try {
      // showLoader();
      const { data } = await onBookableOptions();
      if (data) {
        setBookableOptions(data);
        bookableData = onOptionfilteredData(data);
        // console.log('bookableData---1234', bookableData);
        for (let elem of bookableData) {
          for (let elem2 of elem.ListData) {
            if (elem2.price < customerBalance.totalBalance) {
              elem2.hasLowBalance = false;
            } else {
              elem2.hasLowBalance = true;
            }
          }
        }
      }
      setAllOptions(bookableData);
      // hideLoader();
      return data;
    } catch (error) {
      console.log(error);
      // hideLoader();
      setIsLoading(false);
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Get booked options and storing it in state
  const getBookedOptions = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data } = await onBookedOptions();
      if (data) setBookedOptions(data);
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Function loads after we get customer data, and returns all options, bookable and booked options
  const afterLoad = async () => {
    try {
      setIsLoading(true);
      // showLoader();
      await getAllOptions();
      await getBookableOptions();
      await getBookedOptions();
      // hideLoader();
      setIsLoading(false);
      return null;
    } catch (error) {
      console.log(error);
      // hideLoader();
      setIsLoading(false);
      return error;
    }
  };

  // Book an option if possible
  const bookAnOption = async (optionId) => {
    try {
      setIsLoading(true);
      // showLoader();
      const { data, status, success } = await onBookOption(optionId);
      console.log({ data, success, status });

      if (success) {
        showAlert({
          type: appAlert.SUCCESS,
          message: 'Successfully updated tariff'
        });
      } else {
        showAlert({
          type: appAlert.ERROR,
          message: 'Error in processing, try again after sometime.'
        });
      }
      await getCustomerData();
      setIsLoading(false);
      // hideLoader();
      return data;
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      // hideLoader();
      if (
        !(
          error?.status === StatusCodes.UNAUTHORIZED ||
          error?.status === StatusCodes.FORBIDDEN ||
          error?.response?.status === StatusCodes.UNAUTHORIZED ||
          error?.response?.status === StatusCodes.FORBIDDEN
        )
      ) {
        showAlert({ type: appAlert.ERROR, message: t(error?.error[0]?.messageBody) });
      }
      return error;
    }
  };

  // Hooks
  // to check the all options, bookable and booked options
  useEffect(() => {
    // const { msisdn, ...restCustomerData } = customerData;
    const totalBalance = customerBalance.totalBalance ? customerBalance.totalBalance : null;
    if (totalBalance !== null) {
      afterLoad();
    }
  }, [customerBalance]);

  // We wrap it in a useMemo for performance reason
  const contextPayload = useMemo(
    () => ({
      // States
      isLoading,
      setIsLoading,
      allOptions,
      bookableOptions,
      bookedOptions,
      bookOptionSuccess,
      setBookOptionSuccess,

      bookAnOption,
      termsValidations,
      getAllOptions,
      getBookableOptions,
      getBookedOptions
    }),
    [
      // States
      isLoading,
      setIsLoading,
      allOptions,
      bookableOptions,
      bookedOptions,
      bookOptionSuccess,
      setBookOptionSuccess,

      bookAnOption,
      termsValidations,
      getAllOptions,
      getBookableOptions,
      getBookedOptions
    ]
  );

  // We expose the context's value down to our components, while
  // also making sure to render the proper content to the screen
  return <OptionContext.Provider value={contextPayload}>{children}</OptionContext.Provider>;
}
OptionContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};
// A custom hook to quickly read the context's value. It's
// only here to allow quick imports
export const useOption = () => useContext(OptionContext);

export default OptionContext;
