import React, { useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useLayout, useLoader } from '@context/Utils';
import { useCustomer } from '@context/MobileOne';
import { useAccount } from '@context/MobileOne/Account';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { Text, Button } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { FullScreenModal } from '@core/Utils';
import { appButtonType, appRoute, appTextFieldLabelClass } from '@utils/globalConstant';
import success from '@theme/assets/img/success.svg';
import { ProfileInfo } from '../ProfileInfo';

export function ChangeHotlinePassword() {
  // Constants

  // States

  // Context
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const {
    isLoading,
    hotlinePasswordForm,
    onHotlinePasswordSubmit,
    hotlinePasswordValidation,
    hotlineSuccessModal,
    setHotlineSuccessModal
  } = useAccount();
  const { showLoader, hideLoader } = useLoader();
  const { customerData, isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions

  // Hooks
  useEffect(() => {
    if (isCustomerLoading || isLoading || isStaticContentLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isCustomerLoading, isLoading, isStaticContentLoading, isDashboardLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_change_hotline_meta_title')}
        description={t('ek_change_hotline_meta_description')}
        keywords={t('ek_change_hotline_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.PROFILE}>{t('ek_account_account')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_hotline-pw_h')}</h4>
          <br />
        </div>
      </div>
      <div className="mt-4 pt-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileInfo />
          </div>
          <div className="col-lg-7 offset-md-2">
            <div className="card">
              <div className="card-body pt-1 pb-4">
                <Formik
                  initialValues={{
                    ...hotlinePasswordForm,
                    pswrdForHtLine: customerData ? customerData.hotlinePassword : '',
                    cnfPswrdForHtLine: ''
                  }}
                  enableReinitialize
                  validationSchema={hotlinePasswordValidation}
                  onSubmit={onHotlinePasswordSubmit}
                >
                  {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <div className="col-lg-12">
                        <Text
                          labelCustomClass={appTextFieldLabelClass.V1}
                          label={t('ek_hotline-pw_h1')}
                          type="password"
                          id="pswrdForHtLine"
                          name="pswrdForHtLine"
                          onChange={handleChange}
                          value={values.pswrdForHtLine}
                          isValid={touched.pswrdForHtLine && !errors.pswrdForHtLine}
                          isInvalid={!!touched.pswrdForHtLine && !!errors.pswrdForHtLine}
                          onBlur={handleBlur}
                          error={
                            touched.pswrdForHtLine && errors.pswrdForHtLine
                              ? errors.pswrdForHtLine
                              : null
                          }
                        />
                      </div>

                      <div className="col-lg-12">
                        <Text
                          labelCustomClass={appTextFieldLabelClass.V1}
                          label={t('ek_hotline-pw_h2')}
                          type="password"
                          id="cnfPswrdForHtLine"
                          name="cnfPswrdForHtLine"
                          onChange={handleChange}
                          placeholder={t('ek_hotline-pw_h2')}
                          value={values.cnfPswrdForHtLine}
                          isValid={touched.cnfPswrdForHtLine && !errors.cnfPswrdForHtLine}
                          isInvalid={!!touched.cnfPswrdForHtLine && !!errors.cnfPswrdForHtLine}
                          onBlur={handleBlur}
                          error={
                            touched.cnfPswrdForHtLine && errors.cnfPswrdForHtLine
                              ? errors.cnfPswrdForHtLine
                              : null
                          }
                        />
                      </div>
                      <div className="col-lg-12">
                        <div className="d-flex justify-content-between pt-4">
                          <Button
                            buttonClass={appButtonType.LINK}
                            type="button"
                            onClick={() => {
                              navigate(-1);
                            }}
                          >
                            {t('ek_change-back_button')}
                          </Button>
                          <Button
                            buttonClass={appButtonType.PRIMARY}
                            type="submit"
                            isLoading={isLoading}
                          >
                            {t('ek_hotline-pw_save')}
                          </Button>
                        </div>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={hotlineSuccessModal} fullscreen>
        <div>
          <div className="book-pass-success mx-auto d-flex justify-content-center flex-column align-items-center vh-100">
            <div className="success-icon">
              {' '}
              <img src={success} alt="success-icon" />{' '}
            </div>
            <h2 className="text-center mt-4 pt-2 mb-4 pb-2">{t('ek_hotline-pw_success-msg')}</h2>

            <Button
              onClick={() => {
                setHotlineSuccessModal(!hotlineSuccessModal);
                navigate(appRoute.DASHBOARD);
              }}
            >
              {t('ek_back_overview')}
            </Button>
          </div>
        </div>
      </FullScreenModal>
    </div>
  );
}

export default ChangeHotlinePassword;
