import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appRoute } from '@utils/globalConstant';
import { ProfileInfo } from '../ProfileInfo';
import { ChangePassword } from '../../ChangePassword';
import { FullScreenModal } from '@core/Utils';
import { useAccount } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import success from '@theme/assets/img/success.svg';
import { useNavigate } from 'react-router-dom';

export function PasswordChange() {
  // Constants

  // States

  // Context
  const navigate = useNavigate();
  const { t } = useStaticContent();
  const { passwordSuccessModal, setPasswordSuccessModal } = useAccount();

  // Functions
  const onClickDashboard = () => {
    setPasswordSuccessModal(!passwordSuccessModal);
    navigate(appRoute.DASHBOARD);
  };

  // Hooks

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_password_change_meta_title')}
        description={t('ek_password_change_meta_description')}
        keywords={t('ek_password_change_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.USER_ACCOUNT}>{t('ek_column_five')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.PROFILE}>{t('ek_account_account')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_change-pw_h')}</h4>
          <br />
        </div>
      </div>
      <div className="mt-4 pt-2">
        <div className="row">
          <div className="col-lg-3">
            <ProfileInfo />
          </div>
          <div className="col-lg-7 offset-md-2">
            <div className="card">
              <div className="card-body pt-1">
                <ChangePassword isProfile />
              </div>
            </div>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={passwordSuccessModal} fullscreen>
        <div className="container vh-100 vw-100 d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="">
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                <div className="success-icon"> {<img src={success} alt="success-icon" />} </div>
                <h2
                  className="text-center mt-4 pt-2"
                  dangerouslySetInnerHTML={{ __html: t('ek_change-pw_success-msg') }}
                />
                <br />
                <Button onClick={onClickDashboard}>{t('ek_back_overview')}</Button>
              </div>
            </div>
          </div>
        </div>
      </FullScreenModal>
    </div>
  );
}

export default PasswordChange;
