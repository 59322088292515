import React, { useEffect, useRef, useState } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount, useCustomer } from '@context/MobileOne';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { Modal } from '@core/Utils';

import './style.scss';

export function ExplainerVideo() {
  // Constants
  const videoEl = useRef(null);

  // States
  const [showModal, setShowModal] = useState(false);
  const [modalVideo, setModalVideo] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [paused, setPaused] = useState(true);

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { getExplainerVideos, explainerVideos, isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isAccountLoading } = useAccount();

  // Functions

  const playVideo = () => {
    // console.log('videoEl================', videoEl);
    // eslint-disable-next-line no-unused-expressions
    videoEl.current.paused ? videoEl.current.play() : videoEl.current.pause();
    setPaused(videoEl.current.paused);
  };

  // To handle modal close
  const closeModal = () => setShowModal(false);

  // Setting the modal show and video for modal
  const videoModal = (item) => {
    setPaused(true);
    setModalVideo(item);
    setShowModal(true);
  };

  // Hooks
  useEffect(() => {
    if (staticContentData) {
      getExplainerVideos();
    }
  }, [staticContentData]);

  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  // useEffect(() => {
  //     console.log('paused===============', paused);
  // }, [paused])

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_help_and_service_meta_title')}
        description={t('ek_user_profile_meta_description')}
        keywords={t('ek_user_profile_meta_keywords')}
      />
      <div className="row">
        <div className="col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
            <BreadcrumbItem route={appRoute.HELP_AND_SERVICE}>
              {t('ek_bc_help_service')}
            </BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_video_h')}</h4>
          <br />
        </div>
        <div className="mt-4 pt-2">
          <div className="row">
            {explainerVideos &&
              explainerVideos.map((video, index) => (
                <div className="col-lg-6" key={`explainer-video-${index}`}>
                  <p className="copytext-medium pb-1">{video.videoHeadline}</p>
                  {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
                  <div
                    className="explainer-video"
                    onClick={() => {
                      videoModal(video);
                    }}
                  >
                    <img
                      className="w-100"
                      src={video.previewImage.media_url}
                      alt={video.previewImage.name}
                    />
                  </div>
                  <div className="d-flex justify-content-between pt-3 pb-3 mb-2">
                    <p className="copy-text">{video.videoCopy}</p>
                    <p className="copytext-medium">{video.videoDuration}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <Modal
          modalClass="explainer-full-video"
          isOpen={showModal}
          onClose={closeModal}
          isCloseButton
        >
          <div className="p-0">
            {modalVideo && (
              <div className="position-relative flex">
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={videoEl}
                  className="w-100 h-100"
                  controls
                  loop
                  autoPlay
                  poster={modalVideo?.previewImage?.media_url}
                  onClick={() => playVideo()}
                >
                  <source src={modalVideo?.media_url} type="video/mp4" />
                </video>
                {/* {paused && (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                                <div className="position-absolute top-50 start-50" onClick={() => playVideo()}>
                                    <img
                                        src="/videos/play.jpg"
                                        alt="Video Play Button"
                                        srcSet=""
                                        width="100"
                                    />
                                </div>
                            )} */}
              </div>
            )}
          </div>
        </Modal>
      </div>
    </div>
  );
}

export default ExplainerVideo;
