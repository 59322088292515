import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button } from '@core/Inputs';
import { useActivation, useCustomer } from '@context/MobileOne';
import { FullScreenModal } from '@core/Utils';
import { appButtonType } from '@utils/globalConstant';
import success from '@theme/assets/img/success.svg';
import { YourTariff } from './YourTariff';
// import { Voucher } from './Voucher';
import { UserAccount } from './UserAccount';
import { YourData } from './YourData';
import { Terms } from './Terms';
import './style.scss';
// import { OverviewConfirmation } from './Confirmation';

export function OverView() {
  // State
  // const [isOverviewHome, setIsOverviewHome] = useState(false);
  const [isEditable, setIsEditable] = useState(false);

  // Context
  const {
    // isLoading,
    overviewForm,
    setOverviewForm,
    personalDataForm,
    setPersonalDataForm,
    tariffActivationForm,
    loginPasswordForm,
    phoneNumberActivationForm,
    overviewValidation,
    overviewFormSubmit,
    bookableTariffs,
    // nextStep,
    activateSimSuccessPopup
  } = useActivation();
  const { getPdfs, termsInfoPdfs, privacyInfoPdfs, mediaImages } = useCustomer();
  const { t } = useStaticContent();

  useEffect(() => {
    getPdfs();
  }, []);

  return (
    <div className="container">
      <div className="row">
        {/* {isOverviewHome ? (
          <>
            <OverviewConfirmation
              isLoading={isLoading}
              isOverviewHome
              setIsOverviewHome={setIsOverviewHome}
              overviewForm={overviewForm}
              overviewFormSubmit={overviewFormSubmit}
              nextStep={nextStep}
            />
          </>
        ) : ( */}
        <Formik
          initialValues={{
            ...overviewForm,
            ...loginPasswordForm,
            ...personalDataForm,
            ...tariffActivationForm,
            ...phoneNumberActivationForm
          }}
          validationSchema={overviewValidation}
          onSubmit={(data) => {
            // setPersonalDataForm({ ...personalDataForm, ...data, });
            const updatedDate = moment(data.birthDate, 'DD.MM.YYYY').format('YYYY-MM-DD');
            data = { ...data, birthDate: updatedDate };
            setPersonalDataForm({ ...personalDataForm, ...data });
            setOverviewForm(data);
            // overviewFormSubmit(overviewForm);
            overviewFormSubmit(data);
            // setIsOverviewHome(!isOverviewHome);
          }}>
          {({
            values: {
              chosenTariffId,
              // voucher,
              msisdn,
              cscPassword,
              hotlinePassword,
              // salutation,
              firstName,
              lastName,
              birthDate,
              street,
              houseNumber,
              emailAddress,
              // identityType,
              // identityNumber,
              // nationality,
              // placeOfIssue,
              // validUntil,
              termsAndConditions,
              thirdParty,
              zip,
              city,
              countryCode
            },
            handleBlur,
            handleChange,
            errors,
            touched,
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="mb-4 col-md-6 mx-auto px-2">
                <h2 className="" dangerouslySetInnerHTML={{ __html: t('ek_ap_overview_h1') }} />
              </div>

              {/* Login password , voucher , active tariff detail */}
              <YourTariff
                chosenTariffId={chosenTariffId}
                bookableTariffs={bookableTariffs}
                mediaImages={mediaImages}
              />

              <div className="mb-2 pb-0">
                <div className="card mx-auto">
                  {/* Login Password , voucher */}
                  <div className="card-body">
                    <div className="row">
                      <div>
                        <UserAccount
                          msisdn={msisdn}
                          cscPassword={cscPassword}
                          hotlinePassword={hotlinePassword}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      {/* <Col xs={12} className="px-3 py-2">
                        <Voucher
                          voucher={voucher}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col> */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Active tariff detail */}

              <YourData
                // salutation={salutation}
                firstName={firstName}
                lastName={lastName}
                birthDate={birthDate}
                street={street}
                houseNumber={houseNumber}
                emailAddress={emailAddress}
                // identityType={identityType}
                // identityNumber={identityNumber}
                // nationality={nationality}
                // placeOfIssue={placeOfIssue}
                // validUntil={validUntil}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                isEditable={isEditable}
                setIsEditable={setIsEditable}
                zip={zip}
                city={city}
                countryCode={countryCode}
              />
              <Terms
                overviewForm
                termsAndConditions={termsAndConditions}
                thirdParty={thirdParty}
                handleBlur={handleBlur}
                handleChange={handleChange}
                errors={errors}
                touched={touched}
                termsInfoPdfs={termsInfoPdfs}
                privacyInfoPdfs={privacyInfoPdfs}
              />
              <div className="d-flex justify-content-end mt-4 mb-4">
                <Button
                  buttonClass={appButtonType.PRIMARY}
                  type="submit"
                  disabled={!termsAndConditions}>
                  {t('ek_ap_overview_confirm_button1')}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
        {/* )} */}
      </div>
      <FullScreenModal isOpen={activateSimSuccessPopup} fullscreen>
        <div className="d-flex justify-content-center text-center align-item-center vh-30 flex-column vh-100">
          {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
          <div className="success-icon"> {<img src={success} alt="success-icon" />} </div>
          <h2
            className="text-center mt-4 pt-2"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_success_data_h1') }}
          />
        </div>
      </FullScreenModal>
    </div>
  );
}

export default OverView;
