import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';

import { useOption, useSpeedOn } from '@context/MobileOne';
import { Button, Checkbox } from '@core/Inputs';
import { RoamingProduct } from '@part/Roaming';

export function BookSpeedOn() {
  // Contexts
  const { allOptions, bookableOptions, isLoading, termsValidations } = useOption();
  const { speedonId } = useParams();
  const navigate = useNavigate();
  const { bookSpeedOn } = useSpeedOn();

  // States
  const [speedOnDetails, setSpeedOnDetails] = useState({});

  // Hooks
  useEffect(() => {
    if (speedonId && allOptions.length > 0) {
      const findOption = allOptions.find(({ id }) => parseInt(id, 10) === parseInt(speedonId, 10));
      console.log(findOption, speedonId, allOptions);
      if (findOption) {
        setSpeedOnDetails(findOption);
      }
    }
  }, [allOptions, bookableOptions, speedonId]);

  // Functions
  const onSubmit = () => bookSpeedOn(speedonId);

  return (
    <>
      <div className="container">
        <div>
          <hr />
          <h2>SpeedOn Book</h2>
          <hr />
          <h4 className="py-4">{speedOnDetails.name}</h4>
          <Formik
            initialValues={{
              terms: false
            }}
            enableReinitialize
            validationSchema={termsValidations}
            onSubmit={onSubmit}
          >
            {({ values, handleChange, errors, touched, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                    <RoamingProduct {...{ ...speedOnDetails }} />
                  </div>
                  <div className="col-lg-6">
                    <div className="">
                      {speedOnDetails.description}
                      <div className="py-4">
                        <Checkbox
                          className="py-4"
                          type="checkbox"
                          label="You must accept my terms!"
                          id="terms"
                          name="terms"
                          onChange={handleChange}
                          value={values.terms}
                          isInvalid={!!touched.terms && !!errors.terms}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12 py-3 d-inline-flex justify-content-between">
                    <Button
                      variant="secondary"
                      type="button"
                      role="tab"
                      onClick={() => navigate(-1)}
                    >
                      Back
                    </Button>
                    <Button variant="primary" type="submit" isLoading={isLoading}>
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default BookSpeedOn;
