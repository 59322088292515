import React, { useState } from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Modal } from '@core/Utils';
import { Button } from '@core/Inputs';
import warning from '@theme/assets/img/warning.svg';
import { appButtonType } from '@utils/globalConstant';
import './style.scss';

export function ChangePasswordAlert({ open }) {
  // Constants

  // States
  const [openModal, setOpenModal] = useState(open);

  // Context
  const { t } = useStaticContent();

  // Hooks

  return (
    <div className="force-new-pass">
      <Modal isOpen={openModal} title={t('ek_forgot-pw_new-pw_modal_h1')}>
        <div>
          <div className="warning-icon pt-0 pb-5">
            <img src={warning} alt="warning" />
          </div>
          <div className="d-flex justify-content-center text-center">
            <p
              className="copytext"
              dangerouslySetInnerHTML={{ __html: t('ek_forgot-pw_new-pw_modal_text') }}
            />
          </div>

          <div className="d-flex justify-content-center text-center">
            <Button
              buttonClass={appButtonType.SECONDARY}
              variant="primary"
              onClick={() => {
                setOpenModal(!openModal);
              }}
            >
              {t('ek_forgot-pw_new-pw_modal_btn')}
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default ChangePasswordAlert;
