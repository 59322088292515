import React from 'react';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useCustomer } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import { appButtonType } from '@utils/globalConstant';

import warning from '@theme/assets/img/warning.svg';
import downloadWhite from '@theme/assets/img/download_white.svg';
import './style.scss';

export function DownloadMNPDoc({ formSubmit, formData, onMnpClicked }) {
  // Constants

  // States

  // Contexts
  const { t } = useStaticContent();
  const { downloadDocPDF, mnpPdfs } = useCustomer();

  // Functions

  // Hooks

  return (
    <div className="row">
      <center>
        <div className="col-xs-12">
          <h3
            className="text-white text-center portability-heading"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_nr-portability_h2') }}
          />
          <div className="warning-icon pt-4">
            <img src={warning} alt="warning" />
          </div>
          <p
            className="copytext-medium pb-2"
            dangerouslySetInnerHTML={{ __html: t('ek_ap_nr-portability_text1') }}
          />
          <div
            aria-hidden
            onClick={() => {
              downloadDocPDF(mnpPdfs?.media_url);
            }}
          >
            <p className="copytext-medium text-white text-underline cursor-pointer">
              <i className="download-icon mx-2">
                <img src={downloadWhite} alt="download_white" />
              </i>
              <u dangerouslySetInnerHTML={{ __html: t('ek_ap_nr-portability_text2') }} />
            </p>
          </div>

          <Button buttonClass={appButtonType.SECONDARY} onClick={onMnpClicked}>
            {t('ek_ap_nr-portability_button')}
          </Button>
        </div>

        <div
          aria-hidden
          role="button"
          className="col-xs-12"
          onClick={() => formSubmit({ ...formData, mnp: false })}
        >
          <p className="pb-0 mt-2">
            <u
              className="link-light-blue"
              dangerouslySetInnerHTML={{ __html: t('ek_ap_nr-porability_text3') }}
            />
          </p>
        </div>
      </center>
    </div>
  );
}

export default DownloadMNPDoc;
