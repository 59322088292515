import React, { useEffect } from 'react';
import { useActivation } from '@context/MobileOne';
// import { appTariffs } from '@utils/globalConstant';
import { SetUpAutoTopup } from './SetUpAutoTopup';
import { ChargingWelcome } from './Welcome';
// import { ChargingConfirmation } from './Confirmation';

import './style.scss';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import MoreInfoScreen from '@part/MoreInfoScreen';
import { FullScreenModal } from '@core/Utils';
export function Charging() {
  // States

  // Contexts
  const { isChargingClicked, setIsChargingClicked, autoTopupMoreInfo, setAutoTopupMoreInfo, nextStep } =
    useActivation(); // tariffActivationForm
  const { t, staticContentData } = useStaticContent();

  // tariffActivationForm.chosenTariffId === appTariffs.TALK ? (
  //   <ChargingConfirmation />
  // ) :

  // Function
  const startTopUpProcess = () => {
    // Disable welcome screen
    setIsChargingClicked(!isChargingClicked);
  };

  useEffect(() => {
    if (staticContentData) {
      const moreInfoTag = document.querySelector('#auto-top-up-more-info');
      if (moreInfoTag) {
        moreInfoTag.addEventListener('click', () => {
          setAutoTopupMoreInfo(true);
        });
      }
    }
  }, [staticContentData]);

  return (
    <>
      <div className="container">
        <div className="card mx-auto">
          <div className="card-body">
            <ChargingWelcome
              startTopUpProcess={startTopUpProcess}
              isChargingClicked={isChargingClicked}
              setIsChargingClicked={setIsChargingClicked}
            />
            {isChargingClicked && <SetUpAutoTopup />}
            <div className="py-4 d-flex justify-content-center">
              <a
                href="javascript:void(0)"
                className=""
                onClick={() => nextStep()}
                dangerouslySetInnerHTML={{ __html: t('ek_reg_step6_topup_btn2') }}
              />
            </div>
          </div>
        </div>
        <FullScreenModal isOpen={autoTopupMoreInfo} customeClass="auto-topup" fullscreen>
          <div className="container pt-5">
            <div className="row pt-5">
              <div className="col-lg-8 pt-5 mx-auto">
                <MoreInfoScreen
                  heading={t('ek_topup_overlay_title')}
                  onBackClick={() => setAutoTopupMoreInfo(false)}>
                  <div className="sreen-body">
                    <div
                      className="copytext"
                      dangerouslySetInnerHTML={{ __html: t('ek_topup_overlay_txt') }}
                    />
                  </div>
                </MoreInfoScreen>
              </div>
            </div>
          </div>
        </FullScreenModal>
      </div>
    </>
  );
}

export default Charging;
