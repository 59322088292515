import React from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount } from '@context/MobileOne';
import { Button } from '@core/Inputs';
import { FullScreenModal } from '@core/Utils';
import { ChangePassword } from '@page/User/Account/ChangePassword';
import EDEKAsmartLogoBlue from '@theme/assets/img/EDEKAsmart_logo.svg';
import success from '@theme/assets/img/success.svg';

export function ForcePasswordChange() {
  // Contexts
  const { t } = useStaticContent();
  const { onLogoutPress, forcePasswordSuccessModal, setForcePasswordSuccessModal } = useAccount();

  // Functions
  const forceResetClick = () => {
    setForcePasswordSuccessModal(!forcePasswordSuccessModal);
    onLogoutPress();
    window.location.href = '/login';
  };

  return (
    <>
      <div className="light-gradient h-100 w-100 vh-100">
        <div className="container">
          <div className="sticky-top">
            <div className="logo">
              <img src={EDEKAsmartLogoBlue} alt="logo" />
            </div>
          </div>
          <div className="card mx-auto mt-5">
            <div className="card-body">
              <ChangePassword isForceReset />
            </div>
          </div>
        </div>
      </div>

      <FullScreenModal isOpen={forcePasswordSuccessModal} fullscreen>
        <div className="container vh-100 vw-100 d-flex align-items-center justify-content-center">
          <div className="row">
            <div className="col-lg-8 mx-auto">
              <div className="">
                {/* eslint-disable-next-line react/jsx-curly-brace-presence */}
                <div className="success-icon"> {<img src={success} alt="success-icon" />} </div>
                <h2
                  className="text-center mt-4 pt-2"
                  dangerouslySetInnerHTML={{ __html: t('ek_change-pw_success-msg') }}
                />
                <br />
                <Button onClick={forceResetClick}>{t('ek_back_login')}</Button>
              </div>
            </div>
          </div>
        </div>
        {/* <div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <center>
            <h4
              className="text-center"
              dangerouslySetInnerHTML={{ __html: t('ek_change-pw_success-msg') }}
            />
            <br />
            <br />
            <Button onClick={forceResetClick}>{t('ek_back_login')}</Button>
          </center>
        </div> */}
      </FullScreenModal>
    </>
  );
}

export default ForcePasswordChange;
