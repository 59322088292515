import React from 'react';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button } from '@core/Inputs';
import { BreadcrumbItem, Breadcrumbs, Card } from '@core/Layout';
import { appRoute } from '@utils/globalConstant';
import { MetaTitle } from '@core/Layout/MetaTitle';

export function EsimTariff() {
  // States

  // Contexts
  const { t } = useStaticContent();
  // Functions

  // Hooks

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_esim_tariff_meta_title')}
        description={t('ek_esim_tariff_meta_description')}
        keywords={t('ek_esim_tariff_meta_keywords')}
      />
      <div>
        <br />
        <br />
        <Breadcrumbs>
          <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
          <BreadcrumbItem route={appRoute.TARIFF_OPTION}>Tariffs</BreadcrumbItem>
        </Breadcrumbs>
        <h4>{t('ek_tarif-change_esim_header')}</h4>
        <br />
        <br />
        <center>
          <Card>
            <br />
            <h4>{t('ek_tarif-change_esim_h1')}</h4>
            <br />
            <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_text1') }} />
            <br />
            <h3>{t('ek_tarif-change_esim_h2')}</h3>
            <br />
            <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_text2') }} />
            <br />
            <Button variant="primary" onClick={() => {}}>
              {t('ek_tarif-change_esim_call-button')}
            </Button>
            <br />
            <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_text3') }} />
            <br />
            <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_text4') }} />
            <br />
            <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_text5') }} />
            <br />
          </Card>
          <br />
          <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_h3') }} />
          <br />
          <div dangerouslySetInnerHTML={{ __html: t('ek_tarif-change_esim_text6') }} />
          <br />
        </center>
      </div>
    </div>
  );
}

export default EsimTariff;
