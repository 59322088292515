import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useLayout, useLoader } from '@context/Utils';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { useCustomer, useOption, useSpeedOn } from '@context/MobileOne';
import { BreadcrumbItem, Breadcrumbs, Card } from '@core/Layout';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { appButtonType, appCustomCardClass, appPassTypeID, appRoute } from '@utils/globalConstant';
import { Button } from '@core/Inputs';
import EmpfehLung from '@theme/assets/img/empfehlung.svg';
import { ActiveTariffSection } from './ActiveTariffSection';
import './style.scss';

export function Option() {
  // States
  const [options, setOptions] = useState([]);

  // Contexts
  const { t } = useStaticContent();
  const navigate = useNavigate();
  const { bookableOptions } = useOption(); // bookableOptions allOptions
  const { passOffers } = useSpeedOn();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();

  // Functions
  const getPrice = (price) => `${price} €`.replace('.', ',');

  const getGb = (pass) => {
    var marker = 1024;
    var gigaBytes = marker * marker * marker;
    var GB = pass?.volumeData?.volume?.bytes / gigaBytes;
    return GB;
  };

  // Hooks

  useEffect(() => {
    if (bookableOptions && passOffers) {
      const localOptions = [...bookableOptions, ...passOffers];
      setOptions(localOptions);
    }
  }, [bookableOptions, passOffers]);

  useEffect(() => {
    if (isStaticContentLoading || isAutoTopUpLoading || isCustomerLoading || isDashboardLoading) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [isStaticContentLoading, isAutoTopUpLoading, isCustomerLoading, isDashboardLoading]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_book_data_meta_title')}
        description={t('ek_book_data_meta_description')}
        keywords={t('ek_book_data_meta_keywords')}
      />
      <div className="row">
        <div className="col-md-12 col-lg-12">
          <br />
          <Breadcrumbs>
            <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
          </Breadcrumbs>
          <h4>{t('ek_book_data_h')}</h4>
        </div>
      </div>
      <div className="mt-4 pt-3">
        <div className="tarif-options">
          <div className="row">
            <div className="col-lg-5">
              <ActiveTariffSection />
            </div>
            <div className="col-lg-7 mt-3">
              <Card customClass={appCustomCardClass.OPTION_CARD}>
                {options.length > 0 ? (
                  <div className="mb-3">
                    <span className="mt-3 listing-badge options-badge d-flex badge rounded-pill text-dark bg-light">
                      <img src={EmpfehLung} alt="Icon" />{' '}
                      <h6 className="mb-0 px-2 copytext-medium">{t('ek_top-up_recommendation')}</h6>
                    </span>
                  </div>
                ) : null}

                {options.length > 0 ? (
                  options.map((item, index) =>
                    item?.passCode ? (
                      <div key={`option-${index}`}>
                        {/* {options.length > 1 ? <hr /> : <></>} */}
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="w-45">
                            <h6 className="copytext-medium mb-0">
                              {item?.passType == appPassTypeID.DAY_FLAT_UNLIMITED
                                ? t('ek_datapackage_dayflat')
                                : item.name}
                            </h6>
                            <span className="copytext">{item?.duration?.valueFormatted}</span>
                            <span className="copytext">
                              {getGb(item) > 10000 ? t('ek_datapackage_unlimited') : null}
                            </span>
                            {/* <span className="copytext">{item?.smsCode}</span> */}
                          </div>
                          <div className="w-35 ms-3 ps-5">
                            {/* <span className="copytext">{t('')}</span> */}

                            <span className="copytext">{getPrice(item?.price)}</span>
                          </div>
                          <div className="w-20">
                            <Button
                              buttonClass={appButtonType.LINK}
                              onClick={() => {
                                navigate(appRoute.PASSOFFER_BOOK(item?.passCode));
                              }}
                            >
                              {t('ek_book_data_choose-button')}
                              <i className="bi bi-chevron-right ms-2" />
                            </Button>
                          </div>
                        </div>
                        {options.length - 1 !== index ? <hr /> : <></>}
                      </div>
                    ) : (
                      <div key={`option-${index}`}>
                        {/* {options.length > 1 ? <hr /> : <></>} */}
                        <div className="d-flex justify-content-between">
                          <div className="w-45">
                            <h6 className="copytext-medium mb-0">{item?.name}</h6>
                            <span className="copytext">
                              {item?.duration} {item?.durationUnit}
                            </span>

                            {/* <span className="copytext">{item?.description}</span> */}
                          </div>
                          <div className="w-35">
                            {/* <span className="copytext">{t('')}</span> */}

                            <span className="copytext ms-1">{getPrice(item?.price)}</span>
                          </div>
                          <div className="w-20">
                            {item.bookable ? (
                              <Button
                                buttonClass={appButtonType.LINK}
                                onClick={() => {
                                  navigate(appRoute.OPTION_BOOK(item?.id));
                                }}
                              >
                                {t('ek_book_data_choose-button')}
                                <i className="bi bi-chevron-right ms-2" />
                              </Button>
                            ) : null}
                          </div>
                        </div>
                        {options.length - 1 !== index ? <hr /> : <></>}
                      </div>
                    )
                  )
                ) : (
                  <div>
                    <br />
                    <p
                      className="text-center"
                      dangerouslySetInnerHTML={{ __html: t('ek_tariffs_no-option-available') }}
                    />
                  </div>
                )}
              </Card>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Option;

export * from './Book';
