/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { useAccount, useCustomer } from '@context/MobileOne';
import { useLayout, useLoader } from '@context/Utils';
import { useOnlineTopUp } from '@context/AlphaComm/OnlineTopUp';
import { useDashBoard } from '@context/MobileOne/DashBoard';
import { MetaTitle } from '@core/Layout/MetaTitle';
import { BreadcrumbItem, Breadcrumbs } from '@core/Layout';
import { appRoute, faqCategory } from '@utils/globalConstant';
import threeGIcon from '@theme/assets/img/3g4glte.svg';
import general from '@theme/assets/img/allgemeines.svg';
import customerPortal from '@theme/assets/img/kundenportal.svg';
import esim from '@theme/assets/img/esim.svg';
import tarife from '@theme/assets/img/esim_fertig.svg';
import unlock from '@theme/assets/img/freischaltung.svg';
import './style.scss';

export function Faq() {
  // Constants

  // States
  const [questionsData, setQuestionsData] = useState([]);
  const [selectedCategoryData, setSelectedCategoryData] = useState([]);
  const [activeKeys, setActiveKeys] = useState([]);

  // Contexts
  const { t, staticContentData } = useStaticContent();
  const { isLoading: isAutoTopUpLoading } = useOnlineTopUp();
  const { isLoading: isStaticContentLoading } = useLayout();
  const { showLoader, hideLoader } = useLoader();
  const { isLoading: isCustomerLoading } = useCustomer();
  const { isLoading: isDashboardLoading } = useDashBoard();
  const { isLoading: isAccountLoading } = useAccount();

  // Functions
  const getFaqData = () => {
    const staticData = staticContentData;
    if (staticData.faq != null && staticData.faq.length > 0) {
      // Updating data , so that we can get an faq array with type and respective questions

      const faqData = staticData.faq;
      let faqTypes = [];
      const faqFinalData = [];
      if (faqData != null && faqData.length > 0) {
        faqData.forEach((elem, i) => {
          faqTypes.push(elem.category);
        });
        faqTypes = faqTypes.filter((item, i, ar) => ar.indexOf(item) === i).sort();
        faqTypes.forEach((elem, i) => {
          const catData = [];
          faqData.forEach((data, j) => {
            if (elem === data.category) {
              // eslint-disable-next-line no-param-reassign
              data.selected = false;
              catData.push(data);
            }
          });
          faqFinalData.push({
            category: elem,
            data: catData,
            // eslint-disable-next-line no-unneeded-ternary
            selected: i === 0 ? true : false
          });
        });
        setQuestionsData(faqFinalData);
        setSelectedCategoryData(faqFinalData[0].data);
      }
    }
  };

  const setCategoryPdf = (elem) => {
    const data = [...questionsData];
    data.forEach((el, i) => {
      if (el.category === elem.category) {
        el.selected = true;
        const answersData = [...elem.data];
        answersData.forEach((answer, j) => {
          answer.selected = false;
        });
        setSelectedCategoryData(answersData); // updating faq questions on clicking on category
      } else {
        el.selected = false;
      }
    });
    setQuestionsData(data);
  };

  // To set accordian event keys
  const handleSelect = (eventKey) => setActiveKeys(eventKey);

  // To close all open answers
  const closeAllAnswer = (index) => {
    if (activeKeys.length > 0) {
      // eslint-disable-next-line array-callback-return
      activeKeys.map((item) => {
        if (item === index) {
          setActiveKeys('');
        }
      });
    }
  };

  // Hooks
  useEffect(() => {
    if (staticContentData) {
      getFaqData();
    }
  }, [staticContentData]);

  useEffect(() => {
    if (
      isStaticContentLoading ||
      isAutoTopUpLoading ||
      isCustomerLoading ||
      isDashboardLoading ||
      isAccountLoading
    ) {
      showLoader();
    } else {
      hideLoader();
    }
  }, [
    isStaticContentLoading,
    isAutoTopUpLoading,
    isCustomerLoading,
    isDashboardLoading,
    isAccountLoading
  ]);

  return (
    <div className="container">
      <MetaTitle
        title={t('ek_help_and_service_meta_title')}
        description={t('ek_user_profile_meta_description')}
        keywords={t('ek_user_profile_meta_keywords')}
      />
      <div>
        <div className="row">
          <div className="col-lg-12">
            <br />
            <Breadcrumbs>
              <BreadcrumbItem route={appRoute.DASHBOARD}>{t('ek_bc_dashboard')}</BreadcrumbItem>
              <BreadcrumbItem route={appRoute.HELP_AND_SERVICE}>
                {t('ek_bc_help_service')}
              </BreadcrumbItem>
            </Breadcrumbs>
            <h4>{t('ek_faq_h')}</h4>
            <br />
          </div>
        </div>

        <div className="faq mt-4 pt-2">
          <div className="row">
            <div className="col-lg-5">
              <div className="faq-section-box d-flex flex-wrap">
                {questionsData.map((elem, index) => (
                  <div
                    aria-hidden
                    className={`user-account d-flex flex-column align-items-center justify-content-center${
                      elem.selected ? ' active' : ''
                    }`}
                    key={index}
                    onClick={() => {
                      setCategoryPdf(elem);
                    }}
                  >
                    <div className="text-center">
                      <i>
                        {elem.category === faqCategory.THREE_G && (
                          <img src={threeGIcon} alt="3g-icon" />
                        )}
                        {elem.category === faqCategory.GENERAL && (
                          <img src={general} alt="general-icon" />
                        )}
                        {elem.category === faqCategory.UNLOCK && (
                          <img src={unlock} alt="unlock-icon" />
                        )}
                        {elem.category === faqCategory.CUSTOMER_PORTAL && (
                          <img src={customerPortal} alt="portal-icon" />
                        )}
                        {elem.category === faqCategory.TARIFE && (
                          <img src={tarife} alt="tarife-icon" />
                        )}
                        {elem.category === faqCategory.ESIM && <img src={esim} alt="esim-icon" />}
                      </i>
                      <h6 dangerouslySetInnerHTML={{ __html: t(elem.category) }} />
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="col-lg-6 offset-md-1">
              <Accordion alwaysOpen activeKey={activeKeys} onSelect={handleSelect}>
                {selectedCategoryData.map((faq, index) => (
                  <Accordion.Item eventKey={index} key={index}>
                    <Accordion.Header
                      onClick={() => {
                        closeAllAnswer(index);
                      }}
                    >
                      {faq.question}
                    </Accordion.Header>
                    <Accordion.Body dangerouslySetInnerHTML={{ __html: faq.answer }} />
                  </Accordion.Item>
                ))}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
