import React from 'react';
import { Formik, Form } from 'formik';
import { useStaticContent } from '@dom-digital-online-media/dom-static-content-sdk';
import { Button, Text } from '@core/Inputs';
import { useActivation } from '@context/MobileOne';
import { appButtonType, appTextFieldLabelClass } from '@utils/globalConstant';
import errorIcon from '@theme/assets/img/error.svg';
import sucessIcon from '@theme/assets/img/check.svg';
import './style.scss';

export function LoginPassword() {
  // Context
  const {
    isLoading,
    loginPasswordForm,
    loginPasswordValidation,
    loginPasswordFormSubmit,
    validatePasswordWithKeys,
    changePasswordErrorMsg
  } = useActivation();
  const { t } = useStaticContent();
  return (
    <>
      <div className="text-container mx-auto">
        <div className="mx-auto mb-3 pb-1 ps-4 ms-1">
          <h2 className="text-left " dangerouslySetInnerHTML={{ __html: t('ek_ap_password_h1') }} />
        </div>
      </div>

      <Formik
        initialValues={{ ...loginPasswordForm }}
        // validationSchema={loginPasswordValidation}
        validate={(v) => validatePasswordWithKeys(v, 'cscPassword', 'confirmCscPassword')}
        onSubmit={loginPasswordFormSubmit}
      >
        {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
          <div className="card mx-auto">
            <div className="card-body">
              <Form onSubmit={handleSubmit}>
                <div>
                  <Text
                    labelCustomClass={appTextFieldLabelClass.V1}
                    type="password"
                    label={t('ek_ap_password_pw1')}
                    id="cscPassword"
                    name="cscPassword"
                    onChange={handleChange}
                    value={values.cscPassword}
                    placeholder={t('ek_ap_password_pw_input')}
                    // isValid={touched.cscPassword && !errors.cscPassword}
                    // isInvalid={!!touched.cscPassword && !!errors.cscPassword}
                    onBlur={handleBlur}
                    // error={touched.cscPassword && errors.cscPassword ? errors.cscPassword : null}
                  />
                  {/* {JSON.stringify(errors)} */}
                  <ol className="chage-password-err-list mb-5 mt-3 ps-0">
                  <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.minMax
                          ? 'text-danger'
                          : values.cscPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.minMax ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.cscPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require1')}
                    </li>
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.uppercase
                          ? 'text-danger'
                          : values.cscPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.uppercase ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.cscPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require2')}
                    </li>
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.number
                          ? 'text-danger'
                          : values.cscPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.number ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.cscPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require3')}
                    </li>
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.specific
                          ? 'text-danger'
                          : values.cscPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.specific ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.cscPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_pwd_require4')}
                    </li>
                    {/* changePasswordErrorMsg */}
                    {changePasswordErrorMsg && (
                      <li className="d-flex align-items-start text-danger">
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                        {changePasswordErrorMsg || t('ek_password_error_invalid-char')}
                      </li>
                    )}
                  </ol>
                  <Text
                    labelCustomClass={appTextFieldLabelClass.V1}
                    type="password"
                    label={t('ek_ap_password_pw2')}
                    id="confirmCscPassword"
                    name="confirmCscPassword"
                    onChange={handleChange}
                    value={values.confirmCscPassword}
                    placeholder={t('ek_ap_password_pw2_input')}
                    // isValid={touched.confirmCscPassword && !errors.confirmCscPassword}
                    // isInvalid={!!touched.confirmCscPassword && !!errors.confirmCscPassword}
                    onBlur={handleBlur}
                    // validMsg={
                    //   touched.confirmCscPassword && !errors.confirmCscPassword
                    //     ? t('ek_ap_password_success-msg')
                    //     : null
                    // }
                    // error={
                    //   touched.confirmCscPassword && errors.confirmCscPassword
                    //     ? errors.confirmCscPassword
                    //     : null
                    // }
                  />
                  <ol className="chage-password-err-list mb-0 mt-1 ps-0">
                    <li
                      className={`d-flex align-items-start ${
                        errors && errors.otherErrors && errors.otherErrors.match
                          ? 'text-danger'
                          : values.confirmCscPassword && 'text-success'
                      }`}>
                      {errors && errors.otherErrors && errors.otherErrors.match ? (
                        <img className="error-icon" src={errorIcon} alt="error_icon" />
                      ) : (
                        values.confirmCscPassword && (
                          <img className="sucess-icon" src={sucessIcon} alt="sucess_icon" />
                        )
                      )}
                      {t('ek_change-pw_success')}
                    </li>
                  </ol>
                </div>

                <div className="d-flex justify-content-end mt-5 pt-3">
                  <Button
                    buttonClass={appButtonType.PRIMARY}
                    isIcon
                    icon={<i className="bi bi-arrow-right" />}
                    type="submit"
                    isLoading={isLoading}
                  >
                    {t('ek_ap_password_next-button')}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}

export default LoginPassword;
